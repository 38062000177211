
.flexOtherPrice{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    
    }
    .justify_space{
        justify-content: space-between;
    }
    .bgSearch{
        box-shadow: 0 0 4px #bcbcbc;
        padding: 10px !important;
    }
    .icon_setting{
        width: 2rem;
        height: 2rem;
        /* border-radius: 20% !important; */
    }
    .icon_setting_search {
      
        padding-left: 5px !important;
        padding-right: 0 !important;
    
    
    }
    [dir="ltr"] .icon_setting_search {
     
        padding-left: 0 !important;
        padding-right: 5px !important;
    
    }
    .padding_form{
        padding-bottom:15px ;
    
    }
    .NameCat{
        font-size: 0.7rem;
    }
    .CategoryImage{
        /* width: 40%;
        height: auto; */
        width: 100%;
        height: 160px;
        text-align: center;
        margin: 0 auto;
    }
    .FSocial{
        width: 1.2rem !important;
        height: 1.2rem !important;
        font-size: 1.2rem !important;
    }
    .Badge_POs{
        right: 86%;
        left:0%;
        position: absolute;
        /* margin-top: 8px;
        margin-bottom: 8px; */
        top: 2%;
    }
    [dir="ltr"] .Badge_POs{
        right: 0%;
        left: 87%;
       
    }
    .FollowMobile{
        height: 320px;
        text-align:center ;
        /* padding-top:3% ;  */
        padding-bottom:7% ;
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        justify-items: start;
        max-width: 1500px;
     
        gap:1rem;
        color: white;
        margin: auto;
        height: auto;
        
    }
    .FollowMobile a{
        color: #fff;
    }
    .FollowMobile p{
        margin-bottom:4px;
    }
    .footer p{
        margin-bottom:8px;
    }
    .UlTerm span{
        font-size: 0.9rem !important;
        margin-bottom: 3px;
    }
    .UlTerm ol li {
                   
        margin-bottom: 8px !important;
    }
    .UlTerm p {
       
        margin-bottom: 8px !important;
    }
    .BgUlServices{
        backdrop-filter: blur(2px);
        background-color: #dd1e4b6b;
    }
    .paddingButton{
        padding: 6px 8px ;
    }
    .paddingLinkMobile{
        padding: 20px 5px ;
    }
    .pproducttext ul li {
        font-size: 0.8rem;
       margin-bottom: 6px;
    }
    .pproducttext ul li p {
        font-size: 0.8rem;
        margin-bottom: 6px;
    }
    
    .pproducttext ul li p span{
        color: #F52A59 !important;
        font-size: 0.8rem !important;
        font-weight: 600 !important;
    }
    .pproducttext ul li p strong{
        font-size: 0.8rem !important;
       
    }
    .widthImgProd{
        width:300px !important;
        height: 300px !important;
    }
    /* .TabOffer{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        align-items: center;
    } */
    .heightallmen{
        height: 400px;
        overflow: auto;
        overflow-x: hidden;
    }
    .heightmobilesubmenu{
        /* height: 708px; */
        min-height: fit-content;
        height: 85vh;
        max-height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .imgtypetransport{
        width:2rem;
        height:2rem;
    }
    .imgcompanytransport{
        width:3rem;
        height:3rem;   
        border-radius: 50%;
    }
    .HeightMM{
        overflow-y: auto;
        min-height: 300px;
        max-height: 700px;
    }
    .subtextmobile{
        font-size: 0.9rem !important;
    }
    .GridCatMobile{
        display: grid;
        grid-template-columns: 30% 70%;
      
    }
    .FlexSubButton{
    display: flex !important;
    justify-content: flex-start !important;
    }
    .paddinglr{
        padding: 0px 10px;
    }
    .flexMobCat{
        display: flex;
        align-items: flex-start !important;
        flex-direction: column;
     
    }
    .flexrow{
        display: flex;
        align-items: center !important;
    }
    .blueSearchBg{
        background-color: #008C8D ;
        border-radius: 50%;
        display:flex;
        border:0.5px #008C8D  solid;
        align-items: center;
        justify-content: center;
    }
    
    .heightOff{
        height: 17px !important;
    }
    .btnsmall{
        height: 79% !important;
        width: 91% !important;
    }
    .heightsubMenu{
        height: 50%;
        overflow-y: scroll;
    }
    .sizeCard{
        width: 90%;
        margin: auto;
    }
    .marginSkeleton{
        margin:10px 8px 0px 8px    
    }
    .rectangle{
        height: 185px !important;
        width:100% ;
    }
    .loadingData{
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2,1fr);
        gap: 1rem;
        padding: 10px;
    }
    .loadingPro{
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2,1fr);
        gap: 1rem;
        padding: 10px;
    }
    .loadingDataCat{
        display: grid;
        align-items: center;
        grid-template-columns: repeat(3,1fr);
        gap: 4rem;
        padding: 10px;
    }
    .loadingMenu{
        display: grid;
        align-items: center;
        grid-template-columns: repeat(1,1fr);
        gap: 4rem;
        padding: 10px;
    }
    .marginAuto{
        margin: auto;
    }
    
    .display{
        display:block;
    }
    .MenuMar{
        margin-bottom: 120px;
    }
    .ImgPro{
        width: 285px;
    
        border: 1px solid #bcbcbc;
    }
    .imgtrad{
        width: 350px;
        border: 1px solid #bcbcbc;
    }
    .Empty{
        width: 59px;
    }
    .DivImagePro{
        display: grid;
        grid-template-columns: 16% 81%;
        gap: 0.5rem;
        /* width: 301px; */
        width: 355px;
    }
    .gridBrands{
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .buttonCategory{
        border: 0;
        background-color: transparent;
    }
    .PadLast{
        padding-right: 4px !important;
        padding-left: 0px !important;
        
    }
    [dir="ltr"] .PadLast{
        padding-left: 4px !important;
        padding-right: 0px !important;
    }
    .paddlevel3{
        padding-right: 30px !important;
        padding-left: 0px !important;
    }
    [dir="ltr"] .paddlevel3{
        padding-left: 30px !important;
        padding-right: 0px !important;
    }
    .paddlevel2{
        padding-right: 15px !important;
        padding-left: 0px !important;
    }
    [dir="ltr"] .paddlevel2{
        padding-left: 15px !important;
        padding-right: 0px !important;
    }
    .SiderBorder{
        border-right: 1px solid;
        border-left: 0px;
        margin-right: 12px;
        margin-left: 0px;
    }
    [dir="ltr"] .SiderBorder{
        border-left: 1px solid;
        border-right: 0px;
        margin-left: 12px;
        margin-right: 0px;
    }
    .SiderBorder3{
        border-right: 1px solid;
        border-left: 0px;
        margin-right: 25px;
        margin-left: 0px;
    }
    [dir="ltr"] .SiderBorder3{
        border-left: 1px solid;
        border-right: 0px;
        margin-left: 25px;
        margin-right: 0px;
    }
    
    .PaddCat{
        padding: 10px;
    }
    .NoItem{
        width: 50%;
        height: 50%;
    }
    .Postitionabsolute{
        position: absolute;
        top: 12%;
    }
    .gapbasket{
        gap: 0.5rem !important;
    }
    .SquareBaskte{
        width: 103%;
        height: 330px;
    
    }
    .widthDes{
        width: 57%;
        margin: auto;
    }
    .SeeMoreGradients{
        background-image: linear-gradient(#008C8D ,#14d8dc);
        border: 0;
        color:white;
        width: 31%;
        border-radius: 5px;
      
    }
    .ImgDownload{
        position: absolute;
        /* max-width: 1500px; */
        left: -64%;
        bottom: 42%;
        overflow: hidden;
        z-index: 0;
        width: 100%;
    }
    .BgDownload{
        background-color: #eeeff1;
        position: relative;
        z-index: 1;
    }
    .positionX{
        background-position-x: 180px !important;
    }
     .HH{
        display: flex;
        padding-left: 3% !important;
        padding-top: 3% !important;
        padding-right: 3% !important;
        justify-content: space-between;
    
    
    }
    .sizeAdIc{
        font-size: 1rem !important;
    }
    
    .BgWeb{
        background-color: #F9FBFF;
    }
    .BgComp{
        background-color: white;
        padding: 20px 20px;
    }
    .BgCategory{
        position: fixed;
        top: 70px;
        z-index: 2;
        width: 100%;
        height: 54px;
        padding: 0px;
        background-color:#F9FBFF;
        /* box-shadow: 0 0 10px grey !important; */
    }
    .BgAllCat{
        background-color: #F9FBFF;
        top: 122px;
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
    
        box-shadow: 0 0 2px #bcbcbc;
    }
    .BgSubCat{
        background-color:white;
    
    }
    .CatMarket{
        box-shadow: 0 0 3px #bcbcbc;
        width: 80px !important;
        height: 80px !important;
        border-radius: 50%;
        padding: 5px;
        object-fit: contain;
    }
    .myswiperMarket{
        padding: 10px;
    }
    .widthAddHome{
        width: 100%;
    }
     .swiperSlideMraket img{
     width: 1.8rem ;
    }
    
    .Sponsorsize img{
        object-fit: contain !important;
    }
    
    .loadingJSON{
        width:4rem !important;
        height:4rem!important
    }
    .AsbuttonDisable label{
        z-index: 1;
        cursor: pointer;
        line-height: 1.8em;
        font-size: 1.1rem;
    }
    .AsbuttonDisable{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .AsbuttonDisable input[type="radio"]{
        opacity: 0.011;
        z-index: 100;
    }
    .flex_{
        display: flex; 
       /* flex-flow: row; */
        padding: 10px 0px; 
        align-items: center;
    }
    
    .font_address{
       /* font-weight: bolder; */
       /* margin-right: 7px; */
       width: max-content;
       margin-bottom: 0;
       text-decoration: none;
       /* color:#fff; */
    }
    .font_address a{
      color:  #008C8D ;
       text-decoration: none;
       /* color:#fff; */
    }
    
    .disAddcomfirmation{
        box-sizing: border-box;
        width:100%;
        text-align: center;
        padding:5px 15px;
        margin: auto;
        border-radius: 12px;
        font-size: 1rem;
        text-align: center;
        color: black;
        margin-bottom: 0;
        background-color: #80808036;
    } 
    .CircleLi{   
         border-radius: 0%; 
           height: 0.4rem;
        }
    .ComfirmationButtondisabl{
        box-sizing: border-box;
        background-color: #c0babb;
      border:0px solid #c0babb;
        width:100%;
        text-align: center !important;
        padding: 11.5px 0px !important;
      
        margin: auto;
        color: white !important;
     
        border-radius: 11px;
        
    }
    
    
    .ShareIcon{
        font-size: 1.5rem !important;
    }
    .positionBgProfile{
        position: absolute;
        top:20%;
    }
    .paddingOffer{
        padding: 0px 0px 0px 8px !important;
    }
    [dir="ltr"] .paddingOffer{
        padding: 0px 8px 0px 0px !important;
    }
    .ShowthreeProduct{
        text-align: center;
    
     
        grid-template-columns:repeat(2,1fr);
        display: grid;
       
        gap: 1rem;
        width: 100%;
        margin: auto;
      
    }
    .ShowOffer{
        text-align: center;
    
     
        grid-template-columns:repeat(2,1fr);
        display: grid;
       
        gap: 1rem;
        width: 100%;
        margin: auto;
      
    }
    .gapEntity{
        gap:1rem !important;
    }
    .ETpadd{
        /* padding: 0px 8px; */
        /* right: 115px; */
        position: relative;
        top: -13px;
    }
    /* [dir="ltr"] .ETpadd{
        left: 115px;
    } */
    .ET{
        background-color: #bdbfc196;
        color: white !important;
        font-weight: 100 !important;
        padding: 4px 2px !important;
        border-radius: 0px 0px 6px 6px ;
        text-align: center;
        /* border-radius: 0px 0px 12px 12px; */
        /* backdrop-filter: blur(9px); */
        /* background-color: #edeaef8c; */
        /* width:139px; */
        margin-left:auto;
        margin-right:auto;
        margin: 0px !important;
    
    }
    .fullWH{
        width: 100%;
        height: 100%;
    }
    .HeightCount{
        height: 40px !important;
    }
    .liStyle{
        list-style: none;
        padding: 0 !important;
    }
    .sticky{
    
        position: sticky;
        top: 18%;
    }
    .poSticky{
        position: sticky;
        top: 0;
    }
    .paddingDialogLCCT{
        padding-Top: 10px  !important;
    }
    .paddingDialogLCCB{
        padding-bottom:  10px !important;
    }
    .listMenu{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        align-items: center;
        justify-content: initial;
    
        justify-items: initial;
        /* width: 100%; */
        width: 90%;
        margin:auto
    }
    .SublistMenu{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        align-items: center;
        justify-content: center;
        justify-content: center;
        justify-items: center;
        gap: 1rem;
        padding-top: 15px;
    
    }
    .SubCategory{
        display: flex;
        grid-template-columns: repeat(1,1fr);
        align-items: flex-start;
        gap: 1rem;
        width: 100%;
        flex-direction: column;
    
    }
    .BorderRig{
        border-left: 0px ;
        padding-left: 0px;
    
    }
    .BorderLef{
        border-right: 10px;
        padding-right: 0px !important;
    
    }
    .SevenCategory{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        justify-content: center;
        gap: 1rem;
    
    
    }
    .FlexMenu{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        padding: 10px 0;
       
    }
    .FlexPM{
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        align-content: center;
    }
    
    .Buttonmeals{
        font-size: 1.1rem !important;
    }
    .clearMeals{
        background-color: black !important;
        font-size: 1em !important;
        position: absolute;
    
        color: white;
        left: 0;
        width: 0.7em !important;
        height: 0.7em !important;
        top: 0;
        /* border-bottom-right-radius: 6px;
        border-bottom-left-radius: 0px; */
    }
    [dir="ltr"] .clearMeals{
        left: 92%;
      
        /* border-bottom-right-radius: 0px;
        border-bottom-left-radius: 6px; */
    }
    
    .ButtonClear{
      
            font-size: 1em !important;
            position: absolute !important;
            /* left: 15px !important; */
            z-index: 1;
            top: 0 !important;
    }
    
    .lineBottom{
        border: 1px solid rgb(63 63 68 / 15%);
        width: 100%;
        position: absolute;
        bottom: 54px;
    }
    .paddingTextProduct{
        padding: 0 0px;
    }
    .DisCountSquare{
        position: absolute;
        top:0px;
        right:0;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        
    }
    [dir="ltr"] .DisCountSquare{
        right:unset;
        left:0;
    }
    .widthOffer{
        width: auto !important;
        padding-right:  5px !important;
        padding-left: 6px !important;
        font-size: 0.7rem !important;
    
    }
    [dir="ltr"] .widthOffer{
        padding-left:  5px !important;
        padding-right:  6px !important;
    }
    
    .bordercount{
        border-radius: 50% !important;
        border:1px solid #008C8D  !important;
         width: 25px !important;
         height: 25px !important;
         font-weight: 600;
       /* padding: 5px; */
    }
    
    .SquareOffer{
        background-color: #F52A59;
        color: white;
        font-size: 0.9em;
        text-align: center;
        width: 100%;
        padding: 1px 10px;
        border-radius: 6px 0px 0px 6px;
    }
    .DisSquareOffer{
        background-color: #F52A59;
        color: white;
        font-size: 1.1em;
        text-align: center;
        width: 100%;
        padding: 1px 10px;
        border-radius: 6px 0px 0px 6px;
    }
    [dir="ltr"] .DisSquareOffer{
        background-color: #F52A59;
        color: white;
        font-size: 1.1em;
        text-align: center;
        width: 100%;
        padding: 1px 10px;
        border-radius: 0px 6px 6px 0px;
    }
    
    .positionExplore{
        position: absolute;
        top: 0;
        z-index: 1;
    
    }
    .WordSpace{
        word-break: break-word;
        overflow: hidden;
    }
    .Circle{
        border-radius: 50%;
        background-color: #e5e5e5;
        padding: 1px;
        width: 0.7rem;
        height: 0.7rem;
        margin: 5px  auto 0 auto;
    }
    .CircleLi{
        border-radius: 50%;
        background-color: #e5e5e5;
        padding: 1px;
        width: 0.7rem;
        height: 0.7rem;
        margin: auto;
    }
    .flexli{
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    
    
    .SelectDate{
        position: absolute;
        top: 25px;
        font-size: 1rem;
     
    }
    
    .halfGap{
        gap: 0.5rem !important;
        }
        .GapCou{
            gap: 0.2rem;
        }
    .GapCurrency{
        gap: 2.5rem;
        }
        .GapZero{
            gap: 0rem !important;
        }
        .ButtonExplorMore{
        
            background-color: #F52A59;
            color: white;
            border-radius: 4px;
            position: absolute;
            bottom: 44px;
            width: 31%;
            font-size: 0.9rem;
            padding: 4px ;
            text-align: center;
            direction: ltr;
            box-shadow: 0 0 3px grey;
            z-index: 1;
            left:1%;
    
        
        }
        [dir="ltr"] .ButtonExplorMore{
    
            left: 66%;
        }
        .notFoundPage{
            direction: rtl;
            margin: 5% 10px;
          }
          
          .notFoundRightBlock{
            text-align: center;
            margin-top: 50px;
          }
          
          .notFoundRightBlock > h1{
            color: #F52A59;
            /* color: #038E8C; */
            font-weight: bold;
            font-size: 10em;
          
          }
          
          .notFoundRightBlock > h3{
            font-weight: bolder;
            font-size: 2em;
            margin-bottom: 15px;
          }
          
          .notFoundRightBlock > a{
            color: #038E8C;
            padding: 2px 20px;
            border-radius: 20px;
            margin-top: 10px;
            display: inline-block;
            border: 1px solid  #038E8C;
            font-size: 20px;
          }
          
          .notFoundPage {
             margin: unset;
          }
          .rowMargin {
           
             margin-bottom: -42px !important;
           
          }
          .row{
            margin-left: auto !important;
            margin-right: auto !important
          }
        .padding10{
            padding: 10px 0px !important;
        }
        .paddingfood{
            padding: 20px 0px !important;
        }
        .paddinglrzero{
            padding-left: 0px !important;
            padding-right: 0px !important;
          
        }
        .paddingZero{
            padding: 0px !important;
        }
        /* .cards2 img{
            width: 120px;
            height: 120px;
            margin: auto;
        } */
        .fullwidth{
         width: 100%;   
        }
        .AddCart{
            width: 100%;
            margin: 0;
            position: absolute;
            bottom: 0;
            font-size: 0.9rem;
            padding: 10px 8px;
      
        }
        .paddingAddCart{
            padding: 10px;
        }
        .paddingAddCartPro{
            padding: 7px;
        }
        .paddingoffer{
            padding: 10px;
        }
        .borderteal{
            border:1px solid #008C8D ;
        }
        .borderRadius{
            border-radius: 3px !important;
        }
        .borderPink{
            border:1px solid #F52A59;
        }
        .yellowbox{
            box-sizing: border-box;
            background-color:#faaf00;
            border: 0px solid #faaf00;
            font-size:18px;
            color:white;
            padding: 0px 3px;
            border-radius: 3px;
        }
         .widthQuantitybtn{
            width: 100% !important;
        }
        .paddConfirmation{
            padding-bottom: 60px;
        }
        .ratingdiv{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            align-items: center;
        }
        .RatingDialogdiv{
            display: grid;
            gap: 1rem;
            grid-template-columns: 100%;
        }
        [dir="ltr"].IconButton{
            right:80%;
            left:80%;
            top:15}
            
            [dir="rtl"].DialogTitle{
               display: flex;
               direction: rtl;
            }
            [dir="ltr"].DialogTitle{
               display: flex;
               direction: ltr;}
            [dir="rtl"] .searchbox {
                direction: rtl;
            } 
            
            
            [dir="rtl"].search::placeholder{
                direction: rtl;
            }
            [dir="ltr"].search::placeholder{
                direction: ltr;
            }
            
             [dir="rtl"] .cards {
                direction: rtl;
                
            
            }
              
            [dir="ltr"] .cards {
                direction: ltr;
                
            
            }
             [dir="rtl"] .SquareFooter  {
                direction: ltr;
                
            
            } 
            [dir="ltr"] .SquareFooter  {
              
            direction: rtl;
                
            
            } 
            
            [dir="rtl"] .footer  {
                direction: rtl;
              
            
            }
            .borderradiusTop{
        
                margin-bottom: 15px !important;
                width: 30% !important;
                margin-right: auto;
                margin-left: auto;
            }
            .heightAndRaduis{
              border-radius: 10px;
              height: 45px;
            }
            .widthClick{
                width: 50% !important;
                margin: auto;
            }
            .borderradiusTopMedical{
        
                margin-bottom: 0px !important;
                width: 24% !important;
                margin-right: auto;
                margin-left: auto;
                border-radius: 10px;
            }
          /* .marginDetailsProducts{
             
              padding-right: 0px !important ;
              padding-left: 0px  !important ;
          }
          [dir="ltr"] .marginDetailsProducts{
            padding-left: 0px  !important ;
            padding-right: 0px !important ;
          } */
            .paddingSearch{
                padding: 10px !important;
            }
        .paddingTaboffers{
            padding: 10px 0 30px 0;
        }
            .widthstate{
                width:50%;
                margin: auto;
            }
            .paddingtext{
                padding: 5px 10px 0px 10px;
            }
            .flexPrice{
                display: flex;
                gap:0rem;
                flex-direction: row;
                /* justify-content: space-between; */
            }
            .Blogwidth{
                height: 100%;
                width: 100%;
                box-shadow: 0px 0px 3px  #bcbcbc ;
                box-sizing: content-box;
                border-radius: 5px;
                padding-bottom: 0px;
        
            }
            .displayBlock{
                display: block;
            }
    
            
            .nomargin{
                margin: 0;
            }
            .flex{
                display: flex;
                border: none !important;
                
            }
            .flexCat{
                display: flex !important;
                align-items: center;
                border: none !important;
            }
            .flexCategory{
                display: flex;
                flex-wrap: wrap;
            }
            /* .marginHeaderOffer{
                margin-bottom: 1%;
            } */
            .Cover{
                object-fit: cover !important;
            }
            .TwoGrid{
                display: grid;
                grid-template-columns: 100%;
                gap:0rem;
                padding: 40px;
                align-items: center;
            }
            .paddingProgress{
                padding: 40px !important;
            }
            .borderBtnBooking{
                border: none;
                padding: 10px;
                border-radius: 7px;
            }
            .BgGrey2{
                background-color: #bcbcbc;
            
            
            }
            .BgYellow{
                background-color: #faaf00;
            }
            .PinkBG{
                background-color:#F52A59 ;
            }
            .WhiteBG{
                background-color:#fff ;
                box-shadow: 0 0 3px #bcbcbc;
                padding: 13px;
         
            }
            .White{
                color: #fff !important;
            }
            .TwoFlex{
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
            
            }
            .TwoFlexButton{
              
                display: flex;
              gap: 1rem;
                align-items: center;
                
            
            }
            .paddingTabsBasket{
                padding: 0px;
            }
            .card1{
            width: 100%;
            }
            .btnAdd{
                margin-bottom:0;
                border:none;
                color:white !important;
                background-color:#008C8D 
            }
            .comparedPrice{
                padding: 10px 30px;
                width: 100%;
                margin: auto    ;
                border-radius: 0px;
                font-size:1rem;
            }
            .compared{
              padding-top: 20px;
            }
            .btnlogout{
                /* margin-bottom:0; */
                border:none;
                /* color:white;*/
                background-color:white 
            }
            .paddingSwiperOffer{
                padding-top: 10px !important;
            }
            .borderOfferSlider{
            
                box-shadow: 0px 0px 3px  #bcbcbc ;
                box-sizing: content-box;
                border-radius: 12px;
            }
            .RegisterLogo{
                width: 6rem;
                height: 6rem;
                margin:  0 auto;
                max-width: 10rem;
            }
            .relativePosition{
                position: relative;
            }
            .EditBtnThreeState{
                width: 50%;
                margin: auto;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            .ImgandheaderFAQ{
                display: flex;
                align-items: center;
            }
            .ContainerBuy{
                max-width: -webkit-fill-available !important;
                padding: 40px 0px !important;
            }
            .paddingTopContactandTermpage{
                padding: 40px  0px  !important;
            }
            .widthAddCardBtn{
                 width: 100% !important; 
            }
            .paddingdevicedialogtext{
                padding: 0 46px;
            }
            .Cursor{
                cursor: pointer;
            }
            .borderdevicesimg{
                border-radius: 12px;
                box-shadow: 0 0 3px #bcbcbc;
                padding: 20px ;
            }
            
            .borderTabDietDetails{
                border-radius:12px !important;
                border:0.5px solid #ccf0ef !important ;
                color:#008C8D  !important;
                box-shadow: 0 0 3px #bcbcbc;
                font-weight: 600 !important;
                opacity: 1 !important;
            }
            .borderTabOffer{
                border-radius:12px !important;
                border:0.5px solid #e7597c  !important ;
                color:#e7597c  !important;
                /* box-shadow: 0 0 10px #bcbcbc; */
            }
            .imgoffer{
                width: 100%;
                height: 145px !important;
                margin: auto;
                object-fit: contain;
                border: 1px solid #bcbcbc;
                border-radius: 6px;
                box-shadow: 0 0 3px #bcbcbc;
            }
            .BgTeal{
                background-color: #008C8D  !important;
            }
            .widthbtncontact{
                width: 100% !important;
            }
            .bgSoicalicon{
                background-color: #F52a5930;
                
                border-radius: 50%;
               padding: 2%;
              
            }
            .BasketAddWidthDialog{
                width: 83px !important;
            }
            .ImgDiolagAddcard{
                display: grid;
                grid-template-columns: 100%;
                align-items: center;
                justify-content: center;
               
            }
            .Dialogimg{
                width: 100% !important;
                height: 100% !important;
                margin: auto;
            }
            .BottomQuantity{
                display: grid;
                grid-template-columns: 100%;
                gap: 0rem;
                align-items: center;
                /* width: 100%; */
            }
            .BottomOrder{
                display: grid;
                grid-template-columns: 100%;
                gap: 1.6rem;
                align-items: center;
                justify-items: center;
            }
            .BottomOrderDialog{
                display: flex;
                grid-template-columns: 70%;
                gap: 1.6rem;
                align-items: center;
                justify-content:flex-start;
                justify-items: center;
                flex-wrap: wrap;
         
            }
            .divOrderDialog{
                display: flex;
                justify-content:space-between;
            }
            .marginZero{
                margin: 0 !important;
            }
            .deletebasket{
                right: 317px;
                top: 4px;
                position: absolute !important;
            }
            [dir="ltr"] .deletebasket{
                left:317px;
                top: 4px;
                position: absolute;
            }
            .paddingToppersonandview{
                padding-top: 10px;
            }
            .ColorBlack{
                color: black !important;
            }
            .DarkBlue{
                color: #203d74 !important;
            }
            .paddingproduct{
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            
            .displayBuybtn{
                display: block;
            }
            .widthbtnComfirmation{
                width:90% !important ;
            }
            .widthbtnOpen{
                width:100% !important ;
            }
            .widthbtnOk{
                width:74% !important ;
            }
            .sizepricetext{
            font-size: 0.9rem !important;
            }
            
            .objectFit{
                object-fit: none !important;
            }
            .DivTextBasket{
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                align-items: center;
                justify-items: center;
            }
            .DivTextAddress{
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                align-items: center;
                justify-items: center;
                gap: 1rem;
                padding: 10px 0;
            }
            .widthbutnBasket{
                margin: 0 !important;
                width: 97% !important;
            }
            .imgproductbasket{
               max-width:550px ;
               width:100%;
               height: 100%;
               max-height:550px ;
               margin: auto;
            }
            .starandrating{
                display: grid;
                grid-template-columns: 61% 18%;
                align-items: flex-start;
            }
            .paddingComment{
                padding: 30px 0px;
            }
            .paddingcontact{
                padding: 10px 0px;
            }
            .paddingRatingDiv{
                padding-bottom: 20px ;
            }
            .paddingfavsubdiet{
            padding-bottom: 60px ;
            }
            .paddingTop{
                padding-top: 60px;
            }
            .textaligncenter{
                text-align: center !important;
            }
            .textalign{
                text-align: initial !important;
            }
            .paddingzero{
                padding: 0 !important;
            }
            .marginbottom{
                margin-bottom: 0 !important;
            }
            .direction{
                direction: ltr; 
            }
            [dir="ltr"] .direction{
                direction: rtl; 
            }
             [dir="rtl"] .allcard{
                direction: rtl; 
            } 
            [dir="ltr"] .allcard{
                direction: ltr; 
            }
            [dir="rtl"] .allcardJson{
                direction: rtl; }
            [dir="ltr"] .allcardJson{
                direction: ltr; 
            }
            .alignInitial{
                align-items: center !important;
            }
            .FourGrid{
                display: grid ;
                grid-template-columns:100%;
                align-items: flex-start;
                gap: 2rem;
                padding: 40px 0px;
            
            }
            .FlexColumn{
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
            }
            .FlexColumnCategory{
                display: flex;
                flex-direction: column;  
                align-items: flex-start;
            }
            .marginoffertab{
                margin: 0 15px !important;
            }
            .SizeFont{
                font-size: 0.9rem !important;
            }
            .BgButton{
                background-color: #F52A59 !important;
                border: 0 !important; 
                color: white !important;
            }
            .border{
                border-left:0 ;
                border-right: 0;
                height: 4px;
                position: absolute;
            }
            [dir="ltr"] .border{
                border-right: 0 ;
                border-left: 0 ;
            }
            .BorderBottom{
                border-bottom:0 ;
               /*  border-right: 0; */
              
            }
            
            .textPriceProduct{
                font-size: 0.9em;
                margin-bottom: 0px;
                padding-left: 8px;
                padding-right: 8px;
                text-align: initial;
                font-weight: 600;
            }
            .Order1{
                order: 1;
            }
            .Order2{
                order: 2;
            }
            .PaddingBar{
            padding: 20px 0px 0px;
            }
            .CategoryDiv{
                display: grid;
                grid-template-columns: 100%;
                gap: 1rem;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 15px;
                padding-left: 15px;
                align-items: center;
                justify-items: flex-start;
            }
            [dir="ltr"] .CategoryDiv{
                padding-left: 15px;
                padding-right: 0;
            }
            .WorkTimeDiv{
                display: grid;
                grid-template-columns: 100%;
                gap: 3rem;
               
            }
            .Category{
               /*  font-size: 1.2rem !important; */
                font-size: 0.8rem !important;
            }
            .CategoryHeader{
                font-size: 1.3rem !important;
                text-align: initial;
            }
            .BgGrey{
                background-color: #bcbcbc26;
                width: 100%;
                margin: auto;
            }
            .paddingBottom{
                padding-bottom: 22px;
            }
            .ComfirmationDiv{
                box-shadow: 0 0 3px #bcbcbc;
                margin:0px 0px 100px 0px;
            }
            .iconandtextthreebox{
                display: flex;
                align-items: center;
                padding: 0 10px;
                gap: 1rem;
              
            }
            .BasketandButtonOrder{
                display: grid;
                align-items: center;
                grid-template-columns: 50% 50%;
                gap: 0rem;
              
            }
            .BasketandButtonOrderBasketpage{
                display: grid;
                align-items: center;
                grid-template-columns: 80% 21%;
                gap: 1rem;
                padding: 10px;
              
            }
            .paddingBottomZero{
                padding-bottom: 0 !important;
            }
            .PaddingTopZero{
                padding-top: 0 !important;
            }
            .BasketandEditIcon{
                display: flex;
                align-items: center;
               /*  grid-template-columns: 80% 21%; */
                gap: 1rem;
                padding: 10px;
                justify-content: center;
              
            }
            
            .Comfirmationandprice{
                display: grid;
                align-items: center;
                grid-template-columns: 50% 50%;
                gap: 0rem;
                justify-content: center;
              
            }
            .Buyandprice{
                display: flex;
                flex-direction: column;
                align-items: center;
                grid-template-columns: 50% 50%;
                gap: 2rem;
                justify-content: center;
              
            }
            .iconandtexttime{
                display: flex;
                align-items: center;
                /* justify-content: center; */
                gap: 1rem;
              /*   grid-template-columns: repeat(2,1fr); */
            }
            .ThreeBox{
                box-shadow:0 0 3px #bcbcbc ;
                background-color: white;
                width: 72%;
            border-radius: 7px;
                height: 46px;
            
                color: #bcbcbc !important;
            }
            .Count{
                border:none;
                background-color: transparent;
                width: auto;
                color: #008C8D ;
    
                height: 100%;
              
            
            }
            .raduis{
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
            [dir="ltr"] .raduis{
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;  
            }
            .Countproduct{
              border: 0;
                background-color: white;
                width: auto;
               
            
            }
            .CountWhite{
                border:none;
                background-color: #008C8D ;
                width: auto;
                color: white;
            
            }
            .Blod{
                font-weight: 600  !important;
            }
            .PinkColor{
                color:#F52A59 !important;
            }
            .TealColor{
                color:#008C8D  !important;
            }
            .GreyColor{
                color: #bcbcbc !important;
            }
            .paddingOrder{
                padding: 10px 0;
            }
            .Opendetails{
                box-sizing: border-box;
       
              background-color: #008C8D ;
              border:0px solid  #008C8D ;
                width:39%;
                text-align: center;
                padding: 5px 20px;
              
             margin: auto;
                color: white;
             font-size: 0.9rem;
                border-radius: 11px;
                
            }
            .Closedetails{
                box-sizing: border-box;
                background-color: #F52A59;
              border:0px solid  #F52A59;
                width:39%;
                text-align: center;
                padding: 5px 20px;
              
             margin: auto;
                color: white;
             font-size: 0.9rem;
                border-radius: 11px;
                
            }
            .ComfirmationButton{
                box-sizing: border-box;
                background-color: #F52A59;
              border:0px solid  #F52A59;
                width:100%;
                text-align: center !important;
                padding: 6px 12px !important;
              
                margin: auto;
                color: white !important;
             
                border-radius: 6px;
                
            }
            .borderbottom{
                border-bottom: 2px solid #bcbcbc2b;
            
                
            }
            .borderbottomor{
                border-bottom: 3px solid #bcbcbc63;
                /* width: 100%; */
                width: 40%;
                margin: auto;
                
            
                
            }
            .borderbottomdialog{
                border-bottom: 1px solid #bcbcbc63;
                width:100%;
                margin: auto;
                
            
                
            }
            .RecycleBin{
               
                right:87%;
                bottom:0;
                position: absolute;
                width: 0%;
            }
            [dir="ltr"] .RecycleBin{
                left:87%;
                bottom:0;
                position: absolute;
            }
            .BlodText{
                font-weight: 600;
                font-size: 1.3rem;
            }
            .StarDiet{
                stroke:#008C8D ;
                stroke-width:1;
                color:#F52A59
            }
            .StarBorderDiet{
                stroke-width:1;
                stroke:#008C8D ;
                color:#008C8D 
            }
            .SwitchDetailsDiet>input[type=radio]:not(:checked), .SwitchDetailsDiet>input[type=radio]:checked{
             
                display: none;
            }
            .SwitchDetailsDietLabel{
                display: grid;
                grid-template-columns: 20% 20% 20%;
                width: 20%;
                margin: auto;
                padding: 1rem;
                text-align: center;
                cursor: pointer;
                transition: #ffffff 200ms ease-out;
            }
            .SwitchDetailsDiet >input#one:checked{
             
                border:0.5px solid#ccf0ef ;
                border-radius:12px ;
            
                box-shadow: 0 0 3px #bcbcbc;
            } 
            .colorTab{
                border:0.5px solid#ccf0ef ;
                color:#008C8D ;
                font-weight: 600;
                border-radius:12px ;
            
                box-shadow: 0 0 3px #bcbcbc;
            }
            .numberphoneandicon{
                display: flex;
                flex-direction: row;
                
            }
            .SquareGreyBg{
                background-color: #bcbcbc;
                background-position: center;
                width: 100%;
                margin: 50% 50%;
                padding: 100px;
                z-index: 0;
                position: absolute;
            }
            .textpadding{
                padding-bottom: 20px;
            }
            .TitleandIcons{
                display: flex;
                /* display: grid; */
                grid-template-columns: 9% 86%;
                align-items: center;
                /* padding: 0 0 10px 0; */
                gap: 0.5rem;
                padding: 0 0 10px 0;
            }
            .TitleandIconsDevicesSlider{
                display: grid;
                grid-template-columns: 13% 44% 44%;
                align-items: center;
                padding: 0 0 10px 0;
                justify-content: space-between;
            }
            .HeaderBasket{
                display: grid;
                grid-template-columns:  13% 76% 11%;
                align-items: center;
                padding: 0 0 10px 0;
                justify-content: space-between;
            }
            .HeaderandBasketIcon{
                /* display: grid;
                grid-template-columns: auto auto ; */
                display: flex;
                align-items: center;
                justify-content: center;
                justify-items: center;
                padding: 10px 20px;
                gap: 1rem;
            }
            .HeaderandBasketIconpagebasket{
                display: grid;
                grid-template-columns: 8% 33% ;
                align-items: center;
                /* justify-content: initial;
                justify-items: center; */
                padding: 10px 0px;
                gap: 0rem;
            }
            .HeaderContact{
                /* before ==>
                display: grid;
                grid-template-columns: auto auto ;
                align-items: center;
                justify-content: center;
                justify-items: center;
                padding: 10px 0px;
                gap: 1rem; */ 
                display: flex;
                grid-template-columns: auto auto;
                align-items: center;
                justify-content: center;
                justify-items: center;
                padding: 10px 0px;
                grid-gap: 1rem;
                gap: 1rem;
            }
            .paragraphandIcons{
                display: grid;
                grid-template-columns: 8% 92%;
                align-items: flex-start;
             
            }
            .paragraphandIconsSpecialty{
                display: grid;
                grid-template-columns: 6% 92%;
                align-items: flex-start;
             
            }
            .containerwhite{
                background-color: white;
            }
            .phoneSquarestyle{
                background-color: #bcbcbc63;
            }
            .swiperpagination{
                position: absolute !important;
                z-index: 10;
            top:70%
            }
            .loginandlanguage{
                display: flex;
                justify-content: space-around;
                /* grid-template-columns: 74% 19% ; */
                align-items: center;
                gap: 1rem;
            }
            .ContainerMargin{
                margin-top: 82px;
            }
            
            .ShadowCalendar{
                box-shadow: 0 0 3px #bcbcbc;
                width: 100%;
                border-radius: 6px;
            }
            .Calendarup{
                background-color: #008C8D ;
                color: white;
            padding: 4px;
               border-top-left-radius: 6px;
               border-top-right-radius: 6px;
             /*   text-align: center; */
               
            }
            .Calendardown{
            color: grey;
            height: 120px;
            overflow-y: scroll;
            width: 100%;
            
            }
            .BasketBody{
            color: grey;
            height:auto;
            position: relative;
            width: 100%;
            
            }
            .iconsSize{
                 font-size:1.2rem !important
            }
            .greyicon{
            color: #bcbcbc;
            }
            .Clockandtext{
                display: flex;
                /* grid-template-columns: 7% 40%; */
                align-items: center;
                justify-content: center;
            }
            .liServices{
                padding: 0 0 20px 0;
            }
            .UlCategory{
             /*    padding:0px 4px 0 4px; */
                padding: 0px;
                color:black;
                line-height:180%;
                display: flex;
                flex-direction: column;
                align-items:flex-start ;
                
            }
            .UlCatMenu{
      
                /* width:126.5%;
                right: -140px; */
                width: 100%;
                right: 0px;
                min-width: 158px;
                background-color: white;
                box-shadow: 0 0 2px #bcbcbc;
                padding:  10px 0px 0px 0px; 
                position: absolute;
                list-style: none;
                top: 50px;
                height: 450px;
                overflow-y: auto;
                overflow-x: hidden;
          
            }
            .UlCatMenu a{
                /* display: flex; */
                color:black;
                line-height: 200%;
           
                font-size: 0.7rem;
                margin-bottom: 0px;
                padding: 0px 15px;
         
            }
            .UlCatMenu button{
                color:black;
                line-height: 200%;
                font-size: 0.7rem;
                margin-bottom: 0px;
                padding: 0px 15px;
            }
            .GridUlCatMenu{
                display: grid !important;
                grid-template-columns: repeat(2,1fr);
                /* height: 100%; */
            }
            .GridUlSubCatMenu{
                display: grid !important;
                grid-template-columns: repeat(2,1fr);
               
            }
      .UlSubCatMobile{
        background-color: white;
        box-shadow: 0 0 2px #bcbcbc;
        height: 100vh;
        max-height: 100vh;
        min-height: fit-content;
      }
            .UlSubCatMenu{
                flex-direction: column;
                width: 100%;
                left: 0%;
                height:100%;
                min-width: 158px;
                background-color: white;
                box-shadow: 0 0 2px #bcbcbc;
                padding: 10px 0px 0px 0px;
                position: absolute;
                list-style: none;
                top: 0px;
                /* overflow-y: scroll; */
    
            }
            [dir="ltr"] .UlSubCatMenu{
                left:52%;
            }
            .UlSubCatMenu a , .UlSubCatMenu .headLine{
                /* display: flex; */
                color:black;
                line-height: 200%;
           
                font-size: 0.8rem;
                margin-bottom: 0px;
                padding: 0px 15px;
         
            }
            .UlCatAll{
              
                flex-direction: column;
                width:100%;
                height:450px;
                min-width: 158px;
                background-color: #F9FBFF;
                box-shadow: 0 0 2px #bcbcbc;
                padding:  10px 0px 0px 0px; 
                position: absolute;
                list-style: none;
                top: 50px;
                overflow-y: auto;
          
            }
            .UlCatAll a ,.UlCatAll span{
                /* display: flex; */
                color:#000 ;
               
                font-weight: 100 !important;
                font-size: 0.7rem !important;
                margin-bottom: 0px;
                padding: 0px 15px ;
                width: 100%;
         
            }
             .UlCatAll Button{
                display: flex;
                color:#000 ;
                line-height: 200%;
                font-weight: 100 !important;
                font-size: 0.7rem !important;
                margin-bottom: 0px;
                padding: 0px  15px !important;
         
            }
            /* .UlCatAll Button:hover{
                color:#008C8D  !important;
                background-color: transparent !important;
            } */
       
            .UlServices{
                padding:0px 27px 0 0;
                /* color:#008C8D ; */
                line-height:180%;
                margin-bottom:0 !important;
            }
            [dir="ltr"] .UlServices{
                padding:0px 0 0 27px;
                /* color:#008C8D ; */
                line-height:180%;
                font-size: 1rem;
            }
            
            .UlServicesDesktop{
            width:157.99px;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
             padding:  10px 10px 0px 0px; 
            position: absolute;
            list-style: none;
            
            
            
            }
            [dir="ltr"] .UlServicesDesktop{
            width: 157.99px;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
            padding:  10px 0px 0px 10px; 
            position: absolute;
            list-style: none;
            
            }
            .UlServicesDesktop > a , .UlServicesDesktop > div ,.UlServicesDesktop > button {
                color: grey;
                line-height: 200%;
                font-weight: 600;
                font-size: 0.9rem;
                margin-bottom: 10px;
                padding: 0px 15px;
                appearance: none;
                border: none;
            }
        
            .UlServicesDesktop a:hover , .UlServicesDesktop div:hover,.UlServicesDesktop > button:hover {
                color: #008C8D ;
                text-decoration: none;
                outline: 0;
            }
            .UlServicesDiet{
            width:auto;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
             padding:  15px ; 
            position: absolute;
            list-style: none;
            z-index: 10;
     
            
            
            
            }
            [dir="ltr"] .UlServicesDiet{
            width:auto;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
            padding:  15px ; 
            position: absolute;
            list-style: none;
            
            }
            .UlServicesDiet  {
                color: grey;
                line-height: 200%;
                font-weight: 600;
                font-size: 0.9rem;
                margin-bottom: 10px;
                padding: 0px 15px;
            }
        
            .UlServicesDiet a:hover{
                color: #008C8D ;
                text-decoration: none;
                outline: 0;
            }
            .UlServicesDesktopSubMenu{
            width:100%;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
             padding:  10px 0px 0px 0px; 
            position: absolute;
            list-style: none;
            margin-right: 161px;
            top: 170px;
            
            
            
            }
            [dir="ltr"] .UlServicesDesktopSubMenu{
            width:100%;
            min-width: 158px;
            background-color: white;
            box-shadow: 0 0 3px #bcbcbc;
            padding:  10px 0px 0px 0px; 
            position: absolute;
            list-style: none;
            margin-left: 182px;
            top: 170px;
            
            }
            .UlServicesDesktopSubMenu > a{
                color: grey;
                line-height: 200%;
                font-weight: 600;
                font-size: 0.9rem;
                margin-bottom: 10px;
                padding: 0px 15px;
            }
            
            .backgroundSlogen{
                background-color: #E7FBFA;
                width: 100%;
                padding: 20px;
                border-radius: 16px;
                margin: auto;
            }
            .backgroundSlogenDialog{
                background-color: #E7FBFA;
                width: 85%;
                padding: 8px;
                border-radius: 16px;
                margin: auto;
            }
            /* .borderLeft{
                border-left:none;}
            [dir="ltr"] .borderLeft{
                border-left:none;} */
             .BackGroundOfferheader{
                position: absolute;
                width: 1.8rem;
                z-index: 0;
                top: 0px;
                right: 6px;
                }
                [dir="ltr"] .BackGroundOfferheader{
                    position: absolute;
                    width: 1.8rem;
                    z-index: 0;
                    top: -1px;
                    left: 6px;
                }
            .menuflex{
                flex:  0 0 15%;
            }
            .flexlogonav{
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                justify-items: center;
            /*     flex: 0 0 60%; */
            }
            .stylebasketnav{
                display: flex;
                /* grid-template-columns: auto auto auto; */
               /* gap: 1rem; */
               gap:0.5rem;
                flex: 0 0 15%;
                justify-content: end;
                align-items: center;
                position: relative;
                z-index: 4;
             
            }
            .bloodtextfooter{
                font-weight: 600;
                font-size: 0.9rem;
                text-align: initial;
                margin-bottom: 1rem;
            }
            .textfooter{
                
                font-size: 0.8rem;
            }
            
            .markandheart{
                position: relative;
                right:77%;
                top:0px;
            }
            
            [dir="ltr"] .markandheart{
                position: absolute;
                left: 82%;
                top:0px;
            }
            .markandheartdetails{
                position: absolute;
                right:88%;
                /* right:85%; */
                top:0px;
            }
            
            [dir="ltr"] .markandheartdetails{
                position: absolute;
                left: 88%;
                /* left: 85%; */
                top:0px;
            }
            .markandheartMedical{
                position: absolute;
                left:88%;
                top:-2px;
            }
            
            [dir="ltr"] .markandheartMedical{
                position: absolute;
                left: 0%;
                right:0;
                top:-2px;
            }
            .markandheartBlog{
                position: absolute;
                right:83%;
                top:-1px;
            }
            
            [dir="ltr"] .markandheartBlog{
                position: absolute;
             
                left:83%;
                top:-1px;
            }
            .markandheartproduct{
                position: absolute;
                /* right:81%;   view one product*/
                right:72%;
                top:0px;
                width: 50px;
            }
            
            [dir="ltr"] .markandheartproduct{
                position: relative;
                /* left: -1%; */
                left: 72%;
                top:0px;
                width: 50px;
            }
            .markandheartnav{
                position: relative;
                right:77%;
                top:-7px;
            }
            [dir="ltr"] .markandheartnav{
                position: absolute;
                left: 82%;
                top:-7px;
            }
            
            .mark{
                font-size: 3.5rem !important;
                position: absolute;
                color:#008C8D ;
                width: 2.5rem;
            
            }
            [dir="ltr"] .mark{
                font-size: 3.5rem !important;
               width: 2.5rem !important;
                color:#008C8D ;
                position: absolute;
              
            }
    
            .markdetails{
                font-size: 3.5rem !important;
                position: absolute;
                color:#008C8D ;
                width: 2.2rem;
            
            }
            [dir="ltr"] .markdetails{
                font-size: 3.5rem !important;
          
                color:#008C8D ;
                position: absolute;
              
            }
            .markproduct{
                font-size: 3.5rem !important;
                width: 2rem !important;
                position: absolute;
                color:#008C8D ;
                height: auto !important;
                object-fit: contain !important;
            }
            [dir="ltr"] .markproduct{
                font-size: 3.5rem !important;
                color:#008C8D ;
                position: absolute;
                height: auto !important;
                object-fit: contain !important;
              
            }
            .heart{
                font-size: 1.5rem !important;
                width: 1.5rem !important;
                /* color:#d9245f; */
                position: absolute;
                right:4px; 
                top:4px;
            }
            [dir="ltr"] .heart{
                font-size: 1.5rem !important;
              
                /* color:#d9245f; */
                position: absolute;
                left:4px; 
                top:4px;
            }
            .heartdetails{
                font-size: 1.5rem !important;
                width: 1.4rem !important;
                /* color:#d9245f; */
                position: absolute;
                right:1px; 
                top:1px;
            }
            [dir="ltr"] .heartdetails{
                font-size: 1.5rem !important;
               
                /* color:#d9245f; */
                position: absolute;
                left:1px; 
                top:1px;
            }
            .heartproduct{
                font-size: 1.5rem !important;
                width: 1.5rem !important;
                height: 1.5rem !important;
                /* color:#d9245f; */
                position: absolute;
                right:2px; 
                top:2px;
                object-fit: contain;
            }
            [dir="ltr"] .heartproduct{
                font-size: 1.5rem !important;
    
                /* color:#d9245f; */
                position: absolute;
                /* left:22px;  */
                left: 2px;
                top:3px;
                object-fit: contain;
            }
    
    
    
    
    
    
            .heartblog{
                font-size: 1.5rem !important;
                width: 1.5rem !important;
                height: 1.5rem !important;
                /* color:#d9245f; */
                position: absolute;
                right:4px; 
                top:4px;
                object-fit: contain;
            }
            [dir="ltr"] .heartblog{
                font-size: 1.5rem !important;
    
                /* color:#d9245f; */
                position: absolute;
                left:4px; 
                top:4px;
                object-fit: contain;
            }
        
            .headerFAQ{
                font-size: 1.1rem;
                color:black;
                border-top:  3px solid #008C8D ;
                border-bottom: 3px solid #008C8D ;
                padding: 1% 0 1% 0;
                width: 50%;
                text-align: left;
            
            }
            [dir="ltr"] .headerFAQ{
    
                color:black;
                
                border-top:  3px solid #008C8D ;
                border-bottom: 3px solid #008C8D ;
                padding: 1% 0 1% 0;
                width: 50%;
                text-align: right;
            
            }
            .textminimun{
                font-size: 1.2rem;
                color: black;
                font-weight: 600;
                text-align: center;
            }
            
            .Asbutton{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .mealscheck{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .Asbutton input[type="radio"]{
                opacity: 0.011;
                z-index: 100;
            }
            .mealscheck input[type="checkbox"]{
                opacity: 0.011;
                z-index: 100;
            }
            .Scrollbutton{
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 0;
            }
            
            .Scrollbutton input[type="radio"]{
                opacity: 0.011;
                z-index: 100;
            }
            .btnradio{
                padding: 6px 6px;
                color: white;
                 background-color:#F52A59;
                 border: none;
                 border-radius: 50px;
                 font-size: 1.1rem;
                 font-weight: 600;
                 width: 100%;
             
               
            }
            .btnradioDiet{
                padding: 2px 6px;
               
                 /* background-color:#F52A59; */
                 border: 0.5px solid black;
                 border-radius: 0px;
         
                 font-weight: 600;
                 width: 100%;
             
               
            }
            .selectTime{
              color:#F52A59
             
               
            }
            .notselect{
                color: grey;
            }
            .Asbutton label{
                z-index: 1;
                cursor: pointer;
                line-height: 1em;
                font-size: 1.1rem;
            }
            .mealscheck label{
                z-index: 1;
                cursor: pointer;
                text-align: center;
    
            }
            .subCalendarcard1{
                font-size:1.7rem;
              
            }
            .subalendarcard2{
                font-size: 1rem;
                color:#bcbcbc
            }
            .pcardinfo1{
                font-weight: 600;
                color: #008C8D ;
                font-size: 1rem;
                margin: 0;
            }
            .pcardinfo2{
                font-weight: 600;
                font-size: 1.2rem;
                margin: 0;
            }
            .AtagIcon{
                text-decoration: none;
              
                color: none;
            }
            .hoverLink:hover{
            text-decoration: underline;
            text-decoration-color: black !important;
            }
            .pinkname{
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 0;
            
            }
            .h1s7{
                font-size: 0.7rem;
            }
            .categoryname{
                font-size: 0.9rem;
            }
            .Effectstext{
                font-size: large;
                text-align: initial;
            }
            /* .HakeemSection2{
             padding: 0 0 0 0; 
            } */
            .Effects{
                font-size: 1.5rem;
                text-align: initial;
            }
            /* productsliderDetails */
            
            .galleryContainer{
                position: relative;
                /* width: 300px; */
                width: 352px;
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                overflow: hidden;
                gap: 0.3rem;
            }
            .galleryMain{
                width:350px !important;
                height: 300px;
                box-shadow: 0 50px 75px 50px rgba(#1e1e1e,0.18);
                border:1px solid #e9e9e9e9
            }
            
            .galleryTitle{
                /* position: absolute; */
                top:0px;
                right:0px;
              
                
            }
            .galleryTitle img{
                object-fit: contain !important;
            }
            .galleryThumbs{
                order: -1;
                width: 100px;
                height: 300px;
                margin-right: 0px;
                margin-left: 0px;
                flex-direction: column;
                cursor: pointer;
                
            }
     
      
            .galleryThumbs img{
                object-fit: cover !important;
                border: 1px solid #ebebeb;
            }
            
            
            .myswiper2{
                width: 290px;/*or 236 */
                height: 300px;
                
             /*     margin-left: 28px !important;
                margin-right:  7px !important;  */
            /*     padding-bottom: 5px !important; */
            }
            
            
            .myswiper{
                box-sizing:border-box;
                height: 100%;
                width: 350px ;
            }
            .myswiper .swipler-slide{
                width: 25%; 
                height:100% ;
               /*  width: 100%; */
                opacity: 0.5;
            }
            .myswiper .swipler-slide-thumbs-active{
                opacity: 1;
            }
            .swiplerslideimg{
                height: 57px !important;
                width: 85px !important;  
            }
            
            /* ----------------------------------------- */
            .sizeArtical{
                width: 84%;
                margin: auto;
            }
            .sizeAvatarcard{
                width: 125px !important;
                margin: auto;
            }
            .ProfileUser{
                box-shadow:0px 0px 3px grey;
                border-radius: 50%;
            }
            .pricenow{
                color:#008C8D ;
                text-align: center;
                font-weight: 600;
            }
            .priceBefore{
                color:#F52A59;
                text-align: center;
                font-weight: 600;
            }
            .pproducttext{
                text-align: initial;
                font-size: 0.9rem;
                margin:0;
                /* padding: 10px 0; */
            
            }
            .pproducttext h3 span{
                text-align: initial;
                font-size: 0.9rem;
                margin:0;
                color: #F52A59 !important;
            }
            .pproducttext ul{
              padding-right: 0px;
              padding-left: 0px;
            }
            [dir="ltr"] .pproducttext ul{
              padding-right: 0px;
              padding-left:0px;
            }
            .pproducttext ul li  span strong span{
                color:#F52A59 !important
            }
            .pproducttext ul li  span strong{
                color:#F52A59 !important
            }
            .pproducttexttop{
                text-align: initial;
                font-size: 0.9rem;
                margin-bottom: 0;
            }
            .producttext{
            width: 100%;
            padding-top: 0px;
            
            }
            .producttext > Ul{
                margin-left: 4px;
                margin-right:4px ;
            } 
            .accountcreation{
             
            
                text-decoration: none;
                color:#bcbcbc;
            }
            .forgetPassword{
                text-decoration-line: none;
                padding-top: 10px;
                padding-left: 6px;
                padding-right: 6px;
            
                
            }
            .parforgetPassword{
                text-decoration-line: none;
                padding-top: 10px;
                padding-left: 6px;
                padding-right: 6px;
            
            }
            
            .searchboxcontainer{
                
                    margin: 0px auto;
              
               
            }
            .paddingSearch{
                padding: 40px 0px 20px 0px !important ;
            }
            [dir="ltr"].searchboxcontainer{
                direction: ltr; 
            }
            .searchbox{
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-around;
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2),
                0 -10px 10px 0 rgba(0, 0, 0, 0.2);
                border-top-left-radius: 11% 80%;
                border-bottom-left-radius: 11% 80%;
                border-top-right-radius: 13% 80%;
                border-bottom-right-radius: 13% 80%;
                overflow: hidden;
            }
            .searchboxhome{
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-around;
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2),
                0 -10px 10px 0 rgba(0, 0, 0, 0.2);
                border-top-left-radius: 11% 80%;
                border-bottom-left-radius: 11% 80%;
                border-top-right-radius: 13% 80%;
                border-bottom-right-radius: 13% 80%;
                overflow: hidden;
            }
            
            .greycircle{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
            
                padding:0px 0px;
                border-radius: 50%;
                background-color: white;
            }
            .greycircleborder{
                box-sizing: border-box;
                border:  1px solid #bcbcbc;
                width: 60%;
                padding:0px 0px;
                border-radius: 50%;
                background-color: white;
            }
            .greycircleAll{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                width: 150px;
                height: 150px;
                padding:0px 0px;
                border-radius: 50%;
                background-color: white;
            }
            .greyProductCard{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                width: 60%;
                padding:0px 0px;
            }
            .colorTeal{
                background-color: #008C8D  ;
            }
            .Favorite{
                Margin-top: 10px;
                z-index: 2;
                position: relative;
            }
            .imgproduct{
            width: 100%;
            height: 100%;
            }
            .yellowsquare{
               box-sizing: border-box;
               background-color: #faaf00;
               border: 0px solid #faaf00;
               width: 24%;
               padding: 5px 0px 5px 0px;
               left: 84%;
               right:0%;
               border-radius: 0px 0px 12px 0px; 
               bottom: 0;
               color: white;
               position: absolute;
               text-align: center;
            }        
            [dir="ltr"] .yellowsquare{
                padding: 5px 0px 5px 0px;
                right: 73%;
                left: 0%;
              
                border-radius: 0px 0px 0px 12px;
        
            } 
            .yellowsquare2{
                box-sizing: border-box;
                background-color: #faaf00;
                border:0px solid #faaf00;
                padding: 5px 0px 5px 0px;
                /* right:87%; */
                /* border-radius: 12px 0px 0px 0px; */
                color: white;
                width:13%;
                text-align: center;
                position: absolute;
            }     
    
            [dir="ltr"] .yellowsquare2{
                padding: 5px 0px 5px 0px;     
                /* left:87%; */
                /* border-radius: 0px 12px 0px 0px; */
            } 
    
             .Statesquare{
                box-sizing: border-box;
                background-color: #faaf00;
                border:0px solid #faaf00;
                text-align: center;
                padding:5px;
                left:0%; 
                bottom: 0;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                font-size: 20px;
                text-align: center;
                color: white;
                margin-bottom: 0;
                
            } 
             .buttonAddProduct{
                box-sizing: border-box;
                background-color: white;
                border: 1px solid #bcbcbc;
                text-align: center;
                padding:10px 30px;
                width: 100%;
                font-size: 1rem;
                text-align: center;
                color: black;
                margin-bottom: 0;
                
            } 
             
            
            
            .Open{
                box-sizing: border-box;
                background-color: #008C8D ;
                border:0px solid  #008C8D ;
                width:13%;
                text-align: center;
                padding: 5px;
                position: absolute;
                left:0;
                color: white;
                bottom: 0;
                border-top-right-radius: 10px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px; 
                font-size: 1rem; 
            } 
             .Add{
                box-sizing: border-box;
                background-color: #008C8D ;
              border:none !important;
                width:100%;
                text-align: center;
                height: 45px; 
                display: flex;
                align-items: center;
                justify-content: center;
                /* padding:10px; */
             /*    position: absolute; */
                left:0%; 
                bottom: 0;
            /*     border-bottom-left-radius: 12px;
                border-bottom-right-radius: 0px; */
                border-radius: 12px;
                font-size: 20px;
                text-align: center;
                color: white;
                margin-bottom: 0;
                
            } 
            
             .ClickAdd{
                box-sizing: border-box;
                background-color: white;
                border:0px solid #008C8D ;
                width:100%;
                text-align: center;
                /* padding:5px; */
             /*    position: absolute; */
                border:0.5px solid #ccf0ef ;
                left:0%; 
                bottom: 0;
               /*  border-bottom-left-radius: 12px;
                border-bottom-right-radius: 0px; */
                border-radius: 12px;
                font-size: 20px;
                text-align: center;
                color: black;
                margin-bottom: 0;
               
                
            } 
             .BasketAdd{
                box-sizing: border-box;
                background-color: white;
                border:0px solid #008C8D ;
                width:50%;
                text-align: center;
                padding:5px;
                margin: auto;
                border:0.5px solid #ccf0ef ;
                border-radius: 12px;
                font-size: 1rem;
                text-align: center;
                color: #008C8D ;
                margin-bottom: 0;
            } 
            .UlTerm ul {
                padding: 0px 20px;
            }
        
             .Addcomfirmation{
                box-sizing: border-box;
                background-color: #008C8D ;
                border:0px solid #008C8D ;
                width:100%;
                text-align: center;
                padding:6px 15px;
                margin: auto;
                border:0.5px solid #ccf0ef ;
                border-radius: 22px;
                font-size: 1rem;
                text-align: center;
                color: white;
                margin-bottom: 0; 
                
            } 
            /* [dir="ltr"] .BasketAdd{
            
                border-bottom-left-radius: 0px;
               border-bottom-right-radius: 12px;
             
               }  */
            .ButtonOrder{
                background-color: #008C8D  !important;
                color: white !important;
                width: 80%;
            }
            
            
            .Close{
                box-sizing: border-box;
                background-color:#F52A59;
                border:0px solid  #F52A59;
                width:13%;
                text-align: center;
                padding: 5px;
                position: absolute;
                left:0;
                color: white;
                font-size: 1rem;
                bottom: 0;
                border-top-right-radius: 10px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                
            }
            
             [dir="ltr"] .PinkNameSquare{
                box-sizing: border-box;
                background-color: #F52A59;
                border:4px solid #F52A59;
                border-right-color: #008C8D ;
                border-left-color:  #F52A59;
             
                width:30%;
                text-align: center;
                padding: 0px 0px 0px 0px;
                position: absolute;
                left:0%;
                top:0;
                color: white;
                border-radius: 0px;
                
            }  
            .PinkNameSquare{
                border-right-color:#F52A59 ;
                border-left-color:   #008C8D ;
                right:0%;
              
            }
            .topthree{
                text-align: center;
                padding-top:20px;
                display: grid;
                padding-bottom: 20px;
            }
            .icontext{
                font-size: 0.9rem;
                margin: 0;
                color: black;
            }
            .textaboutdoctor1{
                font-size: 1rem;
             /*   margin-bottom: 0;  */
                color: black;
                overflow: hidden;
               
            }
            .TextBasket1{
                font-size: 1.5rem;
            /*    margin-bottom: 0;  */
                color: black;
                text-align: initial;
            }
            .Text{
                font-size: 1.5rem;
            /*    margin-bottom: 0;  */
                color: black;
                text-align: initial;
            }
            .textaboutresturant1{
                font-size: 1.1rem;
                margin-bottom: 8px;
              /*   margin-bottom: 0; */
                color: black;
            }
            .textaboutdoctor1card{
                font-size: 1.1rem;
                margin-bottom: 0;
                color: #008C8D ;
                overflow: hidden;
                font-weight: 600;
            }
            .textaboutdoctor2{
                font-size: 0.9rem !important;
                /* margin-bottom: 8px; */
                color: black;
                /* overflow: hidden; */
                
            }
            .TextBasket2{
                font-size: 1rem;
                margin-bottom: 0;
                color: black;
                text-align: initial;
            }
            .fontNum{
                font-size: 1rem;
                margin-bottom: 0;
                color: black;
              
                        text-align: right;
            }
            .fontNum1{
                font-size: 1rem;
                margin-bottom: 0;
                color: black;
              
                text-align: center;
            }
            [dir="ltr"] .fontNum{
                font-size: 1rem;
                margin-bottom: 0;
                color: black;
            
                text-align: left;
            }
            [dir="ltr"] .fontNum1{
                font-size: 1rem;
                margin-bottom: 0;
                color: black;
            
                text-align: center;
            }
            .textaboutdoctor2card{
                font-size: 1rem;
                margin-bottom: 0;
                /* overflow: hidden; */
            }
            .textaboutdoctor3{
                font-size: 0.9rem !important;
                margin-bottom: 0;
                color: black;
                padding: 4px;
                overflow: hidden;
                
            }
            .TextBasket3{
                font-size: 0.9rem;
                margin-bottom: 0;
                color: black;
                text-align: initial;
                
            }
            .textaboutdoctor3card{
                font-size: 0.9rem;
                margin-bottom: 0;
                overflow: hidden;
            }
            
            .avatercard{
                box-shadow:0px 0px 3px grey;
                border-radius: 50%;
                width:125px;
                height: 125px;
             
            }
            .imgpinksearch{
                width: 55px;
                z-index: 3;
            }
            
            .form{
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: right;
                border-radius: 28px;
                padding:24px;
                width: 70%;
                margin: auto;
                display: grid;
                align-items: center;
               
              
            }
            .formSearch{
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: right;
                border-radius: 12px;
                padding:24px;
                width: 100%;
                margin: auto;
                display: grid;
                align-items: center;
                grid-template-columns: 100%;
               
              
            }
            .formDietSearch{
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: right;
                border-radius: 12px;
                padding:24px;
                width:100%;
                margin: auto;
                display: grid;
                align-items: center;
                gap: 1rem;
               
              
            }
            .label{
                background-color:white;
                flex: 0.4;
                border: 0;
                outline: none; 
                font-size: 9px;
                font-weight: 600;
                color: grey;
                margin: 0;
              
            }
            .select{
                border: none;
                font-size: 1.2rem;
                color:grey;
                width: 100%;
            }
            
            .signIn{
              padding: 4px 8px;
               color: white;
                background-color:#008C8D ;
                border: none;
                border-radius: 7px;
                font-size: 0.8rem;
            font-weight: 600;
            width: 109.52px;
              height: auto;
             
            
            }
            .clickSignin{
              padding: 0px;
              color: #fff;
              background-color:transparent;
              border: none;
              /* border-radius: 10px; */
              font-size: 1rem;
              font-weight: 600;
              width: 100%;
              height: auto;
             
            
            }
            .Time{
              padding: 10px 10px;
               color: white;
                background-color:#F52A59;
                border: none;
                border-radius: 50px;
                font-size: 1.1rem;
            font-weight: 600;
              width: 100%;
              height: auto;
             
            
            }
            .sizeAvatar{
                width:60px !important;
                height:60px !important;
                box-shadow:0px 0px 3px grey;
                border-radius: 50% !important;
            }
            .sizeAvatarWithoutborder{
                width:300px !important;
                height:300px !important ;
            
            }
            .sizeAvatarProfile{
                width:80px !important;
                height:80px !important;
                box-shadow:0px 0px 3px grey;
                border-radius: 50%;
            }
            .sizeAvatarcard{
                width:100px !important;
                height:100px !important;
                box-shadow:0px 0px 3px grey;
                border-radius: 50%;
            }
            .ProfileUser{
                box-shadow:0px 0px 3px grey;
                border-radius: 50%;
            }
            .sizestars{
                font-size: 1.5rem !important;
                width:122px !important;
               
               
            }
            .sizestarscomment{
                font-size: 1.2rem !important;
                width:154px !important;
               
               
            }
            .sizestarsproduct{
                font-size: 2rem !important;
                width:154px !important;
               
               
            }
            .sizestarscard{
                font-size: 1.2rem !important;
            /*     width:154px !important; */
               
               
            }
            .Container{
                padding: 0px;
                width: 100%;
                margin-left:auto;
                margin-right: auto;
                max-width: 357px !important;
            }
            .ContainerDownload{
                /* padding: 40px 40px; */
                padding: 40px 0px ;
             
            }
            .colorDownloadsection{
                background-color:#f4f6f8 !important; 
            }
        
            
            .formandbutton{
                text-align: center;
                    align-items: center;
                padding-top:20px;
            
                  display: grid;
                  padding-bottom: 20px;
            }
            .searchbtn{  
                 padding: 5px 10px;
                 color: white;
                 background-color:#F52A59;
                 border: none;
                 border-radius: 10px;
                 width: 40%;
                 height: auto;
                 font-size:1.3rem;
                 z-index: 1;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
                 margin-top: -4%;
                }
            
            .Booknow{ 
                padding: 10px 10px;
                color: White;
                background-color:#F52A59;
                border: none;
               /*   border-radius: 10px; */
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                width: 100%;
                height: auto;
                font-size:1rem;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }
            .Booknow2{  padding: 10px 10px;
                color: white;
                 background-color:#F52A59;
                 border: none;
               /*   border-radius: 10px; */
                 border-radius: 16px;
                 width: 60%;
            
                height: auto;
               font-size:1.5rem;
               z-index: 1;
               display: flex;
               align-items: center;
               justify-content: center;
               margin-left: auto;
               margin-right: auto;
            }
            
            .SeeMore{ 
                padding: 7px;
                color: white;
                background-color:#008C8D ;
                border: none;
                border-radius: 6px;
               
                width: 100%;
            
                height: auto;
                font-size:1rem;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }
            .change{  
                 padding: 5px 10px;
                 color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 0px;          
                 width: 100%;
                 height: auto;
                 font-size:1rem;
                 z-index: 2;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
            }
            .Buy{  padding: 5px 10px;
                color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 6px;
               
                 width: 33%;
            
               height: auto;
               font-size:1.5rem;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto
            
            }
            .BackgroundHakeemMedical{
                background-image: url("/assest/BGM.webp");
            
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: auto;
                margin-left: 0px ;
                
                background-position: initial;
              
              
            }
            .BackgroundDiet{
       
            
                background-position: center;
                background-repeat: no-repeat;
                background-size: 150%;
                height: auto;
                margin-left: 0px ;
                
                background-position: center;
              
              
            }
            .BackgroundGreySquare{
       
            
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: auto;
                margin-left: 0px ;
                
                background-position: initial;
              
              
            }
            .BackgroundGreySquareAbout{
       
            
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: auto;
                margin-left: 0px ;
                
                background-position: center;
              
              
            }
            .SectionContainer{
                width: 100%;
                margin-left:auto;
                margin-right: auto;
                box-sizing: border-box;
                display: block;
                padding:15px 0px;
                /* max-width: 357px; */
                /* max-width: 372px; */
                max-width: 353px;
                min-height: min-content;
            
            }
            .SectionContainerDeleteAccount{
                width: 100%;
                margin-left:auto;
                margin-right: auto;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                padding:15px 0px;
                /* max-width: 357px; */
                /* max-width: 372px; */
                max-width: 353px;
             
                
            
            }
            .div1DeleteAccount{
                width: 100%;
                max-width: 100%;
            margin-bottom: 10px;
            
            
            }
            .div2DeleteAccount{
                width: 100%;
                max-width: 100%;
            
            }
            .paddingComfirmation{
                padding: 20px 0px !important;
            }
            .card2{
                padding-top: 20px;
            }
            .starimg{
                width: 1em;
            }
            .HeaderMadicalHakeem{
                text-align: center;
                    padding-top: 20px;
                    color:#008C8D  ;
                    font-size: 2.5rem;
                    font-weight: 600;
                 
                   
            }
            .HeaderDietHakeem{
                text-align: center;
                  
                    color:#F52A59 ;
                    font-size: 2.5rem;
                    font-weight: 600;
                 
                   
            }
            .HeaderDietHakeem2{
                text-align: center;
                
                    color:black ;
                    font-size: 2rem;
                 
                 
                   
            }
            .EnterEmail{
                
                text-decoration: none;
                color:#bcbcbc;
                font-size: 1.1rem;
                
            }
            .HeaderLogin{
                
                    padding-top: 20px;
                    color:#008C8D  ;
                    font-size: 2rem;
                    font-weight: 600;
               
                   letter-spacing: 1px;
            }
            .HeaderMadicalHakeem{
                text-align: center;
                    padding-top: 50px;
                    color:#008C8D  ;
                    font-size: 1.5rem;
                    font-weight: 600;
                 
                   
            }
            .HeaderMadicalHakeem2{
                text-align: center;
                padding-top: 20px;
                color:#008C8D  ;
                font-size: 1.5rem;
                font-weight: 600;
             
            }
            .HeaderMadicalHakeem3{
                text-align: center;
                padding-top: 20px;
                color:black ;
                font-size: 1rem;
                font-weight: 600;
             
            }
            .HeaderMadicalHakeem4{
                text-align: center;
                padding-top: 0px;
                color:black ;
                font-size: 1rem;
              
             
            }
            .cardHakeemMedical{
            
               align-items: center;
            
              
            }
            .backgroundDiscover{
               /*   background-image: url("/assest/Mask Group 111.svg");  */
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
                margin: 0px ;
                background-color: white;
                background-position: initial;
            }
            .headerDiscover{
                text-align: center;
              
                color:#F52A59 ;
                font-size: 2rem;
            
            }
            
            .Discoverimagesbox{
                width: 100%;
                border-radius: 1%;
                padding:  40px 0px;
                box-shadow: 0 0 3px #bcbcbc;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .discoverimag1{
                width: 14em;
                height: 10em;
                max-width: 550px;
            }
            .discoverimag2{
                width: 12rem;
                height: 10em;
                max-width: 550px;
            }
            .discoverimag3{
                width: 12em;
                height: 10em;
                max-width: 550px;
            }
       
            .dot{
                width: 2.5rem;
                color: #F52A59;
            }
            
            .svglottie{
                width: 50px;
            }
            .logo{
                /* width: 5rem !important;
                height: 3.5rem; */
            /* flex: 0 0 49%; */
            position: relative;
            z-index: 3;
        }
    
            
            .column2{
                float: left;
                width: 50%;
                height: 200px;
                padding-top: 60px;
                padding-left: 100px;
            }
            .column3{
                float: right;
               width: 50%;
               height: 200px;
               padding-top: 60px;
               padding-right: 10px;
            }
            .medical{
                width: 40%;
            
                padding: 20px 5px;
                border-radius: 30px;
                box-sizing: border-box;
                background-color: white;
            
            }
            .food{
                width: 40%;
               
                padding: 20px 5px;
                border-radius: 30px;
                box-sizing: border-box;
                background-color: whitesmoke;
                
            }
            .searchs6 {
                width: 2.5rem;
                height: 2.5rem;
        
               
            
            }
            .searchs6Examination {
                width: 3.3rem;
                height: 3.3rem;
                padding: 10%;
                margin: auto;
               
            
            }
            .paddingtopex{
                padding-top: 1%;
            }
            .searchsimg {
                width: 4rem;
                height: 130px ;
                padding-Top:10% ;
               
            
            }
            .search1 {
                width: 50%;
                height: auto;
                padding-Top:10% ;
            }
            .pimage{
                width: 100%;
                height: auto;
                padding:10px ;
            }
            .p4img{
                width: 100%;
            }
            
            .h1s5{
                color:#F52A59;
              text-align: center;
              font-size: 1.5rem;
            }
            .nameProduct{
                color:black;
                text-align: initial;
                font-size: 1rem;
                font-weight: 600;
                margin-bottom: 8px;
            }
            .RelatedProducts{
                color:black;
                text-align: initial;
                font-size: 1.5em;
            
            }
            
            .h1s6{
                color:black ;
              text-align: center;
              font-weight: 600;
              font-size: 0.7rem;
            }
            .subtext{
                font-size: 0.6rem !important;
            }
            .p5{
                text-align: center;
                font-size: 1rem;
            }
            
        
            .typo6{
                text-align: center;
                flex-grow: 1;
                padding-right: 6%;
            }
            .hLine6{
                text-align: center;
                padding-top: 6%;
                color: black;
                font-size: 1rem;
              
            }
            .hLine7{
             padding-left: 3%;
             padding-top: 6%;
             padding-right: 3%;
             font-size: 1rem;
             text-align: initial;
             
             
            }
            .lineProduct{
            /*     padding-left: 3%;
                padding-right: 3%;  */
                font-size: 1.2rem; 
            }
            .ArticalTitle{
                font-size: 1rem;
                padding-left: 3%;
                padding-top: 4%;
             padding-right: 3%;
             font-weight: 600; 
            }
            .h1s7{
                color:black ;
                 
            
            
                 align-items: flex-start;
            
               
            }
            
            .profileimg{
                width: 2rem;
            }
            .searchs7 {
                width: 2.5rem;
                height: 2.5rem;
            
               
              
            }
            .searchs7White {
                width: 2.5rem;
                height: 2.5rem;
            
               
              
            }
            .basketnav {
                width: 1.3rem !important;
                height: 1.3rem !important;
            
               
              
            }
            .Countrynav {
                width: 1.5rem;
                height: 1.5rem;
                position: relative;
                top:-8px
            
               
              
            }
            .searchs11 {
                width: 40%;
                height: 40%;
            
               
              
            }
            .hLine8{
                text-align:left;
                padding-top: 3%;
                padding-left: 3%;
            }
            .h1s8{
                color:rgb(83, 77, 77) ;
                 text-align: center;
             
            
            }
            .searchs8 {
                width: 40%;
                height: 40%;
               
               
              
            }
            .gridcontainer{
                display:grid;
                column-gap: 50px;
               grid-template-columns: auto auto auto auto auto;
              
               justify-content: center;
              
               
            }
            .griditem{
             
                padding: 20px;
                font-size: 30px;
                text-align: center;
                justify-content: center;
            }
            
            .hLine9{
                text-align:initial;
                color: #008C8D ;
                padding-left: 5%; 
              display: flex;
              font-weight: 600;
              font-size: 1.1rem;
              position: relative; 
              z-index:1;
              padding-right: 5%;
            }
            
            .h4Line9{
                text-align:left;
                padding-top: 2%;
                padding-left: 2%;
                display: flex;
                padding-right: 2%;
                font-size: 1.5rem;
            }
            .h4Offerslid{
                text-align:left;
               
                padding-left: 4%;
                display: flex;
                padding-right: 4%;  
            }
            .rowimge::after{
             content: "";
             display: table;
             clear: both; 
            
            
            }
            .columnimge1{
              float: left;
                width: 33.3%;
                margin-left: 15%;
                padding: 1px;
              
               
            }
            .columnimge2{
                float: left;
                  width: 33.3%;
                 
                  padding: 1px;
                
                 
              }
            .rowimge.columnimge .img{
                width: 40%;
                height:40%
              
            }
            .searchs9{
               
               width: 10%;
             
                border-radius: 10%;
               
            }
            .myswiper9{
                width: 100%;
                height: 100%;
            }
            .myswiperDevices{
                width: 100%;
                height: 100%;
            }
            .myswiperArtical{
                width: 100%;
                height: 100%;
                /* margin: 10px 0 !important; */
            }
            .myswiperArtical > .swiper-wrapper{
        
                margin: 10px 0 !important;
            }
            .myswiperAddCard{
                width: 200px;
                height: 150px;
                margin:auto;
            }
        
            .myswiper9 .swipler-slide img{
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover !important;
            }
            .myswiperDevices .swipler-slide img{
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover !important;
            }
            .myswiperArtical  img{
              display: block;
              height: 226px !important;
              width: 100%;
              object-fit: cover !important;
              margin: 1px 0 !important;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
            .myswiperAddCard .swipler-slide img{
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover !important;
            }
            .productswiper{
                width: 100%;
                height: 470px ;
             
            }
            
            
            .h1s10{
                color:rgb(61, 54, 54);
            padding-left: 15%;
            
            }
            .searchs10{
                width: 40%;
                height: 40%;
            
            }
            .h1s11{
                color:rgb(61, 54, 54);
              text-align: center;
             
              
             
            
            }
            
        .logof{
            /* width: 3rem;
            height: 3rem; */
            margin-bottom: 4px;
            margin-left: 0;
            margin-right: 0;
              
             
              
            }
            .rowf::after{
                content: "";
                display: table;
                clear: both; 
               
               }
               .column1f{
                 float: left;
                   width: 20%;
                   margin-left: 15%;
                   padding-right: 3px;
                 
                  
               }
               .Commentstyle{
                 margin: 10px;
                 width:-webkit-fill-available;
                 box-shadow: 0 0 3px #bcbcbc;
                   border-radius: 3px;
                   padding: 10px;
               }
             
               .column2f{
                   float: left;
                     width: 32%;
                    padding-left: 3px;
                     padding: 1px;
                   
                    
                 }
                 .column3f{
                    float: left;
                      width: 32%;
                     
                      
                    
                     
                  }
                  .boxf{
                    box-sizing: border-box;
              
                  
                    width: 70%;
                    height: 100%;
                    background-color:#008C8D  ;
                    color:white;
                    
                  }
                  .hLine3{
                    text-align: center;
                    font-weight: 600;
                    color:#F52A59 ;
                    font-size: 1.5rem;
                    
                  }
                  .p3{
                    text-align: center;
                   
                    color: rgb(61, 54, 54);
                   margin: 0 auto;
                    font-size: medium;
                
                  }
                  .rowimge3::after{
                    content: "";
                    display: table;
                    clear: both; 
                   
                   }
                  .column3imge1{
                    float: left;
                    width: 33.3%;
                     margin-left: 20%;
                  
                  }
                  .column3imge2{
                    float: left;
                    width: 33.3%;
                 
                    padding: 1px;
                  }
                  .searchs3{
                   
                    width: 100%;
                    height:100%;
                  
                  }
                  .searchs31{
                   
                    width: 87%;
                    height:77%;
                  
                  }
                
                  .imgopacity1{
                    width: 85%;
                    padding: 8px;
                    border-radius: 16px;
                    opacity: 0.5;
                    background-color: #bcbcbc;
                    margin: auto;
                  }
               
                  .imgopacity2{
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                    opacity: 0.7;
                    background-color: #bcbcbc;
                  }
               
                  .Person{
                    width:1.2rem;
                  }
                  .view{
                   /* width:1.8rem; */
                   width: 100%;
                   margin: auto;
                  }
                  .viewmedical{
                   width:1.8rem;
                   height:1.8rem;
                   
                   margin: auto;
                  }
                  .icontitle{
                   width:1.3rem;
                   height:1.3rem;
                  }
                  .icontContact{
                   width:3rem;
                   height:3rem;
                  }
                  .icontFAQ{
                   width:4rem;
                   height:5rem;
                  }
                  .iconBasketTab{
                   width:3rem;
                   height:3rem;
                   font-size: 2rem !important;
                   margin-right: 3px  !important;
                  }
                  .iconBlog{
                   width:2.5rem;
                   height:2.5rem;
                   font-size: 2rem !important;
                  }
                  .iconBasketMain{
                   width:1.4rem;
                   height:1.4rem;
                   font-size: 2rem !important;
                  }
                  .icondollar{
                   width:1.8rem;
                   height:3.5rem;
                  }
                  .iconPhone{
                   width:1.3rem !important;
                   height: 1.3rem !important;
                  }
                  .iconBasket{
                   width:1.2rem !important;
                   height:1.2rem !important;
                   object-fit: contain !important;
                  }
                  .iconremoveBasket{
                   width:1.2rem !important;
                   height:1.2rem !important;
                   object-fit: contain !important;
                  }
               
                  .address1{
                    text-align: center;
                   font-weight: 600;
                   font-size: 1rem;
                   color: black;
                  
                  }
                  .address2{
                    text-align: center;
                    font-weight: 600;
                    font-size: 2rem;
                    color: black;
                   
                }
                  .address3{
                    text-align: center;
                    font-weight: 600;
                    font-size: 1rem;
                    color: black;
                   
                }
                .discoverimgetext{
                    text-align: center;
                    font-size: 2rem;
                    line-height: 2.2;
                }
               
               .slideimge{
                   width: 100%;
                 height: 100%;
               object-fit: cover;
                 margin-left: auto;
                 margin-right: auto; 
                 display: block;
                
            
               }
               .swiplerSlide{
                  
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
              
            .rowbtnslider{
               padding-top: -200%;
            }
            .btnImg{
                width: 100%;
                top:22%;
                font-size: 1em !important;
                color: #F52A59;
                padding: 5px;
                background-Color:white; 
               
                border-radius:50% ;
            
            }
            .swiper{
                height: 0px !important;
            }
            .btnImg1{
                width:20%;
                font-size: 0.6em !important;
                color: #F52A59;
                padding: 5px;
                background-Color:white; 
                box-shadow: 0 0 3px #bcbcbc;
                border-radius:50% ;
              /* padding-top: 15%; */
            }
            .disableArrow{
                width:20%;
                font-size: 0.6em !important;
                color: grey;
                padding: 5px;
                background-Color:white; 
                box-shadow: 0 0 3px #bcbcbc;
                border-radius:50% ;
              padding-top: 15%;
            }
            .Arrowleft{
                width:20%;
                font-size: 0.6em !important;
                color: #F52A59;
                padding: 5px;
                background-Color:white; 
               
                border-radius:50% ;
              padding-top: 15%;
            }
            .ArrowScroll{
                width:20%;
                font-size: 0.6em !important;
                color: #F52A59;
                padding: 5px;
            
               
                border-radius:50% ;
              padding-top: 15%;
            }
            .btnImg2{
              width: 20%;
              font-size:0.6em!important;
              color: #F52A59;
              padding: 5px;
              background-Color:white; 
              box-shadow: 0 0 3px #bcbcbc;
              border-radius:50% ;
            }
            .ArrowServices{
              width: 20%;
              font-size:1.8em!important;
              color: #F52A59;
              padding: 5px;
              background-Color:white; 
              box-shadow: 0 0 3px #bcbcbc;
              border-radius:50% ;
            }
            .Arrowright{
                width: 20%;
            
              padding-top:15%;
              font-size:0.6em!important;
              color: #F52A59;
              padding: 5px;
              background-Color:white; 
             
              border-radius:50% ;
            }
            
            .sliders{
              /* margin-top:3%; */
                 width:100%;
                max-width: 1500px;
                 display: flex;
                 margin: auto;
                
            }
            .slidersOrder{
              /* margin-top:3%; */
                 width:100%;
                max-width: 500px;
                 display: flex;
                 margin: auto;
                
            }
            
            
            .containerSe{
             width: 100%;
             position: relative;
            z-index:1;
            
            
             
            }
            .section{
                width: 1200px;
                max-width: calc(100%-20px);
                margin:0 auto;
                padding: 0 10px;
            }
            
            .searchbox{
             width: 100%;
            height:50%;
             background-color:white;
             display: flex;
             align-items: center;
             border-radius: 60px;
            
            
             box-shadow: 0 0 3px #bcbcbc;
            }
            .searchboxhome{
             width: 100%;
            height:50%;
             background-color:white;
             display: flex;
             align-items: center;
             border-radius: 60px;
            
            
             box-shadow: 0 0 3px grey ;
            }
            .search{
            background-color:white;
            
            flex: 1;
            border: 0;
            width: 30%;
            outline: none;
            padding: 0 20px;
            font-size: 1rem;
            color: grey;
            border-radius: 60px;
            }
            .searchhome{
            background-color:white;
            
            flex: 1;
            border: 0;
            width: 100%;
            outline: none;
            padding: 0 20px;
            font-size: 20px;
            color: grey;
            border-radius: 60px;
            }
            .searchtext{
                background-color:white;
                
                flex: 1;
                border: 0;
                width: 100%;
                outline: none;
                text-align: initial;
                font-size: 1.2rem;
                color: grey;
                border-radius: 60px;
                }
            .imgsearch{
            width: 2.5rem;
            
            
            }
            .imgsearchhome{
            width: 55px;
            
            
            }
            .btnsearchhome{
                border: 0;
                border-radius: 50%;
                width: 60px;
                display: flex;
                background-color: white;
            }
            .btnsearch{
                border: 0;
                border-radius: 50%;
                width: 2.5rem;
                background-color: white;
            }
            .search::placeholder{
                color: grey;
                text-align: right;
            }
            .headers2{
                font-size: 1.5rem;
                font-weight: 600;
                margin: 0 auto;
                background-color: #F52A59;
                background-image: repeating-linear-gradient(90deg, #eee 3%, #F52A59 3%,  #F52A59 27% );
            /* background-image:-webkit-repeating-linear-gradient(90deg, #eee, #F52A59 3%,  #F52A59 1%) ; */
                -webkit-background-clip: text;
                -webkit-text-fill-color:transparent
            }
            .header4s2{
               font-weight: 600;
                font-size: 1.5rem;
                margin: 0 auto;
                color: #008C8D ;
            }
            
            .pars2{
            
               
                font-size:medium;
                margin: 0 auto;
            }
            .parsdialog{
            
               
                font-size:medium;
                margin: 0 ;
            }
            .imgs2{
                
              width:100%;
                max-width:100%;
                height: auto;
            }
            .loginimg{
                width:100%;
                max-width:100%;
                height: 100%;   
            }
            .platform{
                margin-top: 10%;
                margin-left:  2%;
               margin-right:2% ;
                width: 100%;
               
            }
            .imgsA{
            
               /* margin-left: 79%; */
                width:1.5rem;
                margin: auto;
             
            
            }
            .imgsI{
                width:1.5rem;
                margin: auto;
               
            }
            .imgsG{
                margin: auto;
                width:1.5rem;
            
            }
            .H4A{
                font-size: 0.9rem;
                text-align: initial;
                margin-left: 0px;
                margin-right: 6px;
                }
                [dir="ltr"] .H4A{
                
                margin-left: 6px;
                margin-right: 0px;
                }
            .doctorName{
                font-size: 0.9rem ;
                /* text-align: center; */
                text-align: initial;
                margin: 0;
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: break-spaces;
                width: 100px;
            }
            .tagWord{
                font-size: 0.9em ;
                text-align: center;
                margin: 0;
                font-weight: 600;
            }
            .textaboutdoctor{
               
                text-align: center;
                margin: 0;
                font-weight: 600;
                line-height: 2em;
            }
            .twoimg{
                align-items: center;
                justify-content: center;
                text-align: center;
                display: flex;
               /*   grid-template-columns:72% 8%; */
                gap:0rem;
                margin: auto;
                padding:20px 5px 0px 5px ;
                /*padding-left: 5%;
                padding-right: 5%; */
               
               ;
            }
            .Calendarcard{
                align-items: center;
                
                 display: grid;
                 grid-template-columns:100%;
                 gap:2rem;
                 margin: auto;
               
               
               ;
            }
            .subCalendarcard{
                align-items: center;
                
                 display: grid;
                 grid-template-columns: repeat(2,1fr);
                 gap:2rem;
                 margin: auto;
               
               
               ;
            }
            .cardmedical{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns:60% 40%;
               gap: 1rem;
                 margin: auto;
                padding: 4% 1% 10% 1%;
                width: 100%; 
            }
            .cardAddress{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns: 100%;
               gap: 1rem;
                 margin: auto;
                padding: 4% 1% 10% 1%;
                width: 100%; 
            }
            .cardDiet{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns: 100%;
                 gap: 0rem;
             /*     gap: 1rem; */
                 margin: auto;
                padding:0%;
                height: auto;
                width: 100%; 
            }
            .heightdietdetails{
                height: auto !important;
            }
            .cardBooking{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns: 100%;
               gap: 1rem;
                 margin: auto;
              /*   padding: 10%; */
                width: 100%;   
            }
            .comment{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: flex;
                 grid-template-columns: repeat(2,1fr);
                 gap: 11rem; 
                 margin: auto;
                padding: 0;
               
               
            }
            .loveimg{
                width: 1rem;
            }
            .commentimg{
                width: 1.5rem;  
            }
            .Detailprofile{
                align-items: center;
                justify-content: center;
                text-align: initial;
                display: flex;
                 width: max-content;
                 gap: 0.5rem;
            }
            .profileandtextcard{
                align-items: center;
                justify-content: center;
              
                 display: grid;
                 grid-template-columns: 100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                
            }
            .profileandtextcardmedical{
                align-items: center;
                justify-content: center;
              gap:1rem;
                 display: grid;
                 grid-template-columns:100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                
            }
            .part1{
                 align-items: center;
                 justify-content: center;
                 display: grid;
                 grid-template-columns: 100%;
                 width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                 gap:1rem;
                 justify-items: center;
           
    
                
            }
            .profileandtextcardfood{
                align-items: center;
                justify-content: center;
              
                 display: grid;
                 grid-template-columns: 100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                 gap: 1rem;
                
            }
            .BasketTextandButton{
                 padding: 20px 10px; 
                align-items: center;
                justify-content: center;
              
                 display: grid;
                 grid-template-columns: 100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                 grid-template-columns: 50% 50%;
                
            }
            .Orderprice{
                 padding: 0px 10px; 
                align-items: center;
                justify-content: center;
              
                 display: grid;
                 grid-template-columns: 100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                 grid-template-columns: 70% 30%;
                
            }
            .profileandtextcardDetails{
                align-items: center;
                justify-content: center;
              
                 display: grid;
                 grid-template-columns: 100%;
             width: max-content;
                 margin: 0;
                 text-align: center;
                 width: 100%;
                 gap:0.5rem;
            }
            
            .iconandtext{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: flex;
                 grid-template-columns: 45px 45px;
             width: max-content;
                 margin: 0;
                 gap: 0.3rem;
                 width: 100%;
                
            }
            .Detailcommentright{
                align-items: center;
                justify-content: initial;
                text-align: center;
                 display: flex;
               
              
            }
            .Commenttitle{
                padding-right: 3px !important;
                font-size: 0.9em  !important ;
                text-align: initial  !important;
                margin: 0  !important;
                font-weight: 600  !important;
            
                padding-left: 3px  !important;
            }
            .Detailcommentleft{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns: repeat(2,1fr);
                 width: max-content;
                 margin: 0;
                 gap: 1rem;
            }
            .threeimg{
                align-items: center;
                justify-content: center;
                text-align: center;
                 display: grid;
                 grid-template-columns: 100%;
                 gap: 1rem;
                 margin: auto;
                 justify-items: center;
                 grid-template-columns: 27% 43% 21%;
              
               
             }
             [dir="ltr"] .threeimg{
             
                 grid-template-columns: repeat(3,1fr);
              
               
             }
            .threeimg1{
                align-items: center;
               justify-content: center;
                display: grid;
                grid-template-columns: 10% 80%;
                gap:1rem;
              /* display: flex; */
                width:100% 
             
            }
            .threeimg2{   align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: 33% 77%;
                gap: 0;
                margin: auto;
            }
            [dir="ltr"] .threeimg2{   align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: repeat(2,1.5fr);
                gap: 0;
                margin: auto;
            }
            .threeimg3{
                align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: 59% 41%;
                gap: 0;  margin: auto;
            }
            .profile{
                align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: repeat(1,1fr);
                gap: 0;  margin: auto;
            }
            
            /* .profileMedical{
                align-items: center;
                justify-content: center;
                display: grid;
                grid-template-columns: repeat(1,1fr);
                gap: 0;  
            } */
            
            .allanimation{
                width: 1000px;
                margin: 10% auto;
                align-items: center;
                justify-content: center;
                text-align: center;
             
            }
            
            .animation{
               
                width:100%;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin:  auto;
            
               
            }
            .animation1{
               
                width:8rem;
                height:8rem;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin:  auto;
            
               
            }
            .animation2{
               
            /*     height: 100px; */
                width:8rem;
                height:8rem;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin:  auto;
            
               
            }
            .animationheader{
                color: #008C8D ;
                font-size: 2rem;
                text-align: center;
            
            }
            .container2{
                max-width: 1000px;
                align-content: center;
                justify-content:center; 
            }
            .allcard{
                max-width: 100%;
               align-content: center;
               justify-content:center; 
               position: relative;
               z-index: 1;
             /*   margin:10% auto; */
            }
            /* .allcardJson{
                max-width: 100%;
                align-content: center;
                justify-content:center; 
                padding:90px 0px; 
            } */
            .allcarddiscover{
                max-width: 100%;
                align-content: center;
                justify-content:center; 
               /*  padding:20px 20px; */
               align-items: center;
               justify-items: center;
               display: grid;
            }
            .cards{
             
                display: grid;
                display: -ms-grid;
                gap:2rem;
                grid-auto-columns: 100%;
                align-items: center;
                justify-items: center;
                justify-content: center;
             
            }
            .cardsanimation{
                max-width: 1000px;
                text-align: center;
                align-items: center;
                justify-content: center;
                display: grid;
                gap:1rem;
                margin:0 auto;  
            }
            .cardssection3{
                 text-align: center;
                 display: grid;
                 display: -ms-grid;
                 gap:1rem;
                 align-items: center;
                 justify-content: center;
                 justify-items: center;
                 padding-top: 10px;
                 grid-template-columns: repeat(2,1fr);
               
            }
                .personandstar{
                     text-align: center;
                    display: grid;
                   gap:0.5rem;
                align-items: center;
             justify-content: initial;}
                .StarandDistance{
                     text-align: center;
                    display: grid;
                   
                  align-items: center;
                  justify-content: center;
                justify-items: initial;
                grid-template-columns: 100%;
            gap: 0.5rem;
        }
            
                    .stars{
                        text-align: center;
                        display: grid;
                        gap:0rem;  
                        grid-template-columns: repeat(1,0fr);
                    }
                .cardsDiscover{
                    text-align: center;
                 display: grid;
                padding: 20px 0px;
                 grid-template-columns: repeat(1,1fr);
                 align-content: center;
               
                 justify-content: center;
                 justify-items: center;
                 gap: 0rem;
            
                }
                .personandview{
                    text-align: center;
                    display: flex;
                    align-items: center;
                    /* grid-template-columns: repeat(2,1fr); */
                    grid-template-columns:auto auto  ;
                    gap:1rem;
                 justify-content: center;
                 width: 60%;
                 margin: auto;
                 
                }
            .cardssection5{
               text-align: center;
            
                display: grid;
            
            }
            .cardssection6{
                text-align: center;
                grid-template-columns:repeat(4,1fr);
                gap: 0rem;
                display: grid;
                align-items: center;
                justify-content: center;
                justify-items: center;
              
                
                }
                .cardsJsonMedical{ 
                    text-align: center;
                    grid-template-columns: repeat(1,1fr);
                    display: grid;
                    align-items: flex-start;
                }
                    .ColorText{
                        color:#F52A59
                    }
                .cardssection7{
                        text-align: center;
                    
                      /* padding-top:40px;
                      padding-bottom: 40px; */
                      padding:  10px;
                        display: grid;
                        display: -ms-grid;
                        
                        gap: 2rem;
                        grid-template-columns: repeat(2,1fr);
                        align-items: flex-start;
                      
                    }
                    .mybookingsGrid{
                        text-align: center;
                    
                        padding-top:40px;
                   
                          display: grid;
                          display: -ms-grid;
                          padding-bottom: 40px;
                          gap: 1rem;
                          grid-template-columns: 100%;
                    }
                .Offer{
                        text-align: center;
                    
                      padding-top:20px;
                 
                        display: grid;
                        padding-bottom: 20px;
                        gap: 2rem;
                        grid-template-columns: 100%;
                   
                      
                    }
                .ContactDiv{
                        text-align: center;
                    
                      padding-top:0px;
                 
                        display: grid;
                        padding-bottom: 40px;
                        gap: 2rem;
                   
                      
                    }
                .boxcontact{
                      text-align: center;
                      padding-top:40px;
                      padding-right: 20px;
                      padding-left: 20px;
                      display: grid;
                      padding-bottom: 40px;
                      gap: 1rem;
                    }
                 .AddressandChangebtndiv{
                
                        text-align: center;
                        padding-top:0px;
                        display: grid;
                        grid-template-columns: 100%;
                        padding-bottom: 0px;
                        gap: 1rem;
                   
                      
                    }
                    .FlexTypeTransport{
                        display: flex;
                        gap: 1rem;
                    }
                .paymentandother{
                
                
                        text-align: center;
                    
                      padding-top:0px;
                 
                        display: grid;
                        padding-bottom: 0px;
                        gap: 1rem;
                   grid-template-columns: 100%;
                      
                    }
                .Adressandselectdiv{
                
                
                        text-align: center;
                    
                      padding-top:0px;
                 
                        display: grid;
                        padding-bottom: 40px;
                        gap: 1rem;
                   grid-template-columns: 100%;
                      
                    }
                .orderprice{
                
                
                        text-align: center;
                    
                     
                 
                        display: flex;
                        padding: 15px 10px;
                        gap: 1rem;
                        justify-content: space-between;
                   /* grid-template-columns: repeat(2,1fr); */
                      
                    }
                .typetransport{
                
                
                        text-align: center;
                        align-items: center;
                      padding:15px 3px;
                 
                        display: flex;
                   
                        gap: 1rem;
                   /* grid-template-columns: repeat(1,1fr); */
                       justify-items:center;
                       justify-content: space-between;
                      
                    }
                .OrAndBorder{
                        text-align: center;
                    
                  
                 
                        display: flex;
               
                        gap: 0rem;
                   
                      
                    }
                .OrderMedicineDiv{
                   
                    
                      padding-top:40px;
                 
                        display: grid;
                 /*        padding-bottom: 40px; */
                        gap: 1rem;
                        grid-template-columns: 100%;
                   
                      
                    }
                .towOrderMedicineDiv{
                   
                    
                    /* padding-top:40px; */
                 
                        display: grid;
                    /*     padding-bottom: 40px; */
                        gap: 1rem;
                        grid-template-columns: 100%;
                   
                      
                    }
                    .padding20{
                        padding-top:20px;
                    }
                .towSelectAddressDiv{
                   
                    
                     /*  padding-top:40px; */
                 
                        display: grid;
                    /*     padding-bottom: 40px; */
                        gap: 5rem;
                        grid-template-columns: 100%;
                   
                      
                    }
                .imgandName{
                        text-align: center;
                    
                      padding-top:0px;
                 
                        display: grid;
                        padding-bottom: 10px;
                        gap: 0rem;
                        align-items: center;
                   grid-template-columns: 35% 64%;
                      
                    }
                .ComfirmationTop{
                        text-align: center;
                    
                   /*    padding-top:40px; */
                 padding: 20px 20px;
                        display: grid;
                      /*   padding-bottom: 40px; */
                        gap: 0.5rem;
                        align-items: center;
                   grid-template-columns: 100%;
                   justify-items: center;
                      
                    }
                .QuantityandOrderandPriceDiv{
                        text-align: center;
                     /*  padding-top:40px; */
                        padding: 20px 0px;
                        display: grid;
                     /*    padding-bottom: 40px; */
                        gap: 0rem;
                        align-items: center;
                        grid-template-columns: repeat(3,1fr);
                        justify-items: center;
                      
                    }
                .QuantityandOrderandPriceDivDialog{
                    text-align: center;
                    padding-top:20px; 
                    /* padding:20px; */
                    display: grid;
                    padding-bottom: 20px; 
                    gap: 0.5rem;
                    align-items: center;
                    grid-template-columns: repeat(3,1fr);
                    justify-items: initial;
                      
                    }
                .imgcarddialog{
                    text-align: center;
                    padding-top:20px; 
                    display: grid;
                    padding-bottom: 20px; 
                    gap: 0rem;
                    align-items: center;
                    grid-template-columns: 100%;
                    justify-items: center;
                    margin: auto;
                      
                    }
                    .paddingPd{
                        padding: 40px 0;
                    }
                .ThreeDivproduct{
                        text-align: center;
                    
                        padding-top:10px;
                        grid-template-columns: 15% auto auto;
                        display: grid;
                        padding-bottom: 10px;
                        gap: 0.5rem;
                        align-items: center;
                        position: relative;
                        justify-items: start;
                   
                      
                    }
                .ThreeButtonProduct{
                    text-align: center;
                
                    padding-top: 15px;
                    padding-bottom: 15px;
                                    display: grid;
                    justify-content: center;
                    gap: 1rem;
                    align-items: center;
                    justify-items: start;
                    grid-template-columns:100%; 
                    width: 100%;
                    margin: auto;
                   
                      
                    }
                [dir="ltr"] .ThreeButtonProduct{
                      
                   justify-content: initial;
                    
                   
                      
                    }
                .TwoButtonProduct{
                        text-align: center;
                    
                        padding-top:10px;
                 
                        display: flex;
                  
                        gap: 0.5rem;
                        align-items: center;
                   width: 100%;
                   margin: auto;
                   flex-wrap: wrap;
                      
                    }
                .TwoButtonSoical{
                        text-align: center;
                    
                        padding-top:0px;
                        padding-bottom: 0px;
                        display: flex;
                        width: 100%;
                        gap: 0.5rem;
                        align-items: center;
                        margin: auto;
                       
                   
                      
                    }
                    .SocailTrade{
                        text-align: center;
                        display: flex;
                        width: 100%;
                        gap: 1rem;
                        padding: 10px 0px;
                        align-items: center;
                        margin: auto;
                    }
                .SoicalContact{
                        text-align: center;
                    
                    /*     padding-top:40px; */
                 
                        display: flex;
                 
                        gap: 0.5rem;
                        align-items: center;
                        margin: auto;
                   
                      
                    }
    
                .TimeandDistancesandPrice{
                        text-align: center;
                    
                 /*      padding-top:40px; */
                 
                        display: grid;
                        justify-items: center; 
                  /*       padding-bottom: 40px; */
                       gap: 2rem; 
                   
                      
                    }
                .DivCount{
                        text-align: center;
                    
                     grid-template-columns: repeat(3,1fr);
                 
                        display: grid;
                     height: 38px;   
                     
                 align-items: center;
                   
                      
                    }
                    .heightOfferbtn{
                        height: 44.41px;   
                    }
                .CardandBasket{
                        text-align: center;
                    
                        gap: 2rem;
                 width: 100%;
                 grid-template-columns: 100%;
                        display: grid;
                    justify-content: center;
                     
                   
                      
                    }
                .lineprogressandName{
                        text-align: center;
                    
                  gap: 1rem;
                 padding: 10px 0px;
                        display: grid;
                  
                    align-items: center;
                     
                   
                    }
                .lineprogressBook{
                        text-align: center;
                    
                  gap: 1rem;
                 
                        display: grid;
                  
                    align-items: center;
                     
                   
                    }
                .Twotextprice{
                        text-align: center;
                    
                      
                 
                        display: flex;
                     flex-direction: column;
                        gap: 0rem;
                        padding-bottom: 10px;
                   
                      
                    }
                .TwoPriceDialog{
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    gap: 1rem;
                    flex-direction: column;
                    }
                .TwotextpriceProduct{
                        text-align: center;
                        display: grid;
                        gap: 0rem;
                        justify-items: start;
                        align-items: center;
                        flex-direction: column;
                        /* grid-template-columns: 100% ; */
                      
                    }
                .PriceOffer{
                        text-align: center;
                        display: grid;
                        gap: 0rem;
                        justify-items: start;
                        grid-template-columns: 100% ;
                      
                    }
                .TwotextpriceProduct2{
                        text-align: center;
                        display: grid;
                      /*   gap: 1rem; */
                     
                        /* grid-template-columns: 50% 50% ; */
                        bottom: 100px;
                        left:3px;
                     
                         position: absolute;
                    }
                    [dir="ltr"] .TwotextpriceProduct2{
                        left:268px;
                    }
                .TabsCard{
                        text-align: center;
                    
                    
                 
                        display: grid;
                   
                        gap: 4rem;
                   
                      
                    }
                    .Squarereviews{
                        background-color:#faaf00;
                        border: none;
                        padding: 0px 20px;
                        margin: 0 0px;
                        color: white;
                        text-align: center;
                        font-size: 1rem;
                    }
                .phoneSquare{
                        text-align: center;
                        grid-template-columns: auto auto ;
               
                       padding: 20px 0;
                        display: flex;
                align-items: center;
                /*      justify-items: center; */
                        gap: 1rem;
                   
                      
                    }
                .phoneSquarediet{
                        text-align: center;
                        grid-template-columns: 3% 42% 55%;
                  
             
                 padding: 20px 0;
                        display: grid;
                     align-items: center;
                     justify-content: center;
                     gap: 1rem;
                /*      justify-items: center; */
              /*           gap: 1rem; */
                   
                      
                    }
                .phoneSquaredietInMobile{
                        text-align: center;
                        grid-template-columns: 100%;
                        padding: 10px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 0rem;
                        flex-direction: column;
           
                   
                      
                    }
                    .IconAndNumberInMobile{
                        display: flex;
                        gap: 0.5rem;
                        align-self: center;
                        align-items: center;
                    }
                .Twoelereviews{
                        text-align: center;
                        align-items: center;
                        padding: 20px 0;
                        display: flex;
                        /* justify-content: space-between; */
                        justify-content: center;
                        gap: 1rem;
                
                      
                    }
                .allCalendar{
                 text-align: center;  
                 padding-top:15px;
                
                 margin: auto;
                 display: grid;
                 padding-bottom: 15px;
                 font-size: 0.8rem;
                 gap: 0.5rem;
                   
                      
                    }
                    .RESIZE{
                        width: 90%;
                        margin: auto;
                    }
                .ThreeCalendar{
                        text-align: center;
                    
                 /*      padding-top:40px; */
                 
                        display: grid;
                       /*  padding-bottom: 40px; */
                        gap: 4rem;
                 
                      
                    }
                .textandsocial{
                        text-align: center;
                    
                 
                        display: grid;
                      
                        gap: 1rem;
                   
                      
                    }
                .textfooter{
                        text-align: initial;
                        display: grid;
                        gap: 0rem;
                        margin-bottom: 1rem;
                    }
                .cardsDietscard{
                        text-align: center;
                    
                      padding-top:20px;
                 
                        display: grid;
                        padding-bottom: 20px;
                        gap: 1rem;
                   grid-template-columns: repeat(4,1fr);
                      
                    }
                .cardsmedicianorder{
                        text-align: center;
                    
                      /* padding-top:20px; */
                 /* padding: 20px; */
                        display: grid;
                        /* padding-bottom: 20px; */
                        gap: 1rem;
                        grid-template-columns: repeat(2,1fr);
                   
                      
                    }
                .QuestionandPhotocard{
                        text-align: center;
                    
                      padding-top:20px;
                 
                        display: grid;
                        padding-bottom: 20px;
                        gap: 1rem;
                   
                      
                    }
                .cardsTime{
                        text-align: center;
                    
                      padding-top:20px;
                 
                        display: grid;
                        padding-bottom: 20px;
                        gap: 1rem;
                        grid-template-columns: repeat(3,1fr);
                      
                    }
            
                    .PreviewForm{
                        text-align: initial;
                    
                        /* padding-top:20px; */
                         justify-content: center;
                          display: grid;
                          padding: 22px;
                          /* padding-bottom: 20px; */
                          gap: 1rem;
                    }
                    .cardinfo{
                        text-align: initial;
                    align-items: center;
                        padding-top:8px;
                        grid-template-columns: 100%;
                          display: grid;
                          padding-bottom: 8px;
                          gap: 1rem;
                    }
                    .cardsRelatedProducts{
                        text-align: center;
                    
                        padding-top:20px;
                   
                          display: grid;
                          padding-bottom: 20px;
                          gap: 1rem;
                    }
                    .cardsOfferProducts{
                        text-align: center;
                    justify-content: center;
                        padding-top:20px;
                   gap: 3rem;
                          display: grid;
                          padding-bottom: 20px;
                    }
                    .productphoto{
                        text-align: center;
                    
                        padding-top:20px;
                        grid-template-columns: repeat(1,1fr);
                          display: grid;
                          padding-bottom: 20px;  
                    }
                    .productphotofour{
                     
                    
                    padding: 0px 11px;
                   
                          display: flex;
                          padding-bottom: 20px;  
                          grid-auto-columns:  49px 49px 49px 49px;
                          gap: 0.2rem;
                    }
                    .Viewproduct{
                   
                    
                        padding-top:20px;
                        grid-template-columns: 100%;
                        display: grid;
                        padding-bottom: 0px; 
                        gap: 1rem;
                        justify-content: center;
                        justify-items: center;
                    }
                    .cardLogin{
                       align-items: center;
                   /*  justify-content: center; */
                    justify-items: start;
                        padding-top:20px;
                          display: grid;
                          padding-bottom: 20px;
                          grid-template-columns: 100%; 
                          text-align: initial;
                    }
                    .cardTradeMarks{
                        text-align: center;
                    
                        padding-top:20px;
                   
                          display: grid;
                          padding-bottom: 20px;
                    }
                    .cardProducts{
                        text-align: center;
                    
                        padding-top:20px;
                   
                          display: grid;
                          padding-bottom: 20px;  
                    }
                    .cardssocail{
                        text-align: center;
                    
                     gap: 1rem;
                        padding-top:10px;
                        padding-bottom: 10px;
                   
                        display: inline-flex;
                    }
                    .cardssocailfooter{
                        text-align: center;
                    
                     gap: 0.5rem;
                      
                
                     grid-template-columns: repeat(10,1fr);
               
                     display: grid;
                    }
                    .detailpost{
                    width: 97%;
                       text-align: right;
                       line-height:35px ;
                       font-size: 0.9rem;
                       white-space:  break-spaces;
                       /* padding: 0 10px; */
                       word-break: break-word;
                    }
                  [dir="ltr"]  .detailpost{
                        
                           text-align: left;
                        }
                        .textblog{
                          text-overflow: ellipsis;
                               text-align: initial;
                               text-decoration: none;
                               color:black;
                               font-size: 0.8rem;
                               width: 300px;
                              word-break: break-word;
                            
                            
                            }
                            [dir="ltr"] .textblog{
                               
                                text-align: initial;
                                text-decoration: none;
                                color:black;
                                }
                                .TextBlogSection{
                                   width: 100%;
                                   text-decoration: none;
                                   /* padding:0px 10px */
                                }
                    .cardspost{
                      
                    
                        padding-top:20px;
                   
                          display: grid;
                    }
                    .cardsBlog{
                     align-items: center;
                     text-align: center;
                   padding-top:20px; 
                     gap:2rem;
                     display: grid;
                     /* justify-content: center;
                   justify-items: center; */
                   grid-template-columns: 100%;
                   width: 100%;
                      
                    }
                    .cardsdevices{
                     align-items: flex-start;
                     text-align: center;
                    /*  padding-top:20px; */
                     gap:2rem;
                     display: grid;
                     justify-content: center;
                   justify-items: center;
                      
                    }
                    .imgBlog{
                        /* width: 299px; */
                        width: 348px !important;
                        height: 255px !important;
                    }
                    .imgBlogSlider{
                        /* width: 299px; */
                        width: 349px !important;
                        height: 273px !important;
                        object-fit: cover !important;
                    }
                    .imgDevices{
                        width: 200px !important;
                        height: 200px !important;
                    }
                    .imgpost{
                        width: 99%;
                        height: 250px;
                    }
                    .card{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                    }
                    .cardBlog{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: start;
                        text-align: center;
                    }
                    
                .stylesSection7{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
               
                  
                }
                .stylesComfirmationpage{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:2px ;
                    background-color: white;
                    position: relative;
               
                  
                }
                .stylesTabsCard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width: 100%;
                 
               
                  
                }
                .stylesTabsBasket{
                   box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width: 100%;
                    /* padding: 0px 10px;  */
                 
               
                  
                }
                .stylesTabsFav{
                   box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width: 100%;
                    /* padding: 0px 10px;  */
                 
               
                  
                }
                .stylesTabsFavDiet{
                   box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width: 100%;
                    /* padding: 0px 10px;  */
                 
               
                  
                }
                .stylesResturantSoon{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc63;
                    border:0.5px solid#edb4c6 ;
                    border-radius:12px ;
                    background-color: white;
               
                  
                }
                .stylesResturantOpen{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc63;
                    border:0.5px solid#ccf0ef ;
                    border-radius:12px ;
                    background-color: white;
               
                  
                }
                .stylesDietscard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:80px;
                    height:80px;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: black;
                  
                }
                .stylesDietscard a{
                 
                    color: black;
                  
                }
                .stylestrasportwhite{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:50% ;
                    background-color: white;
                    width:58px;
                    height: 58px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: black;
                  
                }
                .stylestrasportwhite a{
                 
                    color: black;
                  
                }
                .stylesbtnmedicianorder{
                    box-sizing: border-box;
                    border:0px  ;
                    /* border:2px solid #008C8D  ; */
                   
                    border-radius:6px ;
                    background-color: #e8f0ff;
                    /* width:167px;
                    height: 167px; */
                    width: 150px;
                    height: 150px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                 
                  
                }
                .stylesDietscarddetails{
                    box-sizing: border-box;
                  
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:60%;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: black;
                  
                }
                .stylesDietscarddetails a{
                 
                    color: black;
                  
                  
                }
                .styleopencategory{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: #008C8D ;
                    width:100%;
                    height: 90px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                 
                    justify-content: center;
                  
                }
                .styleopencategory a{
                    
                    color:white;
                 
                  
                }
                .styletransport{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    /* border: 3px solid #008C8D ; */
                    border: 0px;
                    border-radius:50% ;
                    background-color: #008C8D ;
                    width:58px;
                    height:58px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                 
                    justify-content: center;
                  
                }
                .styletransport a{
                    
                    color:white;
                 
                  
                }
                .styleopencategorydetails{
                    box-sizing: border-box;
                    border:0.5px solid#ccf0ef ;
                    border-radius:12px ;
                    background-color: white;
                    box-shadow: 0 0 3px #bcbcbc;
                    width:100%;
                    margin: auto;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                 position: relative;
                    justify-content: center;
                  
                }
                .styleopencategorydetails a{
                    
                    color:#008C8D ;
                    font-weight: 600;
                 
                  
                }
                .stylesCardBooking{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    margin: auto;
                    width: 100%;
                    padding: 0px;
                }
                .stylesCardDetailsPatient{
                    box-sizing: border-box;
                    box-shadow: 2px 2px  5px 0px #bcbcbc;
                    border: none;
                    border-radius:4px ;
                    background-color: white;
                    margin: auto;
                    width: 100%;
                    padding:  0 0px;
                }
                .stylesCalendar{
                    box-sizing: border-box;
                    box-shadow:2px 2px  5px 0px #bcbcbc;
                    border: none;
                    border-radius:4px ;
                    background-color: white;
                    margin: auto;
                    width: 100%;
                    padding: 10px;
                 /*    max-width: 309px; */
                }
                .cardPreview{
                    padding-bottom: 40px;
                }
                .cardssection8{
                    text-align: center;
                 
                    max-width: 1000px;
                   
                    display: grid;
                    gap:1rem;
                  
                }
            .stylesSection8{
                margin: 10% auto;
             
                max-width: 1000px;
                box-sizing: border-box;
                box-shadow:0 0 3px #13b4b459 ;
                border: none;
                border-radius:12px ;
              
            }
            .cardssection10{
                text-align: center;
                margin-top:10% ;
                max-width: 1000px;
             
                display: grid;
                gap:1rem;
              
            }
            .stylesSection10{
                margin: 10% auto;
             
                max-width: 1000px;
                box-sizing: border-box;
                box-shadow:0 0 3px #13b4b459 ;
                border: none;
                border-radius:12px ;
              
            }
            .cardssection11{
                text-align: center;
                margin-top:10% ;
                max-width: 1000px;
                margin: 10% auto;
                display: grid;
                gap:1rem;
              
            }
            .stylesSection11{
                margin: 10% auto;
            
                max-width: 1000px;
                box-sizing: border-box;
                box-shadow:0 0 3px #13b4b459 ;
                border: none;
                border-radius:12px ;
              
            }
            .downloadsection{
            
                text-align: center;
            
                 
                 
                 
                    display: grid;
                    gap:0rem;
                }
            
            .downloadImg{
                /* width: 100%;     */
                width: 80%;
                max-width: 700px;
                height: auto;
            }
            .cardImage{
                width: 100%;    
                max-width: 700px;
                height: 220px !important;
                object-fit: contain;
            }
            .btnhakeemapp{
                text-align: center;
                margin-top:10% ;
                min-width: 40px;
                margin:  auto;
                display: grid;
                gap:1rem;
                text-align: center;
                padding: 6px 10px 6px 0px;
                
                 display: inline-flex;;
                 color: grey;
                 background-color:white;
                 box-shadow: 0 0 3px #bcbcbc;
                 border: none;
                 border-radius: 10px;
                 font-family: Verdana, Geneva, Tahoma, sans-serif;
            
                 justify-items: center;
                 align-items: center;
                 cursor: pointer;
             
                grid-template-columns: repeat(2,1fr);
                width: 165px;
             
            }
            [dir="ltr"].btnhakeemapp{
                
            
                padding: 6px 0px 6px 10px;
                
             
             
                    grid-template-columns: repeat(2,1fr);
                width: 165px;
             
            }
            .downloadImg1{
                width:2rem;
                height:2rem;
                justify-content: flex-start;
                margin-right: 0px;
            }
            
            .appStore{
            font-size: 1.3em;
            text-align: left;
            margin: 0;
           
        }
            .hakeemapp{
                font-size: 1.3rem;
                font-weight: 600;
                color: #41464b;
                margin-top: 2rem;
                margin-bottom: 0;
            }
            .downnloadapp{
                color: grey;
            }
            .footer{
                height: 320px;
                text-align:center ;
                padding-top:3% ; 
                padding-bottom:3% ;
                align-items: flex-start;
                padding-left: 20px;
                padding-right: 20px;
                justify-items: start;
                max-width: 1500px;
                display: grid;
                gap:1rem;
                color: white;
                margin: auto;
                height: auto;
                grid-template-columns: repeat(3,1fr);
                    /* grid-template-columns: 48% 48%; */
                }
                .footercolor{
                    background-color:#008C8D  ;
                    z-index: 0;
                    margin-bottom: -50px !important;
                }
                .ColorSquareFooter{
                    background-color:#F52A59;
                    padding:30px 0px;
                    height: 0px;
                }
                .SquareFooter{
              
            
                 align-items: center;
                 justify-content: center;
                 
                 text-align: center;
               max-width: 378px;
                    margin: 0% auto;
                  display: grid;
                gap:1rem; 
                  z-index: 1;
                  padding: 3px 0px; 
                  top: -32px;
                  margin-bottom:0;
                width:100%;
               
                  position: relative;
                  grid-template-columns:auto auto ;
             /*    border-radius: 10px; */
                }
                .cardpost{
                    align-items: center;
                  justify-content: center;
                  text-align: center;
                  max-width: 1000px;
                  margin: 10% auto;
                  gap:2rem;
               
                }
                .footer a{
                    color: white;
            text-decoration: none;
                }
              
                .imgtele{
                
                    width:0.9rem;
                    height:0.9rem;
                }
                .divtele{
                    width: 30px;
                    background-color: white;
                height: 30px;
                    border-radius: 50%;
                    padding:3px;
                    margin:auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .cardsquare{
                  align-items: center;
                          order:0;
                    max-width: 1200px;
                    margin: 0 auto;
                    display: grid;
                    gap:0.5rem;
                    text-align:center;
                    grid-template-columns: repeat(2,1fr);
                
                }
                .cardSupoort{
                  
                    max-width: 580px;
                   align-items: center;
                   justify-content: center;
                  order: 1;
                    text-align: center;
                    display: grid;
                    gap:0.5rem;  
                    grid-template-columns: auto auto;
                }
                .imgsmeg{
                    width: 1.1rem;
                    height: 1.1rem;
                 
                }
               .divmessg{
                margin:auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                background-color: white;
                height:30px;
                border-radius: 50%;
                padding: 4px;
    
                }
            .headersupport{
                color: white;
                text-align: right;
                font-size: 0.9rem;
                font-weight: 600;
            }
            [dir="ltr"] .headersupport{
                text-align: left;
            }
            .prasupport{
                color: white;
                font-size: 0.7rem;
                text-align: right;
                margin: 0;
               
            }
            [dir="ltr"] .prasupport{
                color: white;
           
                text-align: left;
               
            }
            .imgsupport{
            /*   
                width:35% ; */
             width:3rem !important;
             height: 3rem;
            }
            .cardSupoort .cards2{
                /* order:-1; */
                order:0;
            }
            
            .Download{ 
                width: 100%;
              
                grid-template-columns: repeat(1,1fr);
               
                    display: grid;
                 
                    align-items: center;
                    justify-content: center;
                             order:1;
                             gap:2rem;
                } 
            .DownloadHeader{ 
                width: 100%;
              
                grid-template-columns: repeat(1,1fr);
               
                    display: grid;
                 
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                             order:1;
                             gap:2rem;
                } 
                .Download h1{
                    font-size: 1.5em;
                    font-weight: 600;
                    color: #41464b;
                    
                } 
                .Download a{
                    text-decoration: none;
                }
             .aboutText{
                 font-size: 0.8rem;
             }
             .headerBlog{
                 color:#008C8D ;
                 font-size: 1.5rem;
                 font-weight: 600;
                 padding-left: 1%;
                 padding-right: 1%;
             }
             .Aboutsectiontext{
                 background-color: white;
             }
             
             .blogh4{
                  overflow: hidden;
                  text-align: initial;
                  font-size: 0.9rem;
                  color:black;
             }
             .devicesh4{
            
                  text-align: center;
                  font-size: 1rem;
                  color:black;
             }
             .producth4{
              
                text-align: initial;
                padding:0px 10px
             }
            
            
             .HakeemMedicaltext{
                /* padding-bottom:20px; */
            }
            .Preview{
                width:23%;
                border: 1 solid grey;
                padding: 0px;
                border-radius: 0px;
                color: grey;
                font-size: 1rem;
            }
            .labelPreview{
                padding: 10px;
                margin: 0;
               
            }
            .allcard3{
                margin: 0px auto 25px auto ;
                position: relative;
                width: 100%;
            }
            .h3sortby{
                font-size: 1.5rem;
                margin:0;
            }
            .Stack{
                justify-content: center;
                width: 100%;
            }
            .StackBook{
                justify-content: center;
                width: 100%;
            }
            form.searchbox input[type=text]{
                padding: 14px 20px;
                border: none;
                border-radius: inherit;
                width: 90%;
              }
              form.searchbox input[type=text]:focus{
                border:none;
                outline: none;
              }
              form.searchbox .searchBtn{
                padding: 15px 13px;
                background: #f52a59;
                border:none;
                color: #fff;
                cursor: pointer; 
                border-radius: 50%;
              }
            form.searchboxhome input[type=text]{
                padding: 0px 20px;
                border: none;
                border-radius: inherit;
                width: 90%;
              }
              form.searchboxhome input[type=text]:focus{
                border:none;
                outline: none;
              }
              form.searchboxhome .searchBtn{
                padding: 15px 13px;
                background: #f52a59;
                border:none;
                color: #fff;
                cursor: pointer; 
                border-radius: 50%;
              }
              .cardproduct{
                  position: relative;
                  width: 100%;
              }
              .Sponsorsize{
                  height: 350px !important;
              }
              .Offersize{
                  height: 250px !important;
              }
              .Articalsize{
                  height: 430px !important;
                  margin-bottom: 20px !important;
                  margin-top: 20px !important;
        
              }
              .ArticalsizeSlider{
                  height: 330px !important;
                  margin-bottom: 20px !important;
                  margin-top: 20px !important;
                  padding-bottom: 10px;
        
              }
              .HeaderDietHakeem{
                text-align: center;
                    padding-top: 20px;
                    color:#F52A59 ;
                    font-size: 1.5rem;
                    font-weight: 600;
                 
                   
            }
             .borderLeft{
                border-left:1px solid #bcbcbc !important;
                border-right: none !important;
            }
            /* [dir="ltr"] .borderLeft{
               border-right:1px solid #bcbcbc !important;
               border-left: none !important;
            } */
            .paddingExamination
            {
                padding: 20% !important;
            }
            .bgExamination{
                background-color: rgb(235, 235, 255);
                border-radius: 50%;
                height: 4rem;
                width: 4rem;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
            }
            .paddingservices{
                padding: 20px 0px;
            }
            .widthmobile{
                width: 50%;
                margin: auto;
            }  
            .track1{
                width: 100%;
                position: absolute;
                text-align: center;
                top: 80px;
                }
                .trackImg1{
                    width:50px;
                    height: 50px;
                }
                .track2{
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    top: 222px;
                }
                .track3{
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    top: 357px;
                }
                .track4{
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    top: 492px;
                }
               
                .textorder{
            
                    position: absolute;
                    width: 30%;
                    text-align: end;
                    top: 20%;
                
                }
                .textorder2{
            
                    position: absolute;
                    width: 100% ;
                    text-align: end;
                    top: 20%;
                
                }
               .countryDiv{
                   display: grid;
                   grid-template-columns: 100%;
                   width: 100%;
                   align-items: center;
                   padding: 155px 0px 0px 0px;
                   gap: 2rem;
                   position: relative;
               }
               .TextFieldAndBtn{
                   display: flex;
                   flex-direction: column;
                   gap: 1rem;
               }
               .logoCountryDilaog{
                   width: 11rem;
               }
               .postitionCu{
                border-radius: 50%;
                top: -47px;
                position: relative;
                width: 1rem;
                height: 1rem;
                z-index: -10;
                text-align: center;
                left: 0px;
            }
            [dir="ltr"] .postitionCu{
                left: 0px;
            }
            .selectCountry{
                width: 1rem;
                border-radius: 50%;
            }
            .fullHeight{
                height: 100%;
            }
            .PaddingPatientForm{
                padding: 10px;
            }
            .formUp{
                display: flex;
                width: 100%;
                gap: 1rem;
                flex-direction: column;
       
               }
               .formdown{
                display: flex;
                width: 100%;
                gap: 1rem;
                flex-direction: column;
       
               }
               .productSearch{
                   display: flex;
                   gap: 1rem;
                   flex-direction: column;
               }
       
            @media (min-width:100px) and  (max-width:280px){
                .compared{
                    padding-top:10px ;
                }
                .galleryMain{
                    width: 263px !important;
                }
                .imgoffer{
                    height: 109px !important;
                }
         
                .MenuMar{
                    margin-bottom: 113px;
                }
                .btnhakeemapp{
    
                    padding: 6px 10px 6px 0px;
                    width: 150px;
                    gap: 1rem;
    
                }
                [dir="ltr"] .btnhakeemapp{
                    padding: 6px 0px 6px 10px;
                }
                .appStore{
                font-size: 1rem;
                text-align: left;
                margin: 0;
                padding-right: 0px;
            }
                .imgsupport{
                    /*   
                        width:35% ; */
                     widows: 2.2rem !important;
                     height: 2.2rem;
                    }
                .heightmobilesubmenu{
                    height: 81vh;
                }
                .subtext{
                    font-size: 0.5rem !important;
                }
                .FollowMobile{
                    padding-left: 10px;
                    padding-right: 10px;
                 }
                .BgAllCat{
                    top:113px;
                }
                /* .ETpadd{
                  
                     right: 86px; *
           
                }
                [dir="ltr"] .ETpadd{
                    left: 86px;
                } */
                .ArticalsizeSlider{
                    height: 306px !important;
                    margin-bottom: 20px !important;
                    margin-top: 20px !important;
          
                }
                .sizeAvatar{
                    width: 110px !important;
                    height: 110px !important;
                }
                .sizeCard{
                    width: 95%;
                }
                .BgCategory{
                    top:58px;
                }
                .display{
    
                    display: none;
                }
                .loadingData{
                    grid-template-columns: repeat(1,1fr);
                }
                .loadingPro{
                    grid-template-columns: repeat(1,1fr);
                }
                .loadingDataCat{
                   gap: 2rem;
                }
    
                .ImgPro{
                    width: 204px;
                }
    
                .imgtrad{
                    width: 264px;
                }
                .Empty{
                    width: 50px;
                }
                .DivImagePro{
                    width: 100%;
                }
                .myswiper2{
                    width: 290px;
                    height: 224px;
                    
    
                }
                .SquareBaskte{
                    height: 304px;
                }
                .widthOffer{
                    padding-right:  8px !important;
                    padding-left:  8px !important;
                }
                [dir="ltr"] .widthOffer{
                    padding-left:  8px !important;
                    padding-right:  8px !important;
                }
                .ImgDownload{
                    position: absolute;
                    left: -158%;
                    bottom: -16%;
                    overflow: hidden;
                    z-index: 0;
                    width: 633px;
                
                }
                .HH{
                    padding-left: 4% !important;
                    padding-top: 4% !important;
                    padding-right: 4% !important;
                }
                .SeeMoreGradients{
                   width: 40%;
                }
                .iconsSize{
                    font-size: 1.2rem !important;
                }
                .textaboutdoctor2{
                    font-size: 0.9rem !important;
                }
                .swiplerslideimg{
                    height: 50px !important;
                    width: 50px !important;
                }
                /* .marginDetailsProducts{
             
                    padding-right: 15px !important ;
                }
                [dir="ltr"] .marginDetailsProducts{
                  padding-left: 15px  !important ;
                } */
                .comparedPrice{
                    padding: 10px 30px;
                    width: 100%;
                    font-size: 0.9rem;
                }
                .myswiper{
                   width: 263px;
                   height: 100%;
                   } 
                .HeaderContact{
                    gap: 0.4rem; 
                }
    
                form.searchbox input[type=text]{
                    padding: 5px 10px;
                 
                  }
                .imgsearch{
                    width: 2.2rem;
                }
                .btnsearch{
                    width:2.1rem;
                }
                .searchs7{
                    width: 2.1rem;
                    height: 2.1rem;
                }
                .logof{
                    width: 2.5rem;
                    height: 2.5rem;
                }
                .imgtele{
                
                    width:0.9rem;
                    height:0.9rem;
                }
                .imgsmeg{
                
                    width:1rem;
                    height:1rem;
                }
                
    
                .ShowthreeProduct{
                    grid-template-columns:repeat(1,1fr) !important;
                }
                .ShowOffer{
                    grid-template-columns:repeat(1,1fr) !important;
                }
                .basketnav{
                    width: 1.2rem !important;
                    height: 1.2rem !important;
                }
                .logo{
                    /* width: 3.8rem !important;
                    height: 3rem; */
                }
                .yellowsquare{
                    width: 27% !important;
                }
                .SelectDate{
                    position: absolute;
                    top: 14px;
                    font-size: 1.2rem;
                 
                }
                .TwotextpriceProduct2{
                    left:3px;
                }
                [dir="ltr"] .TwotextpriceProduct2{
                    left:179px;
                }
                .ButtonExplorMore{
        
                    background-color: #F52A59;
                    color: white;
                    border-radius: 4px;
                    position: absolute;
                    bottom: 44px;
                    width: 29%;
                    font-size: 0.6rem;
                    padding: 5px ;
                    text-align: center;
                    box-shadow: 0 0 3px grey;
                    z-index: 1;
                    
                
                
                }
                [dir="ltr"] .ButtonExplorMore{
    
                    left: 68%;
                }
                .iconBasketTab{
                    width:2.5rem;
                    height:2.5rem;
                    font-size: 2rem !important;
                   }
                .iconBlog{
                    width:2rem;
                    height:2rem;
                    font-size: 2rem !important;
                   }
                .Detailprofile{
                    gap: 0.3rem;
                }
                .cardsBlog{
                    justify-content: center;
                }
                .textblog{
                    width: 250px;
                }
        
                .icontitle{
                    width:1.3rem;
                    height:1.3rem;
                    /* width:1.4rem;
                    height:1.4rem; */
                   }
        
                .comment{
                    gap: 4rem;
                }
                .headers2{
                    font-size: 1.5rem;
                }
                .header4s2{
                    font-size: 1.3rem;
                }
                .hLine3{
                    font-size: 1.3rem;
                }
             .hLine7{
                font-size: 1.1rem;
             }
             .hakeemapp{
                font-size: 1.2rem; 
                margin-top: 2rem;
                margin-bottom: 0;
             }
             .downloadImg1{
                 width:2rem ;
                 height: 2rem;
                 margin: 0;
             }
             .nameProduct{
                font-size: 1.2rem; 
                font-weight: 600;
             }
                .Container{
                    max-width: 266px !important;
                }
                .paddingRatingDiv{
                    padding-bottom: 10px;
                }
                .cardsDietscard{
                gap: 0.5rem;
                }
                .categoryname{
                    font-size: 0.7rem;
                }
                .TitleandIcons{
                    grid-template-columns: 12% 88%;
                }
                .phoneSquare{
                    padding: 20px 10px;
                }
                .H4A{
                    font-size: 0.9rem;
                }
            .searchbox{
            width: 90%;
            margin: auto;
             }
            .searchboxhome{
            width: 90%;
            margin: auto;
             }
                .flexPrice{
                    flex-direction: column;
                     gap:0.5rem;
         
                 }
                .textaboutdoctor1{
                    font-size: 1.2rem;
               
                    color: black;
                }
                .cardsmedicianorder{
                    text-align: center;
                
                  padding-top:20px;
             
                    display: grid;
                    padding-bottom: 20px;
                    gap: 1rem;
                    grid-template-columns: repeat(1,1fr);
               
                  
                }
                .Effects{
                    font-size: 1rem;
                    text-align: initial;
                }
               .sizepricetext{
                   font-size: 0.8rem !important;
               }
               .pproducttexttop{
                font-size: 0.8rem !important;
               }
               .buttonAddProduct{
                   font-size: 0.9rem;
               }
               .Addcomfirmation{
                   font-size: 1rem;
               }
               .CategoryHeader{
                   font-size: 1rem !important;
               }
               .lineProduct{
                font-size: 1rem ;
               }
               .Open{
                font-size: 1rem ;
               }
               .textaboutresturant1{
                font-size: 1rem ;
               }
               .BlodText{
                font-size: 1.3rem ;
               }
               .textaboutdoctor3{
                   font-size: 0.8rem !important;
               }
               .icontext{
                font-size: 0.8rem !important;
               }
               .blogh4{
                font-size: 1rem ;
                width: 250px;
                 word-break: break-word;
               }
                .RelatedProducts{font-size: 1.5rem;}
                .TwotextpriceProduct{
                    text-align: center;
                    display: grid;
                    gap: 0rem;
                    justify-items: start;
                    /* grid-template-columns: 100%; */
                  
                }
                .PriceOffer{
                    text-align: center;
                    display: grid;
                    gap: 0rem;
                 
                    grid-template-columns: 100%;
                  
                }
                .SizeFont{
                    font-size: 0.8rem !important;
                }
                .pproducttext{
                    font-size: 0.8rem !important;
                }
                .galleryContainer{
             
                    /* width: 269px; */
                    width: 100%;
                 
                }
                .TwoButtonSoical{
                    text-align: center;
                
                    padding-top:0px;
                    padding-bottom: 0px;
             
                    display: flex;
                    width: 100%;
                    gap: 0.5rem;
                    align-items: center;
                    margin: auto;
               
                  
                }
                .TwoButtonProduct{
                    text-align: center;
                
                    padding-top:20px;
             
                    display: flex;
              
                    gap: 0.5rem;
                    align-items: center;
               width: 100%;
               margin: auto;
                  
                }
                .deletebasket{
                    right:112px;
                    top: -181px;
                    position: absolute;
                }
                [dir="ltr"] .deletebasket{
                    left:112px;
                    top: -181px;
                }
                .boxcontact{
                    text-align: center;
                
                  padding-top:40px;
             padding-right: 0px;
             padding-left: 0px;
                    display: grid;
                    padding-bottom: 40px;
                    gap: 2rem;
               
                  
                }
                .imgBlog{
                    width: 245px !important;
                    height: 200px !important;
                }
                .imgBlogSlider{
                    width: 245px !important;
                    height: 245px !important;
                    object-fit: cover !important;
                }
                .Clockandtext{
                    display: grid;
                    grid-template-columns: 11% 37%;
                    /* grid-template-columns: 8% 40%; */
                    align-items: center;
                    justify-content: center;
                }
                .bloodtextfooter{
                    font-weight: 600;
                    font-size: 0.7rem;
                    text-align: initial;
                }
                .footer{
                    max-width: 276px;
                    padding-left: 8px;
                    padding-right: 8px;
                    gap: 0.5rem;
                   
        
                }
                .footer a{
                    color: white;
            text-decoration: none;
            font-size: 0.6rem;
    
                }
                .footer p{
                    margin-bottom:4px;
                }
                .textfooter{
                    margin-bottom: 0.5rem;
                }
                .ColorSquareFooter{
                    padding: 29px 0px 20px 0px ;
                }
                .cardSupoort{
                  
                    max-width: 580px;
                    align-items: center;
                    justify-content: center;
                    order: 1;
                    text-align: center;
                    display: grid;
                    gap:0.5rem;  
                    grid-template-columns: 76% 20%;
                    justify-items: end;
                }
                .SquareFooter{
              
            
                    align-items: center;
                    justify-content: center;
                    
                    text-align: center;
                  max-width: 273px;
                       margin: 0% auto;
                     display: grid;
                   gap:0.5rem; 
                     z-index: 1;
                     padding: 5px 0px; 
                     margin-bottom:0;
                     top: -30px;
                   width:100%;
                     position: relative;
                     grid-template-columns:auto auto ;
                /*    border-radius: 10px; */
                   }
                   .sizestars{
                       font-size: 1.5rem !important;
                   }
                .divtele{
                    width: 25px;
                    background-color: white;
                    height: 25px;
                    border-radius: 50%;
                    padding: 2px;
                    margin:auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .divmessg{
                    margin:auto;
                    /* display: block; */
                    width: 25px;
                    background-color: white;
                    height:25px;
                    border-radius: 50%;
                    padding: 2px 5px;
                    }
                .HeaderandBasketIconpagebasket{
                    display: grid;
                    /* grid-template-columns: 11% 39% ; */
                    grid-template-columns: 11% 39% ;
                    align-items: center;
                    /* justify-content: initial;
                    justify-items: center; */
                    padding: 10px 0px;
                    gap: 0rem;
                }
                .SectionContainer{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:20px 0px;
                    max-width: 264px;
                
                }
                .SectionContainerDeleteAccount{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    padding:20px 0px;
                    max-width: 264px;
                
                }
                .div1DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                
                
                }
                .div2DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                
                }
                .headersupport{
                    color: white;
                    text-align: right;
                    font-size: 0.6rem;
                    font-weight: 600;
                }
                [dir="ltr"] .headersupport{
                 
                    text-align: left;
                   
                }
                .prasupport{
                    color: white;
                    font-size: 0.5rem;
                    text-align: right;
                    margin: 0;
                   
                }
                [dir="ltr"] .prasupport{
                    color: white;
                
                    text-align: left;
                   
                }
                .backgroundSlogen{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 8px;
                    border-radius: 16px;
                    margin: auto;
                }
                .backgroundSlogenDialog{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 8px;
                    border-radius: 16px;
                    margin: auto;
                }
                .threeimg{
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                     display: grid;
                 
                     gap: 0rem;
                     margin: auto;
                     justify-items: center;
                     /* grid-template-columns: 28% 51% 21%; */
                     grid-template-columns: repeat(3,1fr);
                   
                 }
                 [dir="ltr"] .threeimg{
                    grid-template-columns: repeat(3,1fr);
                    padding-left: 10px;
                 }
                .cardssection7{
                    text-align: center;
                
                  /* padding-top:40px;
                  padding-bottom: 40px; */
                    display: grid;
                    padding:  10px;
                    gap:1rem;
                    grid-template-columns: repeat(1,1fr);
               
                  
                }
                .cardssection6{
                    text-align: center;
                   grid-template-columns:repeat(2,1fr); 
                      gap: 0.5rem;
                        display: grid;
                    
                        align-items: center;
                        justify-content: center;
                        justify-items: center;
                       
                    
                    
                    }
                .hLine9{
                    text-align:initial;
                color: #008C8D ;
                    padding-left: 8%; 
                  display: flex;
                  font-weight: 600;
                  font-size: 1.1rem;
                  position: relative; 
                  z-index:1;
                  padding-right: 8%;
                }
                .stylesTabsCard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                 
               
                  
                }
                .stylesTabsBasket{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                 
               
                  
                }
                .stylesTabsFav{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                 
               
                  
                }
                .stylesTabsFavDiet{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                 
               
                  
                }
                .markandheartdetails{
                    position: absolute;
                    right:83%;
                    /* right:77%; */
                    top:0px;
                }
                
                [dir="ltr"] .markandheartdetails{
                    position: absolute;
                    left: 83%;
                    /* left: 77%; */
                    top:0px;
                }
                .markandheartMedical{
                    position: absolute;
                    left:86%;
                    top:0px;
                    /* right: 0; */
                }
                
                [dir="ltr"] .markandheartMedical{
                    position: absolute;
                    left: 0%;
                    /* right: 0; */
                    top:0px;
                }
                .yellowsquare2{
                    width:17% !important;
                    /* right: 83%; */
                }
                [dir="ltr"] .yellowsquare2{
                 /* left: 83%; */
                }
                .markandheartBlog{
                    position: absolute;
                    right:78%;
                    top:0px;
                }
                
                [dir="ltr"] .markandheartBlog{
                    position: absolute;
                    left: 78%;
                    top:0px;
                }
                .markandheartproduct{
                    position: absolute;
                    right:79%;
                    top:0px;
                    width: 50px;
                }
                
                [dir="ltr"] .markandheartproduct{
                    position: absolute;
                    left: 79%;
                    top:0px;
                    width: 50px;
                }
                .imgopacity1{
                    width: 100%;
                }
                .address1{
             
                   font-size: 1rem;
                 
                  
                  }
            }
            @media (min-width:320px) and (max-width:389px){
                .BgCategory{
                    top:68px;
                }
                .galleryMain{
                    width: 297px !important;
                }
                .myswiper{
                    width: 297px !important;
                }
                .DivImagePro{
    
                width: 300px;
                }
                .imgoffer{
                    height: 123px !important;
                }
             .heightmobilesubmenu{
                 height: 80vh;
             }
                /* .ETpadd{
                   
                    right: 86px;
    
                }
                [dir="ltr"] .ETpadd{
                    left: 86px;
                } */
                .sizeCard{
                    width: 100%;
                  
                }
                .heightOff{
                    height: 19px !important;
                }
                .loadingData{
                    grid-template-columns: repeat(1,1fr);
                }
                .loadingPro{
                    grid-template-columns: repeat(1,1fr);
                }
                .loadingDataCat{
                   gap: 3rem;
                }
                .display{
                    display: none;
                }
                .ImgPro{
                    width: 240px;
                    border: 1px solid #bcbcbc;
                }
                .imgtrad{
                    width: 302px;
                    border: 1px solid #bcbcbc;
                }
                .SquareBaskte{
                    width: 115%;
                    height: 315px;
                
                }
                .widthDes{
                    width: 78%;
                    margin: auto;
                }
                .h1s7{
                    font-size: 0.7rem;
                }
                .ImgDownload{
                    position: absolute;
                    /* max-width: 1500px; */
                    left: -67%;
                    bottom: 47%;
                    overflow: hidden;
                    z-index: 0;
                    width: 100%;
                }
                .AddCart{
                    font-size: 0.9rem;
                }
                .widthOffer{
                    font-size: 0.8rem !important;
                    
                }
                .cardssection7{
                    gap: 1rem;
                }
                .ButtonExplorMore{
                    width: 41%;
                }
                [dir="ltr"] .ButtonExplorMore{
    
                    left: 57%;
                }
                .stylesDietscard{
                    width: 70px;
                    height: 75px;
                }
                .h1s6{
                    font-size: 0.7rem;
                }
                .subtext{
                    font-size: 0.6rem  !important;
                }
                .searchs6 {
                    width: 2.2rem;
                    height: 2.2rem;
    
                }
                .SeeMoreGradients{
                    width: 37%;
                }
                /* .marginDetailsProducts{
             
                    padding-right: 15px !important ;
                    padding-left: 0px  !important ;
                }
                [dir="ltr"] .marginDetailsProducts{
                  padding-left: 15px  !important ;
                  padding-right: 0px !important ;
                } */
                .galleryContainer{
                    width:297px;
                }
                .ShowthreeProduct{
                    grid-template-columns:repeat(1,1fr) !important;
                }
                .ShowOffer{
                    grid-template-columns:repeat(1,1fr) !important;
                }
                .markandheartproduct{
                    position: absolute;
                    
                    right:78%;
                    top:0px;
                    width: 50px;
                }
                
                [dir="ltr"] .markandheartproduct{
                    position: absolute;
                  
                    left: 78%;
                    top:0px;
                    width: 50px;
                }
            
                .threeimg1{
                    gap: 0.5rem;
                }
                .cardsmedicianorder{
                    text-align: center;
                
                  padding-top:20px;
             
                    display: grid;
                    padding-bottom: 20px;
                    gap: 1rem;
                    grid-template-columns: repeat(2,1fr);
                    justify-items: center;
               
                  
                }
                .stylesbtnmedicianorder{
                    /* width: 140px;
                    height: 140px; */
                    width: 90px;
                    height: 90px
                }
                .SelectDate{
                    position: absolute;
                    top:23px;
                   font-size: 1.2rem;
                }
                .imgBlog{
                    width: 295px !important;
                    height: 250px !important;
                }
    
                .TwotextpriceProduct2{
                    left:3px;
                }
                [dir="ltr"] .TwotextpriceProduct2{
                    left:211px;
                }
                .footer{
                    padding-right: 10px;
                    padding-left: 10px;
                    gap: 0.5rem;
                }
                .FollowMobile{
                   padding-left: 10px;
                   padding-right: 10px;
                }
                .iconBasketTab{
                    width:2.5rem;
                    height:2.5rem;
                    font-size: 2rem !important;
                   }
                .iconBlog{
                    width:2rem;
                    height:2rem;
                    font-size: 2rem !important;
                   }
                .widthQuantitybtn{
                    width: 100% !important;
                }
                .Opendetails{
                    padding: 5px 10px ;
                    border-radius: 7px;
                }
                .Closedetails{
                    padding: 5px 10px ;
                    border-radius: 7px; 
                }
                .deletebasket{
                    right: 259px;
                }
                [dir="ltr"] .deletebasket{
                    left:270px;
                }
                .ContainerMargin{
                    margin-top: 80px;
                }
                .PreviewForm{
                    padding: 0px;
                }
                .SeeMore{
                    padding:7px !important;
                }
                .cardsTime{
                    grid-template-columns: repeat(3,1fr);
                    gap: 0.9rem;
                }
                .Asbutton label{
                    z-index: 1;
                    cursor: pointer;
                    line-height: 1.8em;
                    font-size: 0.9rem;
                }
                .mealscheck label{
                    z-index: 1;
                    cursor: pointer;
    
                }
                .comment{
                    gap: 7rem;
                }
                .textblog{
                    width: 274px;
                }
                .TwoButtonProduct{
                 width: 100%;
                }
                
                .categoryname{
                    font-size: 0.7rem;
                }
             .paddingRatingDiv{
                 padding-bottom: 20px;
             }
                .SectionContainer{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:15px 6px;
                   
                    max-width: 345px;
                }
                .SectionContainerDeleteAccount{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    padding:15px 0px;
                   
                    max-width: 302px;
                }
                .div1DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                
                
                }
                .div2DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                
                }
                .paddingComfirmation{
                    padding: 20px 0px !important;
                }
                .Container{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    padding:0px 0px;
                    max-width: 300px !important;
                
                }
                .cardmedical{
                    width: 90%;
                }
                .headersupport{
                    font-size: 0.8rem;
                }
                .SquareFooter{
                    padding:6px;
                }
                .Squarereviews{
                    padding: 0px 20px;
                }
                .boxcontact{
                    padding: 10px 0px;
                }
                .prasupport{
                    font-size: 0.6rem;
                }
                .SquareFooter{
                    gap:1rem;
                    top:-31px;
                }
                .imgsupport{
                    /* width: 72% !important; */
                    width:2.5rem !important;
                    height: 100%;
                }
                .textfooter{
                    font-size: 0.7rem;
                }
                .bloodtextfooter{
                    font-size: 0.8rem;
                }
                .blogh4{
            
                    text-align: initial;
                    font-size: 1rem;
                    color:black;
               }
               .H4A{
                   font-size: 0.9rem;
                   margin-right: 9px;
               }
               [dir="ltr"] .H4A{
                margin-left: 9px;
               }
               .imgsA{
                   width: 1.2rem;
               }
               .imgsI{
                   width: 1.2rem;
               }
               .imgsG{
                   width: 1.2rem;
               }
               .divmessg{
                   width: 30px;
                   height: 30px;
                   padding: 4px;
               }
               .divtele{
                   width: 30px;
                   height: 30px;
                   padding: 4px;
               }
              .paragraphandIcons{
                display: grid;
                grid-template-columns: 9% 90%;
                align-items: flex-start;
             
            }
              .paragraphandIconsSpecialty{
                display: grid;
                grid-template-columns: 9% 90%;
                align-items: flex-start;
             
            }
            .logo{
            /* width: 4rem !important;
            height: 3rem; */
            /* flex: 0 0 49%; */
        }
            .address1{font-size: 0.9rem;}
               .markandheartdetails{
                   right:86%;
                   /* right:81%; */
               }
               [dir="ltr"] .markandheartdetails{
                   left:86%;
                   /* left:81%; */
               }
                
            }
            @media (min-width:320px){
                .track1{
                    top:14.5%
                }
                .trackImg1{
                    width:50px;
                    height: 50px;
                }
                .track2{
                    top:40.6% 
                }
                .track3{
                    top:65% 
                }
                .track4{
                    top:89.4% 
                }   
            .textorder{
        
                position: absolute;
                width: 35%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 94% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:360px){
                .track1{
                    top:14.6%
                }
                .trackImg1{
                    width:50px;
                    height: 50px;
                }
                .track2{
                    top:40.6% 
                }
                .track3{
                    top:65% 
                }    
                .track4{
                    top:89.4% 
                }    
            .textorder{
        
                position: absolute;
                width: 35%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 89% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:360px) and (min-height:740px){
                .track1{
                    top:14.5%
                }
                .trackImg1{
                    width:60px;
                    height: 60px;
                }
                .track2{
                    top:40.5% 
                }
                .track3{
                    top:65% 
                }
                .track4{
                    top:89.4% 
                } 
            .textorder{
        
                position: absolute;
                width: 33%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 90% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:390px){
                .track1{
                    top:15%
                }
                .trackImg1{
                    width:60px;
                    height: 60px;
                }
                .track2{
                    top:41% 
                }
                .track3{
                    top:65.6% 
                }  
                .track4{
                    top:90% 
                } 
            .textorder{
        
                position: absolute;
                width: 33%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 89% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:400px){
         .CategoryImage{
            /* width: 40%;
            height: auto; */
            width: 100%;
            height: 160px;
            text-align: center;
            margin: 0 auto;
        }
                .loadingData{
                    grid-template-columns: repeat(2,1fr);
                }
                /* .loadingPro{
                    grid-template-columns: repeat(2,1fr);
                } */
          
                .SquareBaskte{
                    width: 109%;
                    height: 330px;
                }
                .widthDes{
                    width: 66%;
                }
                .track1{
                    top:15%
                }
                .trackImg1{
                    width:60px;
                    height: 60px;
                }
                .track2{
                    top:41% 
                }
                .track3{
                    top:65.6% 
                } 
                .track4{
                    top:90.4% 
                } 
            .textorder{
        
                position: absolute;
                width: 33%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 88% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:414px) and (min-height:736px){
            .heightmobilesubmenu{
             height: 82vh;
             }
            }
            @media (min-width:540px){
                .swiplerslideimg{
                    height: 60px !important;
                    width: 60px !important;  
                }
                .myswiper{
                    box-sizing:border-box;
                    height: 280px;
                    width: 100px ;
                }
                .galleryMain{
                    width:480px !important;
                    height: 300px;
                    box-shadow: 0 50px 75px 50px rgba(#1e1e1e,0.18);
                    border:1px solid #e9e9e9e9
                }
                .galleryContainer{
                 flex-direction: row;
                }
                .heightmobilesubmenu{
                    height: 80vh;
                }
                .loadingDataCat{
                    grid-template-columns: repeat(4,1fr);
                }
                .loadingMenu{
                    grid-template-columns: repeat(1,1fr);
                }
                .SquareBaskte{
                    width: 93%;
                    height: 100%;
                }
                .widthDes{
                    width: 54%;
                }
                .ImgDownload{
                    position: absolute;
                    left: -76%;
                    bottom: 19%;
                    overflow: hidden;
                    z-index: 0;
                    width: 633px;
                
                }
                .cardssection7{
                    grid-template-columns: repeat(3,1fr);
                }
                .SeeMoreGradients{
                    width: 27%;
                }
                .SquareFooter{
                    max-width: 500px;
                    gap: 10rem;
                }
                .Container{
                    padding: 0px;
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    max-width: 430px !important;
                }
                .SectionContainer{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:15px 0px;
                    /* max-width: 357px; */
                    max-width: 500px;
                
                }
                .SectionContainerDeleteAccount{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    padding:15px 0px;
                    /* max-width: 357px; */
                    max-width: 500px;
                
                }
                .div1DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;
                
                
                }
                .div2DeleteAccount{
                    width: 100%;
                    max-width: 100%;
                
                }
                
                .track1{
                    top:14.5%
                }
                .track2{
                    top:40.5% 
                }
                .track3{
                    top:64.6% 
                } 
                .track4{
                    top:89.4% 
                }     
            .textorder{
        
                position: absolute;
                width: 39%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 81% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media (min-width:768px) and (max-width:991px){
                /* .flexOtherPrice{
                    display: flex;
                    flex-direction: row;
                    } */
                .padding_form{
                    padding-bottom:0px ;
                
                }
                .Badge_POs{
                    right: 96%;
                    left: 0;
                    position: absolute;
                    top: 2%;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                [dir="ltr"] .Badge_POs{
                    left: 96%;
                    right: 0;
                }
                .wid_Btn_dia{
                    width: 30%;
                }
                .disAddcomfirmation{
                    box-sizing: border-box;
                    /* background-color: #008C8D ;
                    border:0px solid #008C8D ; */
                    width:100%;
                    text-align: center;
                    padding:5px 15px;
                    margin: auto;
                    /* border:0.5px solid #ccf0ef ; */
                    border-radius: 12px;
                    font-size: 1rem;
                    text-align: center;
                    color: black;
                    margin-bottom: 0;
                
                   
                    
                } 
                .galleryMain{
                    width:480px !important;
                    height: 300px;
                    box-shadow: 0 50px 75px 50px rgba(#1e1e1e,0.18);
                    border:1px solid #e9e9e9e9
                }
                .NameCat{
                    font-size: 0.8rem;
                }
                .footer p{
                    margin-bottom: 0.8rem;
                }
                .pproducttext ul{
                    padding-right: 0px;
                    padding-left: 0px;
                }
                [dir="ltr"] .pproducttext ul{
                    padding-left: 0px;
                    padding-right: 0px;
                }
                .GridDetails{
                    display: grid;
                    grid-template-columns: 80% 20%;
                    align-items:start ;
                }
                /* .TabOffer{
                    grid-template-columns: repeat(5,1fr);
                } */
                .imgtypetransport{
                    width: 2rem;
                    height: 2rem;
                }
                .imgcompanytransport{
                    width: 2.5rem;
                    height: 2.5rem;
                }
                .heightmen{
                    height: 100%;
                }
                .UlSubCatMenu{
                    width:74%;
                    left: 0%;
                 
                    min-height: 100%;
                    height: auto;
                }
                [dir="ltr"] .UlSubCatMenu{
                    width: 74%;
                    left: 26%;
                }
                .GridUlCatMenu{
    
                    grid-template-columns: repeat(2,1fr);
                    /* height: 100%; */
                }
                 .ETpadd{
                    top: -40px;
                    right: 25px;
    
                } 
                [dir="ltr"] .ETpadd{
                    left: 25px;
                }
                .ET{
                    /* width:130px; */
                    height: 31px;
                    margin: auto !important;
                    font-size: 0.8rem;
                }
    
                .btnsmall{
                    height: auto !important;
                    width: auto !important;
                }
                
                .marginSkeleton{
                    margin:16px 16px 0px 16px
                }
             .rectangle{
                    height: 200px !important;
                    width: 211px;
                }
                .hLine6{
                    font-size: 1.1rem;
                }
                .imgpost{
                    width: 99%;
                    height:294px;
                }
                .avater{
                    height: 100px;
                }
                .textblog{
                    font-size: 0.8rem;
                    width: 202px;
                }
                .myswiperArtical img{
     
                    height: 190px !important;
           
          
                  }
                .devicesh4{
                    font-size: 1rem;
                }
                .HeightCount{
                    height: 40px !important;
                }
                .sizeCard{
                    width: 100%;
                  
                }
                .allCalendar{
                  
                    font-size: 0.8rem;
                    margin: 0;
                }
                .RESIZE{
                    width: 70%;
                    margin: 0;
                }
                .positiontext{
                    /* top: -25px; */
                    position: relative;
                }
                .textPriceProduct{
                    font-size: 0.9em;
                }
                .heightOff{
                    height: 19px;
                }
                .BgCategory{
                    top:70px;
                    height: 60px;
                }
               .paddingCategorymenu{
                    padding: 0px 36px;
                }
                .loadingData{
                    grid-template-columns: repeat(3,1fr);
                }
                .loadingPro{
                    grid-template-columns: repeat(2,1fr);
                }
                .loadingDataCat{
                    grid-template-columns: repeat(6,1fr);
                    gap: 3rem;
                   
                }
                .loadingMenu{
                    grid-template-columns: repeat(7,1fr);
                }
                .MenuMar{
                    margin-bottom: 125px;
                }
                .ImgPro{
                    width: 308px;
                    border: 1px solid #bcbcbc;
                }
                .imgtrad{
                    width: 383px;
                    border: 1px solid #bcbcbc;
                }
                .Empty{
                    width: 72px;
                }
                .DivImagePro{
                    display: grid;
                    grid-template-columns: 16% 82%;
                    gap: 0.5rem;
                    width: 388px;
                }
                .SiderBorder3{
                    border-right: 1px solid;
                    border-left: 0px;
                    margin-right: 15px;
                    margin-left: 0px;
                }
                [dir="ltr"] .SiderBorder3{
                    border-left: 1px solid;
                    border-right: 0px;
                    margin-left: 15px;
                    margin-right: 0px;
                }
                .paddlevel2{
                    padding-right: 5px !important;
                    padding-left: 0px !important;
                }
                [dir="ltr"] .paddlevel2{
                    padding-left: 5px !important;
                    padding-right: 0px !important;
                }
                .CategoryHeader{
                    font-size: 1.1rem !important;
                }
                .BgGrey{
                    width: 260px;
                }
                .gridBrands{
                  
                    grid-template-columns: repeat(2,1fr);
                }
                .gapbasket{
                    gap: 0.5rem !important;
                }
                .NoItem{
                    width: 30%;
                    height: 30%;
                }
                .SquareBaskte{
                    width:66%;
                    height: 100%;
                
                }
                .widthDes{
                    width: 50%;
                    margin: auto;
                }
                .BgComp{
     
                    padding: 20px 40px;
                }
                .BorderRig{
                    border-left: 1px solid #bcbcbc;
                    padding-left: 5px;
                
                }
                .BorderLef{
                    border-right: 1px solid #bcbcbc !important;
                    padding-right: 5px !important;
                
                }
                .SubCategory{
                    display: flex;
                    /* grid-template-columns: 62% 35%;  when category 3column*/
                    grid-template-columns: 75% 22%; 
                    gap: 0.8rem;
                    flex-direction: row;
    
             
                }
                .SevenCategory{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    /* align-items: center;
                    justify-content: center;
                    justify-content: center;
                    justify-items: center; */
                    gap: 0.3rem;
    
             
                }
                .CategoryImage{
                    /* width: 60%;
                    height: auto; */
                    width:200px;
                    height: 150px;
                    text-align: center;
                    margin: 0 auto;
                }
                .PaddCat{
                    padding: 20px;
                }
                .widthOffer{
                    padding-right:  8px !important;
                    padding-left:  8px !important;
                }
                [dir="ltr"] .widthOffer{
                    padding-left:  8px !important;
                    padding-right:  8px !important;
                }
                .widthAddHome{
                    width: 100%;
                }
                .swiperSlideMraket img{
                    width: 2.5rem ;
                   }
    
                .positionX{
                    background-position-x: 506px !important;
                  
                    background-position-y: 266px !important;
                    max-width: 1700px !important;
                }
                .ImgDownload{
                    position: absolute;
                    left: -45%;
                    bottom: -166%;
                    overflow: hidden;
                    z-index: 0;
                    width: 628px;
                }
                .SeeMoreGradients{
                    width: 18% !important;
                }
                .bordercount{
    
                width: 30px !important;
                height: 30px !important;
                }
                .sizeAdIc{
                    font-size: 1.1rem !important;
                }
                .myswiperMarket{
                    padding: 10px;
                }
    
                .AddCart{
                    font-size: 0.9rem;
                    padding: 10px 8px;
                }
                .paddingAddCartPro{
                    /* padding: 4px; */
                    padding: 8px;
                }
                .paddingoffer{
                    padding: 8px;
                }
                .CatMarket{
                    width: 70px !important;
                    height: 70px !important;
                }
                .font_address{
                    font-size: 0.9rem;
                    font-weight: 100;
                }
                .hakeemapp{
                    font-size: 1.3rem;
                    margin-top: 0;
                    margin-bottom: 0.5rem; 
                 }
                .downloadImg1{
                    width: 2rem;
                    height:2rem;
                    justify-content: flex-start;
                   
                }
                .ShareIcon{
                    font-size: 1.5rem !important;
                }
                .comparedPrice{
                font-size: 1.1rem;
                width: 55.8%;
    
                }
                .sizepricetext{
                    font-size: 0.9rem !important;
                    } 
                .gapEntity{
                    gap:1rem !important;
                }
                .ET{
                    margin-left:0;
                    margin-right:0;
                }
                .Squarereviews{
                    font-size: 1.2rem;
                }
                .BottomProMedical{
                 bottom:60px !important;
                }
                .widthClick{
                    width: 23% !important;
                    margin: 0;
                }
                .heightAndRaduis{
                 
                    height: 45px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
                [dir="ltr"] .heightAndRaduis{
                    border-top-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 10px;
                }
                [dir="ltr"] .clearMeals{
                    left: 86%;
                  
                    
                }
                .compared{
                    width: 77.8%;
                   padding-top: 20px;
                    margin: auto;
                }
                .Circle{
                    margin: 8px auto 0 auto;
                }
                .paddingfood{
                    padding: 10px 0px !important;
                        }
                .myswiperDevices{
                    width: 100%;
                    height: 100%;
                }
                .SelectDate{
                    position: absolute;
                    top:27px;
                   font-size: 1.25rem;
                }
                /* .marginDetailsProducts{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
              [dir="ltr"]  .marginDetailsProducts{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                } */
                .animation{
               
                    width:60%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                
                   
                }
                .selectCountry{
                    width: 1rem;
                    border-radius: 50%;
                }
                .postitionCu{
                    border-radius: 50%;
                    top: -45px;
                    position: relative;
                    width: 1.5rem;
                    height: 1.5rem;
                    z-index: -10;
                    text-align: center;
                    left: -4px;
                }
                [dir="ltr"] .postitionCu{
                    left: 4px;
                }
                .countryDiv{
                    display: grid;
                    grid-template-columns: 40% 50%;
                    width: 100%;
                    align-items: center;
                    padding: 120px 0px 0px 0px;
                    gap: 4rem;
                }
                .paddingBottom{
                    padding-bottom: 80px;
                }
                .track1{
                    top:14.5%
                }
                .trackImg1{
                    width:90px;
                    height: 90px;
                }
                .track2{
                    top:40.4% 
                }
                .track3{
                    top:65% 
                } 
                .track4{
                    top:89.4% 
                } 
            .textorder{
        
                position: absolute;
                width: 29%;
                text-align: end;
                top: 36%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 89% ;
                text-align: end;
                top: 20%;
            
            }
                .widthmobile{
                    width: 100%;
                    margin: auto;
                }
                .ButtonExplorMore{
                    background-color: #F52A59;
                    color: white;
                    border-radius: 4px;
                    position: absolute;
                    bottom: 44px;
                    width: 20%;
                    font-size: 1.1rem;
                    padding: 8px ;
                    text-align: center;
                    box-shadow: 0 0 3px grey;
                    z-index: 1;
                    
                
                }
                [dir="ltr"] .ButtonExplorMore{
    
                    left: 79%;
                }
                .bgExamination{
                    background-color: rgb(235, 235, 255);
                    border-radius: 50%;
                    height: 5.5rem;
                    width: 5.5rem;
                }
                .notFoundRightBlock{
                    text-align: center;
                    margin-top: 30px;
                  }
                  .notFoundRightBlock > h1{
                      font-size: 8em;
                  }
                  .notFoundRightBlock > h3{
                      font-size: 1.5em;
                  }
        
                .CategoryDiv{
                    padding-top:10px ;
                    padding-bottom:10px ;
                    padding-left: 0px;
                    padding-right: 5px;
                }
                [dir="ltr"] .CategoryDiv{
                    padding-left: 5px;
                    padding-right: 0px;
                }
                .paddingSearch{
                    padding: 60px 0px 30px 0px !important;
                }
                .logo{
                    /* width: 5rem !important;
                    height: 3.5rem; */
                }
                .borderradiusTop{
        
                    margin-bottom: 0px !important;
                    width: 28% !important;
                    position:absolute;
                }
                .borderradiusTopMedical{
        
                    margin-bottom: 0px !important;
                    width: 12% !important;
                    /* width: 23% !important; */
                    border-top-right-radius: 50px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 16px !important;
                    border-bottom-right-radius: 0px !important;
                }
                [dir="ltr"] .borderradiusTopMedical{
                    border-top-left-radius: 50px !important;
                    border-top-right-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border-bottom-right-radius: 16px !important;
                }
                .ComfirmationDiv{
                    box-shadow: 0 0 3px #bcbcbc;
                    margin:0px 0px 100px 0px;
                }
                .widthQuantitybtn{
                    width: 26% !important;
                }
                .blogh4{
                    font-size: 0.9rem;
                }
                .Asbutton label{
                    z-index: 1;
                    cursor: pointer;
                    line-height: 1.8em;
                    font-size: 1.1rem;
                }
                .mealscheck label{
                    z-index: 1;
                    cursor: pointer;
    
                }
                .widthAddCardBtn{
                    width: 62% !important; 
               }
                .imgsA{
            
                    /* margin-left: 79%; */
                     width:1.8em;
                     margin: auto;
                     height: 1.8em;
                  
                 
                 }
                 .imgsI{
                     width:1.8em;
                     margin: auto;
                     height: 1.8em;
                    
                 }
                 .imgsG{
                     margin: auto;
                     width:1.8em;
                     height: 1.8em;
                 
                 }
                
                .widthstate{
                    width:50%;
                }
                .flexPrice{
        
                    gap:2rem;
        
                }
                .deletebasket{
                    right: 674px;
                    top: 4px;
                    position: absolute;
                }
                [dir="ltr"] .deletebasket{
                    left:674px;
                    top: 4px;
                }
                .myswiperAddCard{
                    width: 250px;
                    height: 150px;
                    margin:auto;
                }
                .TwoGrid{
                    display: grid;
                    grid-template-columns: 80% 20%;
                    gap:0rem;
                    padding: 40px;
                }
                .paddingProgress{
                    padding: 40px !important;
                }
                .alignInitial{
                    align-items: center !important;
                }
    
                .FourGrid{
                    display: grid ;
                    grid-template-columns: repeat(4,1fr);
                    align-items: flex-start;
                    gap: 1rem;
                    padding: 40px 10px;
                
                }
                .paddingTabsBasket{
                    padding: 0 10px;
                }
                .imgsmeg{
                    width: 1.1rem;
                    height: 1.1rem;
                 
                }
                .imgtele{
                
                    width:1rem;
                    height:1rem;
                }
                .divmessg{
                    margin:auto;
                    /* display: block; */
                    width: 30px;
                    background-color: white;
                    height:30px;
                    border-radius: 50%;
                    padding: 5px;
                    }
                .divtele{
                    width: 30px;
                    background-color: white;
                height: 30px;
                    border-radius: 50%;
                    padding: 5px;
                    margin:auto;
                    /* display: block; */
                }
                .categoryname{
                    font-size: 1.25rem;
                }
                .logof{
                    width: 3rem;
                    height: 3rem;
                    margin-bottom: 0.8rem;
                    margin-left: 0;
                    margin-right: 0;
                     
                      
                    }
                .aboutText{
                    font-size: 1rem;
                }
                .searchs7 {
                    width: 2.5rem;
                    height: 2.5rem;
                
                   
                  
                }
                .searchs7White {
                    width: 3rem;
                    height: 3rem;
                
                   
                  
                }
                .h1s7{
                    font-size: 0.8rem;
                }
                .h1s6{
                
                  font-size: 1rem;
    
                }
                .subtext{
                    font-size: 0.8rem  !important;
                }
                .hLine3{
                  
                    font-size: 2rem;
                    
                  }
                .header4s2{
                
                     font-size: 1.9rem;
              
                 }
                .headers2{
                    font-size: 2.5rem;}
                .pars2{
            
               
                    font-size:medium;
              
                }
                .H4A{
                    font-size: 14px;
                    text-align: initial;
                    margin-left: 0px;
                    margin-right: 6px;
                    }
                    [dir="ltr"] .H4A{
                    
                    margin-left: 6px;
                    margin-right: 0px;
                    }
                .address1{
                 
                   font-size: 2rem;
                  
                  
                  }
                .RegisterLogo{
                    width: 8rem;
                    height: 8rem;
                    margin:  0 auto;
                    max-width: 20rem;
                }
                .EditBtnThreeState{
                    width: 50%;
                    margin: auto;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                .BasketandEditIcon{
                    display: flex;
                    align-items: center;
                   /*  grid-template-columns: 80% 21%; */
                    gap: 1rem;
                    padding: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                  
                }
                [dir="ltr"] .BasketandEditIcon{
                   right: 0;
                   left: 86%;
                }
                .imgoffer{
                    width: 692px;
                    height: 283px !important;
                    margin: auto;
                    object-fit: contain;
                    border: 1px solid #bcbcbc;
                    border-radius: 6px;
                    box-shadow: 0 0 3px #bcbcbc;
                }
                .imgBlog{
                    width: 210px !important;
                    height: 210px !important ;
                }
                .imgBlogSlider{
                    width: 340px !important;
                    height: 220px !important ;
                    object-fit: cover !important;
                    margin: auto;
                    
                }
                .widthbutnBasket{
                    margin: 0 !important;
                    width: 54% !important;
                }
                .BasketAddWidthDialog{
                    width: 60% !important;
                }
                .widthbtnOpen{
                    width:100% !important ;
                }
                .widthbtnOk{
                    width:20% !important ;
                }
                .swiplerslideimg{
                    height: 70px !important;
                    width: 70px !important;
                    margin-bottom: 0 !important;
                   /*  height: 100%;
                    width: 100%;
                    /* object-fit: cover; */
                    /* display: block; */ 
                   
                }
                .galleryContainer{
                    position: relative;
                    width: 388px;
                    /* width: 360px; */
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    overflow: hidden;
                    gap: 0.3rem;
                }
               /*  .sliderSize{
                    width: 100%;
                } */
                .DivTextBasket{
                    display: grid;
                    grid-template-columns: 100%;
                    justify-content: initial;
                    align-items: initial;
                    justify-items: initial;
                }
                .DivTextAddress{
                    display: grid;
                    grid-template-columns: 100%;
                    justify-content: initial;
                    align-items: initial;
                    justify-items: initial;
                    gap: 0.5rem;
                    padding:  0;
                }
                
                .starandrating{
                    display: grid;
                    grid-template-columns: 37% 11%;
                    align-items: flex-start;
                }
                .paddingComment{
                    padding: 30px 0px;
                }
                .paddingcontact{
                    padding: 10px 0px;
                }
                .paddingRatingDiv{
                    padding-bottom: 30px ;
                }
                .SizeFont{
                    font-size: 1rem !important;
                }
                .paddingtopmedicaltext{
                    /* padding-top: 15px; */
                    padding-top:0px ;
                }
                .paddingtopmedicaltext2{
                    /* padding-top: 77px; */
                    padding-top:0px ;
                }
                .BgButton{
                    background-color: #F52A59 !important;
                    border: 0 !important; 
                    color: white !important;
                }
                .BorderBottom{
                    border-bottom:1px solid #c6c2c261;
                   /*  border-right: 0; */
                   padding: 0;
                }
               
                .border{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                 
                left: 255px;
                right: 124px;
                top: 45px;
                }
               [dir="ltr"] .border{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                    right: 255px;
                    left: 124px;
                }
                .border2{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                  
                left: 293px;
                top: 45px;
                right: 235px;
                }
                [dir="ltr"] .border2{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                
                  
                left: 219px;
                
                right: 304px;
                }
             
                .border3{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                    top: 93px;
                    left: 255px;
                right: 124px;
                }
                [dir="ltr"] .border3{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                
                  
                    right: 255px;
                    left: 124px;
                }
                .Order1{
                    order: 1;
                }
                .Order2{
                    order: 2;
                }
                .Category{
                    font-size: 0.8rem !important;
                    padding: 0px ;
                    word-spacing: normal;
                    white-space: pre !important;
                    letter-spacing: normal;
                    text-align: initial;
        
                }
                .PaddingBar{
                    padding: 40px 0px;
                    }
                .iconandtextthreebox{
            display: flex;
            padding: 0 10px;
            gap: 1rem;
                }
                .BasketandButtonOrder{
            grid-template-columns: 21% 50%;
            gap: 1rem;
            
            
                }
                .BasketandButtonOrderBasketpage{
            grid-template-columns: 83% 11%;
            gap: 1rem;
            
            
                }
                .Comfirmationandprice{
            grid-template-columns: repeat(3,1fr);
            gap: 1rem;
            
            
                }
                .Buyandprice{
            /* grid-template-columns: repeat(3,1fr); */
            flex-direction: row;
            gap: 1rem;
            
            
                }
                .iconandtexttime{
            display: flex;
            gap: 1rem;
                }
            .Opendetails{
                box-sizing: border-box;
       
              background-color: #008C8D ;
              border:0px solid  #008C8D ;
                width:18%;
                text-align: center;
                padding: 5px 20px;
              
             margin: auto;
                color: white;
             font-size: 0.9rem;
                border-radius: 9px;
                
            }
            .Closedetails{
                box-sizing: border-box;
                background-color: #F52A59;
              border:0px solid  #F52A59;
                width:18%;
                text-align: center;
                padding: 5px 20px;
              
             margin: auto;
                color: white;
             font-size: 0.9rem;
                border-radius: 11px;
                
            }
                .ComfirmationButton{
                    box-sizing: border-box;
                    background-color: #F52A59;
                  border:0px solid  #F52A59;
                    width:100%;
                    text-align: center !important;
                    padding: 6px 12px !important;
                 margin: auto;
                    color: white !important;
                 
                    border-radius: 6px;
                    
                }
                .BasketAdd{
                    box-sizing: border-box;
                    background-color: white;
                    border:0px solid #008C8D ;
                    width:100%;
                    text-align: center;
                    padding:5px;
                    margin: auto;
                    border:0.5px solid #ccf0ef !important ;
                 
                
                /*  border-bottom-left-radius: 12px;
                 border-bottom-right-radius: 0px; */
                 border-radius: 4px;
                    font-size: 1rem;
                    text-align: center;
                    color: #008C8D ;
                    margin-bottom: 0;
                
                   
                    
                } 
                .Addcomfirmation{
                    box-sizing: border-box;
                    background-color: #008C8D ;
                    border:0px solid #008C8D ;
                    width:100%;
                    text-align: center;
                    padding:5px 15px;
                    margin: auto;
                    border:0.5px solid #ccf0ef ;
                    border-radius: 22px;
                    font-size: 1rem;
                    text-align: center;
                    color: white;
                    margin-bottom: 0;
                
                   
                    
                } 
              /*   [dir="ltr"] .BasketAdd{
            
                    border-bottom-left-radius: 0px;
                   border-bottom-right-radius: 12px;
                 
                   }  */
                .ButtonOrder{
                    background-color: #008C8D  !important;
                    color: white !important;
                }
                .RecycleBin{
               
                    right:84%;
                    bottom:0;
                    position: absolute;
                    width: 0%;
                }
                [dir="ltr"] .RecycleBin{
                    left:84%;
                    bottom:0;
                    position: absolute;
                }
                .hLine9{
                    text-align:initial;
                color: #008C8D ;
                    padding-left: 5%; 
                  display: flex;
                  font-weight: 600;
                  font-size: 1.2rem;
                  position: relative; 
                  z-index:1;
                  padding-right: 5%;
                  padding-bottom: 2%;
                }
                .imgs2{
                
                    width:100%;
                      max-width:550px;
                      height: auto;
                  }
                .BackGroundOfferheader{
                    position: absolute;
                    width: 2.5rem;
                    z-index: 0;
                    top: -8px;
                    right: 12px;
                    }
                    [dir="ltr"] .BackGroundOfferheader{
                        top: -7px;
                        left: 16px;
                    }
                .ContainerMargin{
                    margin-top: 73px;
                }
               .SquareFooter{
                align-items: center;
                justify-content: center;
                 
                text-align: center;
              max-width: 744px;
                   margin: 0% auto;
                 display: grid;
               gap:2rem; 
                 z-index: 1;
                padding: 0px 0px;
                 margin-bottom: -5%;
                 top: -23px;
               width:100%;
                 position: relative;
            
             /*   border-radius: 10px; */
                  }  
                .HeaderDietHakeem{
                    text-align: center;
                      
                        color:#F52A59 ;
                        font-size: 2.5rem;
                        font-weight: 600;
                     
                       
                }
                .bloodtextfooter{
                    font-weight: 600;
                    font-size: 0.9rem;
                    text-align: initial;
                }
                .flexlogonav{
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    justify-items: center;
                   flex:0;
                }
                .stylebasketnav{
                    display: flex;
                    /* grid-template-columns: auto auto auto; */
                    gap: 1rem;
                    flex: 0 4em;
                    justify-content: flex-end;
                }
               
                .basketnav {
                    width: 1.3rem !important;
                    height: 1.3rem !important;
                
                   
                  
                }
                .Countrynav {
                    width: 2rem;
                    height: 2rem;
                    top: -5px;
                
                   
                  
                }
                .cardssocailfooter{
                    text-align: initial;
                
                      gap: 1rem;
                      grid-template-columns: repeat(4,1fr);
               
                      display: grid;
                }
                .prasupport{
                    color: white;
                    /* font-size: 1.2rem; */
                    font-size: 0.8rem;
                    text-align: right;
                    margin: 0;
                   
                }
                [dir="ltr"] .prasupport{
                    color: white;
                   
                    text-align: left;
                   
                }
                .Sponsorsize{
                    height: 500px !important;
                }
                .Offersize{
                    height: 320px !important;
                }
               
                .Articalsize{
             
                    height: 420px !important;
                    margin-bottom: 20px !important;
                    margin-top: 20px !important;
                }
                .ArticalsizeSlider{
                    height: 290px !important;
                   
                    margin-bottom: 20px !important;
                    margin-top: 20px !important;
                    margin-left: 27px !important;
                    padding-bottom: 15px;
                    /* height: 420px !important;
                    margin-bottom: 20px !important;
                    margin-top: 20px !important; */
                }
                [dir="ltr"] .ArticalsizeSlider{
                    margin-left: -8px !important;
                }
                .HeaderDietHakeem2{
                    text-align: center;
                        padding-top: 20px;
                        color:black ;
                        font-size: 2rem;
                     
                     
                       
                }
                
                .stylesDietscard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:120px;
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .stylestrasportwhite{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:50% ;
                    background-color: white;
                    width:50px;
                    height: 50px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .stylesDietscarddetails{
                    box-sizing: border-box;
            
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:100%;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .styleopencategory{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: #008C8D ;
                    width:100%;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: white !important;
                  
                }
                .styletransport{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    /* border: 3px solid #008C8D ; */
                    border-radius:50% ;
                    background-color: #008C8D ;
                    width:50px;
                    height: 50px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: white !important;
                  
                }
                .styleopencategorydetails{
                    box-sizing: border-box;
                    border:0.5px solid#ccf0ef ;
                    border-radius:12px ;
                    background-color: white;
                    
                    width:100%;
                    margin: auto;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    position: relative;
                    justify-content: center;
                }
                .markandheart{
                    position: absolute;
                    right:91%;
                    top:0px;
                }
                [dir="ltr"] .markandheart{
                    position: absolute;
                    left: 91%;
                    top:0px;
                }
                .markandheartdetails{
                    position: absolute;
                    /* right:86%; */
                    right: 90%;
                    top:0px;
                }
                [dir="ltr"] .markandheartdetails{
                    position: absolute;
                    /* left: 86%; */
                    left: 90%;
                    top:0px;
                }
                .markandheartMedical{
                    position: absolute;
                    right:0%;
                    top:0px;
                    z-index: 1;
                }
                [dir="ltr"] .markandheartMedical{
                    position: absolute;
                    left: 0%;
                    top:0px;
                    z-index: 1;
                }
                .markandheartBlog{
                    position: absolute;
                    right:84%;
                    top:-1px;
                }
                [dir="ltr"] .markandheartBlog{
                    position: absolute;
                    left: 84%;
                    top:-1px;
                }
                .markandheartproduct{
                    position: absolute;
                    right:79%;
                    top:0px;
                    width: 50px;
                }
                [dir="ltr"] .markandheartproduct{
                    position: absolute;
                    left: 79%;
                    top:0px;
                    width: 50px;
                }
                .mark{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 3rem !important;
                  
                }
                [dir="ltr"] .mark{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 3rem !important;
                    
                }
                .markdetails{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 2.2rem !important;
                  
                }
                [dir="ltr"] .markdetails{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 2.2rem !important;
                    
                }
                .markproduct{
                    font-size: 3.5rem !important;
                    width: 2rem !important;
                    position: absolute;
                    color:#008C8D ;
                    height: auto !important;
                    object-fit: contain !important;
                  
                }
                [dir="ltr"] .markproduct{
                    font-size: 3.5rem !important;
                    width: 2rem !important;
                    position: absolute;
                    color:#008C8D ;
                    height: auto !important;
                    object-fit: contain !important;
                    
                }
               .heart{
                    font-size: 2rem !important;
                    width: 2rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                   right: 4px;
                    top:4px;
                } 
                [dir="ltr"] .heart{
                    font-size: 2rem !important;
                    width: 2rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                 
                   left:1px; 
                    top:3px;
                }
               .heartdetails{
                    font-size: 2rem !important;
                    width: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                   right: 1px;
                    top:1px;
                } 
                [dir="ltr"] .heartdetails{
                    font-size: 2rem !important;
                    width: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                 
                   left:1px; 
                    top:1px;
                }
               .heartproduct{
                    font-size: 1.5rem !important;
                    width: 1.3rem !important;
                    height: 1.3rem !important;
                    position: absolute;
                    right: 2px;
                    top:2px;
                    object-fit: contain;
                } 
                [dir="ltr"] .heartproduct{
                    font-size: 1.5rem !important;
                    width: 1.3rem !important;
                    height: 1.3rem !important;
                    position: absolute;
                    object-fit: contain;
                    left:2px; 
                    top:2px;
                }
    
                .heartblog{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                   right: 2px;
                    top:2px;
                    object-fit: contain;
                }
                [dir="ltr"] .heartblog{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                    object-fit: contain;
                   left:2px; 
                   top:2px;
                }
    
    
                .textminimun{
                    font-size: 1.2rem;
                    color: black;
                    font-weight: 600;
                    text-align: initial;
                }
                .searchs3{
                   
                    width: 100%;
                    height:100%;
                  
                  }
                .searchs31{
                   
                    width: 80%;
                    height:100%;
                  
                  }
                .threeimg{
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                     display: grid;
                     grid-template-columns: repeat(3,1fr);
                     gap: 1rem;
                     margin: auto;
            
                  justify-items: stretch;
                   
                 }
                .headerFAQ{
                    font-size: 2rem;
                    color:black;
                    
                    border-top:  3px solid #008C8D ;
                    border-bottom: 3px solid #008C8D ;
                    padding: 1% 0 1% 0;
                    width: 50%;
                
                }
                .imgsupport{
              
                    width:3rem !important ;
                    height: 3rem;
                 
                }
                .stylesCardDetailsPatient{
                    box-sizing: border-box;
                    box-shadow: 2px 2px  5px 0px #bcbcbc;
                    border: none;
                    border-radius:4px ;
                    background-color: white;
                    margin: auto;
                    width: 80%;
                    padding: 0 0px;
                }
               
                .stylesCardBooking{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    margin: auto;
                    width: 60%;
                    padding: 60px 0px;
                }
                .stylesCalendar{
                    box-sizing: border-box;
                  
                    border: none;
                    border-radius: 4px ;
                    background-color: white;
                    margin: auto;
                    width: 80%;
                    padding: 10px;
                  /*   max-width: 550px; */
                }
                .Container{
                     padding: 0px 10px; 
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    /* max-width: 670px; */
                    max-width: 730px !important;
                }
                .ContainerDownload{
                    /* padding: 0px 40px;
                    max-width: 1500px; */
                    padding: 10px 10px;
                    /* max-width: 670px; */
                    max-width: 730px;
                    margin: auto;
                }
               /*  .HakeemSection2{
                    padding: 50px 0 0 0; 
                   } */
                .SectionContainer{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:20px 10px;
                    /* max-width: 670px; */
                    max-width: 730px;
                
                }
                .SectionContainerDeleteAccount{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:20px 10px;
                    /* max-width: 670px; */
                    max-width: 730px;
                
                }
                .paddingComfirmation{
                    padding: 30px 10px !important;
                }
                .Effectstext{
                    font-size: large;
                    text-align: initial;
                }
              
                .Effects{
                    font-size: 1.5rem;
                    text-align: initial;
                }
                .priceBefore{
                    color:#F52A59;
                    text-align: initial;
                }
                .pricenow{
                    color:#008C8D ;
                    text-align: initial;
                }
                .pproducttext{
                    text-align: initial;
                    font-size: 1rem;
                    font-weight: 400;
                    margin:0 !important;
                    /* padding: 23px 0; */
                    padding:10px 0px;
                }
                .pproducttext h3 span{
                    text-align: initial;
                    font-size: 1rem;
                    font-weight: 400;
                    margin:0 !important;
                  
                    padding:10px 0px;
                }
                .pproducttexttop{
                    text-align: initial;
                    font-size: 0.9rem;
                    font-weight: 400;
                    margin: 0;
                }
                .nameProduct{
                    color:black;
                    text-align: initial;
                    font-weight: 600;
                }
                .RelatedProducts{
                    color:black;
                    text-align: initial;
                    font-weight: 600;
            
                }
                .myswiper{
                    box-sizing:border-box;
            /*         padding: 0px 6px !important; */
                    height: 320px;
                    margin-left: 0px !important;
                    margin-right:  0px !important;
                    width: 113px ;
                }
                .myswiper2{
                    width: 336px;
                    height: 330px;
                    margin-left: 0 !important;
                    margin-right:  0 !important;
                    
                }
                .headersupport{
                    color: white;
                    text-align: right;
                    direction: rtl;
                    /* font-size: 1.5rem; */
                    font-size: 0.9rem;
                }
                [dir="ltr"] .headersupport{
                    color: white;
                    text-align: left;
                    direction: ltr;
                    /* font-size: 1.5rem; */
                }
                .searchbox{
                    border-top-left-radius: 5% 50%;
                    border-bottom-left-radius: 5% 50%;
                    border-top-right-radius: 5% 50%;
                    border-bottom-right-radius: 5% 50%;
                  }
                
                form.searchbox input[type=text]{
                  padding: 10px 10px;
                  border: none;
                  border-radius: inherit;
                  width: 90%;
                }
                form.searchbox input[type=text]:focus{
                  border:none;
                  outline: none;
                }
                form.searchbox .searchBtn{
                  padding: 15px 13px;
                  background: #f52a59;
                  border:none;
                  color: #fff;
                  cursor: pointer; 
                  border-radius: 50%;
                }
                .searchboxhome{
                    border-top-left-radius: 5% 50%;
                    border-bottom-left-radius: 5% 50%;
                    border-top-right-radius: 5% 50%;
                    border-bottom-right-radius: 5% 50%;
                  }
                
                form.searchboxhome input[type=text]{
                  padding: 15px 20px;
                  border: none;
                  border-radius: inherit;
                  width: 90%;
                }
                form.searchboxhome input[type=text]:focus{
                  border:none;
                  outline: none;
                }
                form.searchboxhome .searchBtn{
                  padding: 15px 13px;
                  background: #f52a59;
                  border:none;
                  color: #fff;
                  cursor: pointer; 
                  border-radius: 50%;
                }
                .Stack{
                    justify-content: center;
                    width: 100%;
                }
                .StackBook{
                    justify-content: center;
                    width: 100%;
                }
             
                .avatercard{
                    box-shadow:0px 0px 3px grey;
                    border-radius: 50%;
                    width:200px;
                    height:200px;
                 
                }
                .hLine7{
                    padding-left: 2% /* كان %3 */;
                    padding-top: 2%;
                    padding-right: 2% /* كان %3 */;
                    text-align: initial;
                    font-size: 1rem;
                    
                   }
                   .HH{
                    padding-left: 1.5% !important;
                    padding-top: 2% !important;
                    padding-right: 1.5% !important;  
                   }
        
                .sizeAvatar{
                    /* width:140px !important;
                   height:140px !important ;
                   box-shadow:0px 0px 6px grey;
                   border-radius: 50%; */
                   width: 130px !important;
                   height: 130px !important;
                   border-radius: 50% !important;
                   /* top: -35px; */
                   box-shadow:0px 0px 3px grey;
                   /* box-shadow:  -2px 1px 3px 0px #bcbcbc; */
                }
                /* [dir="ltr"] .sizeAvatar{
                   box-shadow:  2px 1px 3px 0px #bcbcbc;
                } */
                .sizeAvatarWithoutborder{
                    width:300px !important;
                   height:300px !important ;
            
                }
                .sizeAvatarProfile{
                    width:70px !important;
                   height:70px !important ;
                   box-shadow:0px 0px 3px grey;
                   border-radius: 50%;
                }
                .sizeAvatarcard{
                    width:120px !important;
                   height:120px !important ;
                   box-shadow:0px 0px 3px grey;
                   border-radius: 50%;
                }
                .ProfileUser{
                    box-shadow:0px 0px 3px grey;
                    border-radius: 50%;
                }
                .sizestars{
                    font-size: 1.5rem !important;
                    width:184px !important;
                
                   align-items: flex-start;
                   
                }
                .sizestarscomment{
                    font-size: 1.3rem !important;
                    width:300px !important;
                
                   align-items: center;
                   
                }
        
                .TwoButtonSoical{
                width: 77.8%;
                }
                .textfooter{
                    font-size: 0.8rem;
                }
                .sizestarsproduct{
                    font-size: 1.5rem !important;
                    width:auto !important;
                
                   align-items: center;
                   
                }
                .sizestarscard{
                    font-size: 1.5rem !important;
                 /*    width:200px !important; */
                
                   align-items: center;
                   
                }
                .h3sortby{
                    font-size: 1.5rem;
                    margin:0;
                }
                .animation1{
             
                     height: 7rem; 
              
                    width:7rem;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                
                   
                }
                .animation2{
                    height: 7rem;
                    width:7rem;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                }
                .hrjsoin{
                width:60%;
                height:0px ;
                border-width:3px ;
                margin-bottom:-87px;
                 /* align:center;  */
                color:#F52A59;
                background-color:#F52A59;
                opacity: 1 !important;
        }
            
        .yellowsquare{
            box-sizing: border-box;
            background-color: #faaf00;
            border:0px solid #faaf00;
            width:12%;
            right:0%;
            bottom: 0%;
            padding: 3px 0px 3px 0px;
            position: absolute;
            border-radius: 0px 0px 12px 0px;
            font-size: 1rem;
        }
                [dir="ltr"] .yellowsquare{
                    padding: 3px 0px 3px 0px;
                    left:0%;
                    border-radius: 0px 0px 0px 12px;
                } 
    
                .yellowsquare2{
                    box-sizing: border-box;
                    background-color: #faaf00;
                    border:0px solid #faaf00;
                    /* right:91%; */
                    /* border-radius: 12px 0px 0px 0px; */
                    width:9%;
                    padding: 0px 0px 3px 0px;
                    position: absolute;
                    font-size: 1rem;
                }
                [dir="ltr"] .yellowsquare2{
                    /* left:91%; */
                    /* border-radius: 0px 12px 0px 0px;  */
                } 
    
             [dir="ltr"] .Statesquare{
                box-sizing: border-box;
                background-color: #faaf00;
                border:0px solid  #faaf00;
                width:13%;
                text-align: center;
                padding: 5px;
                position: absolute;
                left:87%;
                color: white;
                bottom: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 0px;
                    
                } 
                .Statesquare{
                    left:0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    
                }
                .buttonAddProduct{
                    box-sizing: border-box;
                    background-color: white;
                    border: 1px solid #bcbcbc;
                    width:100%;
                    text-align: center;
                    padding: 10px 30px;
                    font-size: 1.1rem;
                
                    color: black;
                    bottom: 0;
             
                }
                .Open{
                    box-sizing: border-box;
                    background-color: #008C8D ;
                    border:0px solid  #008C8D ;
                    width:13%;
                    text-align: center;
                    padding: 5px;
                    position: absolute;
                    left:0;
                    color: white;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px; 
                    font-size: 1rem; 
                }
             [dir="ltr"] .Open{
                left:88%;
                border-top-left-radius: 10px;
                border-top-right-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                    
                } 
    
             [dir="ltr"] .Add{
                box-sizing: border-box;
                background-color: #008C8D ;
                border:0px solid  #008C8D ;
                width:21%;
                text-align: center;
                position: absolute;
                left:77%;
                color: white;
                bottom: 0;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                    
                } 
            
                .Add{
                    left:0;
                    height: 45px; 
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
              
             [dir="ltr"] .ClickAdd{
            
                left:77%;
              
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border:0.5px solid #ccf0ef ;
                    
                } 
                .ClickAdd{
                    box-sizing: border-box;
                    background-color: white;
                    border:0px solid  #008C8D ;
                    width:23%;
                    text-align: center;
                    position: absolute;
                    color: black;
                    bottom: 0;
                    left:0;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border:0.5px solid #ccf0ef ;   
                }
             
     
                .Close{
                    box-sizing: border-box;
                    background-color:#F52A59;
                    border:0px solid  #F52A59;
                    width:13%;
                    text-align: center;
                    padding: 5px;
                    position: absolute;
                    left:0;
                    color: white;
                    font-size: 1rem;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    
                }
                [dir="ltr"] .Close{
    
                    left:88%;
                    bottom: 0;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                        
                    } 
                
                [dir="ltr"] .PinkNameSquare{
                    box-sizing: border-box;
                    background-color: #F52A59;
                    border-right-color: #008C8D ;
                    border-left-color:  #F52A59;
                    width:12%;
                    padding: 0px 0px 10px 0px;
                    position: absolute;
                    left:20%;
                    border-radius:0px;
                    font-size: 20px;
                    
                } 
                .PinkNameSquare{
                    border-left-color: #008C8D ;
                    border-right-color: #F52A59;
                    padding: 0px 0px 10px 0px;
                    right:20%;
                    top: 0px;
                    border-radius:0px;   
                }
                .Preview{
                  width:59.5%;
                  font-size: 1.2rem;
                  padding: 12px;
                 
                   margin: 0 20%;
                   color: #008C8D ;
                   font-weight: 600;
                   box-shadow:0 0 3px #bcbcbc ;
                }
                
                .labelPreview{
                    padding: 10px;
                    margin: 0;
                }
                .checkUseinfo{
                    color: tomato;
                }
       
                .backgroundDiscover{
                   background-position: center;
                   background-repeat: no-repeat;
                   background-size: cover;
                   height: 100%;
                   margin: 2px ;
                   background-color: white;
                   background-position: initial;
                   max-width: 1500px;
                   margin: auto;
                 
               }
                .formandbutton{
                    grid-template-columns: repeat(1,1fr);
                    align-items: center;
                }
                .select{
                    border: none;
                    font-size: 20px;
                    width: auto;
                  
                    }
                .cardHakeemMedical{
            
                    align-items: center;
                   /* padding: 100px; */
                   
                 }
               .HakeemMedicaltext{
                   /* padding-bottom:100px; */
                   /* padding-bottom: 30px; */
               }
                
                .HeaderMadicalHakeem{
                    text-align: center;
                        padding-top: 60px;
                        color:#008C8D  ;
                      font-size: 2rem;
                       
                }
                .HeaderLogin{
                   
                    
                    color:#008C8D  ;
                  font-size: 2rem;
                  font-weight: 600;
                }
                .accountcreation{
                   
                
                    text-decoration: none;
                    color:#bcbcbc;
                }
                .forgetPassword{
                    text-decoration-line: none;
                    padding-top: 10px;
                    padding-left: 6px;
                    padding-right: 6px;
                
                }
                .parforgetPassword{
                    text-decoration-line: none;
                    padding-top: 10px;
                    padding-left: 6px;
                    padding-right: 6px;
                
                }
                
                .HeaderMadicalHakeem2{
                    text-align: center;
                    padding-top: 1%;
                    color:#008C8D  ;
                  font-size: 3rem;
                }
                .HeaderMadicalHakeem3{
                    text-align: center;
                    padding-top: 1%;
                    color:black ;
                  font-size: 2rem;
                }
               
                .HeaderMadicalHakeem4{
                    text-align: center;
                    padding-top: 1%;
                    color:black ;
                  font-size: 1.5rem;
                }
               
                
            
            .form{
                grid-template-columns: repeat(3,1fr);
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                    border-radius: 28px;
                 padding-top:50px;
                 width: 74%;
                  display: grid;
                  padding-bottom: 50px;
                  align-items: center;
                  gap: 1rem;
            }
            .formSearch{
                grid-template-columns: 100%;
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                border-radius: 12px;
             /*     padding-top:35px; */
                width:100%;
                display: grid;
            /*       padding-bottom: 35px; */
                align-items: center;
                gap: 0rem;
                  /* max-width: 640px; */
            }
            .formUp{
                grid-template-columns:100%;
                background-color: white;
                text-align: center;
                border-radius: 28px;
                gap: 1rem;
                width:100%;
                display: grid;
                align-items: center;
                max-width: 1060px;
            }
            .formdown{
                grid-template-columns:100%;
                background-color: white;
                gap: 1rem;
                text-align: center;
                border-radius: 28px;
                width:100%;
                display: grid;
                align-items: center;
                max-width: 1060px;
            }
            .StarDiet{
                stroke:#008C8D ;
                stroke-width:1;
                color:#F52A59
            }
            .StarBorderDiet{
                stroke-width:1;
                stroke:#008C8D ;
                color:#008C8D 
            }
            .formDietSearch{
                grid-template-columns: repeat(1,1fr);
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                    border-radius: 12px;
                /*  padding-top:35px; */
                 width:100%;
                  display: grid;
            /*       padding-bottom: 35px; */
                  align-items: center;
                  gap: 1rem;
                 
               
            }
            
            .DietSearch{
                grid-template-columns: 30% 40%  30%;
                background-color: white;
              
                text-align: center;
                    border-radius: 28px;
                 padding-top:10px;
                 width:92%;
                  display: grid;
                  padding-bottom: 10px;
                  align-items: center;
                  margin: auto;
              
            }
            .productSearch{
                grid-template-columns: 50% 50%;
                background-color: white;
              
                text-align: center;
                border-radius: 28px;
                padding-top:10px;
                width:92%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                margin: auto;
              
            }
            .proName{
                grid-template-columns: 100%;
                background-color: white;
                text-align: center;
                border-radius: 28px;
                padding-top: 10px;
                width: 92%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                margin: auto;
            }
    
            .label{
                background-color:white;
              flex: 0.4;
              
                border: 0;
            
                outline: none;
               
                font-size: 14px;
                font-weight: 600;
                color: grey;
                margin: 0;
              
            }
            .searchbtn{  padding: 5px 10px;
                color: white;
                 background-color:#F52A59;
                 border: none;
                 border-radius: 10px;
                 font-size: 1.1rem;
                 font-weight: 100;
                 width: 20%;
                 height: auto;
      
                 z-index: 1;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
                 margin-top: -3%;}
            .SeeMore{
            
             padding: 7px !important;
                color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 10px;
                 font-size: 1.1rem;
            
               width: 100%;
            
               height: auto;
        
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            }
            .widthPrice{
                width: 79%;
                padding: 14px !important;
            }
            .change{
            
                 padding: 5px 10px;
                 color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 0px;
                 width: 80%;
                 height: 53px;
                 font-size:1rem;
                 z-index: 2;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
            }
            .Buy{
            
                padding: 5px 10px;
                   color: white;
                    background-color:#008C8D ;
                    border: none;
                    border-radius: 10px;
                    font-size: 1rem;
               
                  width: 22%;
               display: flex;
                  height: auto;
                  font-size:25px;
               z-index: 2;
              
               }
                .cards{
                    grid-template-columns: repeat(2,1fr) !important  ;
                    -ms-grid-columns: repeat(2,1fr) !important  ;
                }
                
                .stars{
                    grid-template-columns: repeat(1,1fr);
                }
                .Person{
                    width:1.2rem;
                  }
                  .view{
                   /* width:1.5rem; */
                   width: 100%;
                   margin: auto;
                  }
                  .viewmedical{
                   width:1.5rem; 
                   height:1.5rem; 
              
                   margin: auto;
                  }
                  .icontitle{
                   width:1.4rem !important;
                   height: 1.4rem !important;
                  }
                  .icontContact{
                   width:3.5rem !important;
                   height: 3.5rem !important;
                  }
                  .icontFAQ{
                   width:5rem !important;
                   height: 6.5rem !important;
                  }
                  .iconBasketTab{
                   width:4rem;
                   height: 4rem;
                   font-size: 3rem !important;
                  }
                  .iconBlog{
                   width:2rem;
                   height: 2rem;
                   font-size: 3rem !important;
                  }
                  .iconBasketMain{
                   width:2rem;
                   height: 2rem;
                   font-size: 3rem !important;
                  }
                  .icondollar{
                   width:1.5rem;
                   height: 3.5rem;
                  }
                  .iconPhone{
                   width:1.5rem !important;
                   height: 1.5rem !important;
                  }
                  .iconBasket{
                   width:1.1rem !important;
                   height:1.1rem !important;
                   object-fit: contain !important;
                  }
               
                  .iconremoveBasket{
                   width:1.3rem !important;
                   height:1.3rem !important;
                   object-fit: contain !important;
                  }
               
                .personandstar{
                    grid-template-columns: repeat(1,1fr);
                    gap:0.5rem;
                }
                .StarandDistance{
                    grid-template-columns: 33.3% 33.3% 33.3%;
                    gap:0rem;
                }
                .cardssection3{
                    grid-template-columns: auto auto;
                  width: 90%;
                  margin: auto;
            
                }
                .personandview{
                    grid-template-columns: auto auto ;
                    gap: 0.4rem;
                    margin: 0;
                    justify-content: initial;
                }
                .positionStarMedical{
                    display: grid;
                    width: 136%;
                    justify-items: flex-start;
                }
                .cardssection5{
                    grid-template-columns: repeat(3,1fr);
            
                }
                .icontext{
                    font-size: 0.9rem;
                  color: black;
                }
                .cardssection6{
                    /* grid-template-columns: repeat(3,1fr); */
                    grid-template-columns: repeat(4,1fr);
                    gap: 0rem;
            
                }
                .cardsJsonMedical{
                    grid-template-columns: repeat(7,1fr);
            
                }
                .cardssection7{
                    
                    padding: 10px;
                    grid-template-columns: repeat(4,1fr) ;
                    gap: 0.5rem;
                   
                }
                .ContactDiv{
                    
                    grid-template-columns: repeat(2,1fr) ;
                    gap: 0.5rem;
            
                }
                .orderprice{
                    
                    /* grid-template-columns: repeat(2,1fr) ; */
                    gap: 2rem;
            
                }
                .typetransport{
                    
                    /* grid-template-columns: repeat(2,1fr) ; */
                    gap: 2rem;
                    justify-items:initial;
            
                }
                .AddressandChangebtndiv{
                    
                    grid-template-columns: 75% 25% ;
                    gap: 1rem;
            
                }
                .OrAndBorder{
                    
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 2rem;
            
                }
                .imgandName{
                    
                    grid-template-columns: 17% 79% ;
                    gap: 1rem;
            
                }
                .ComfirmationTop{
                    
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 1rem;
                    justify-items: start;
            
                }
                .QuantityandOrderandPriceDiv{
                    
                    grid-template-columns:  41% 12% 43%;
                    gap: 1rem;
                    justify-items: initial;
                    padding: 20px 20px;
                }
                .flexPrice{
                    justify-content: center;
                }
                .QuantityandOrderandPriceDivDialog{
                    padding: 20px;
                    grid-template-columns: repeat(2,1fr) ;
                    gap: 1rem;
                    justify-items: initial;
            
                }
                .ThreeDivproduct{
                    
                    grid-template-columns: 17% 57% 21%;
                    gap: 0.5rem;
                    justify-items: start;
            
                }
                .ThreeButtonProduct{
                    
                    grid-template-columns:90%; 
        
                    width: 77.8%;
                    margin: auto;
                    padding-top: 0px;
                    justify-content: start;
        
                    align-items: center;
                    justify-items: start;
            
                }
                .TwoButtonProduct{
                    
                    width: 77.8%;
                    gap: 0.5rem;
                    margin: auto;
            
                }
                .ShowthreeProduct{
                    
                    grid-template-columns:repeat(2,1fr);
                    gap: 0.4rem;
            
                }
                .ShowOffer{
                    
                    grid-template-columns:repeat(3,1fr);
                    gap: 1rem;
            
                }
                .TimeandDistancesandPrice{
                    justify-items:initial;
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 2rem;
            
                }
                .DivCount{
                    
                    grid-template-columns: repeat(3,1fr) ;
                  /*   gap: 2rem; */
                  height: 42px;   
            
                }
                .heightOfferbtn{
                    height: 45px;    
                }
                .CardandBasket{
                    
                    /* grid-template-columns:  30% 68%; */
                    grid-template-columns:37% 61%;
                    justify-items: center;
                    gap: 1rem; 
            
                }
                .lineprogressandName{
                    
                    grid-template-columns:  30% 66%;
                    justify-items: center;
                   gap: 1rem; 
            
                }
                .lineprogressBook{
                    
                    grid-template-columns:  100%;
                    justify-items: center;
                   gap: 1rem; 
            
                }
                .Twotextprice{
                    text-align: initial;
                    grid-template-columns: repeat(5,1fr) ;
                    gap: 2rem;
                     
                    flex-direction: row;
            
                }
                .TwoPriceDialog{
                    text-align: initial;
                    gap: 1rem;
                    flex-direction: row;
            
                }
                .TwotextpriceProduct{
                    text-align: initial;
                    /* grid-template-columns: 100% ; */
                    gap: 0rem;
                    flex-direction: column;
    
            
                }
                .PriceOffer{
                    text-align: initial;
                    grid-template-columns:repeat(1,1fr) ;
                    gap: 0rem;
            
                }
                .TwotextpriceProduct2{
                    text-align: initial;
                    /* grid-template-columns: 50% 50% ; */
                /*     gap: 1rem; */
                bottom: 100px;
                left:3px;
             
                 position: absolute;
            
                }
                [dir="ltr"] .TwotextpriceProduct2{
                    /* left:143px; */
                    left: 185px;
                }
                .TabsCard{
                    
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 2rem;
            
                }
                .phoneSquare{
                    
                    grid-template-columns:6%  44% ;
                 /*     gap: 2rem;  */
                    text-align: initial;
            
                }
                .phoneSquarediet{
                    grid-template-columns:5%  56% 39%;
                    /* grid-template-columns:5%  24% 39%; */
                 /*     gap: 2rem;  */
                    text-align: initial;
                    padding: 0;
            gap: 0;
                }
                .Twoelereviews{
                    
                    grid-template-columns: 20% 26% ;
                     /* gap: 10rem;  */
                     gap: 1rem;
                    text-align: initial;
            
                }
                .ThreeCalendar{
                    
                    grid-template-columns: repeat(3,1fr);
                    gap: 1rem;
            
                }
               
                .textandsocial{
                    
                    grid-template-columns: repeat(1,1fr);
                    text-align: initial;
                }
                .textfooter{
                    
                    grid-template-columns: repeat(1,1fr);
                    text-align: initial;
                }
                .cardsDietscard{
                    
                    grid-template-columns: repeat(4,1fr);  text-align: center;
                    
                    padding-top:20px;
               
                      display: grid;
                      padding-bottom: 20px;
                      gap: 1rem;
                 
            
                }
                .cardsmedicianorder{
                    
                    grid-template-columns: repeat(4,1fr);  text-align: center;
                    
                    padding-top:20px;
               
                      display: grid;
                      padding-bottom: 20px;
                      gap: 1rem;
                      
                 
            
                }
                .QuestionandPhotocard{
                    
                    grid-template-columns: repeat(2,1fr);
            
                }
                .cardsTime{
                    
                    grid-template-columns: repeat(4,1fr);
                        gap: 1rem;
                }
                .PreviewForm{
                    grid-auto-columns: 100%;
                }
                .cardinfo{
                    grid-template-columns:100%;
                    gap:0rem;
                }
                .cardsRelatedProducts{
                    grid-template-columns: repeat(2,1fr);
                    gap: 1rem;
                }
                .cardsOfferProducts{
                    grid-template-columns: repeat(2,1fr);
                    gap: 2rem;
                }
                .productphotofour{
                    grid-template-columns:49px 49px 49px 49px;
                    gap: 1rem;
                }
                .Viewproduct{
                    grid-template-columns:  repeat(2,1fr);
                    gap: 1rem;
                    padding-bottom: 20px; 
                }
                .productphoto{
                    grid-template-columns: repeat(1,1fr);
                }
                .cardLogin{
                    grid-template-columns: auto 50%; 
                }
                .cardTradeMarks{
                    grid-template-columns: repeat(6,1fr);
            
                }
                .cardProducts{
                    grid-template-columns: repeat(8,1fr);
                }
                .topthree{
                
                    
                        grid-template-columns: repeat(3,1fr);
                
                   
                }
                .cardssocail{
               /*      grid-template-columns: repeat(5,1fr); */
                    gap: 1rem;
                }
                .cardssection8{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .cardssection10{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .cardssection11{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .downloadsection{
                    grid-template-columns: repeat(2,1fr);
            
                }
                .cardsDiscover{
                    grid-template-columns: repeat(3,1fr);
                 gap: 3rem;
                }
                .cardspost{
                    grid-template-columns: repeat(1,1fr);
                }
                    .footer{
                        grid-template-columns: repeat(4,1fr);
                        padding-bottom:2% ;
                        padding-top: 2%;
                        /* max-width: 670px; */
                        max-width: 744px;
                        justify-items: center;
                        padding-left: 0px;
                        padding-right: 0px;
                        gap: 2rem;
        
                    }
                    .SquareFooter{
                        grid-template-columns: 28% 61%;
                        /* grid-template-columns: 18% 77%; */
                    }
                    .cardpost{
                        grid-template-columns: repeat(2,1fr);
                    }
                    .btnhakeemapp{
                        grid-template-columns: repeat(2,1fr);
                        text-align: center;
                        margin-top:10% ;
                        min-width: 40px;
                        margin:  auto;
                        display: grid;
                        gap:1rem;
                        padding: 6px 10px 6px 0px;
                        display: inline-flex;;
                        color: grey;
                         background-color:white;
                         box-shadow: 0 0 3px #bcbcbc;
                         border: none;
                         border-radius: 10px;
                         justify-items: center;
                         align-items: center;
                        cursor: pointer;
                        /* width: 165px; */
                        width: 155px;
                    }
                    [dir="ltr"] .btnhakeemapp{     
                        padding: 6px 0px 6px 10px;
                    }
                   .cardsquare{
                        grid-template-columns: repeat(2,1fr);
                        order:1;
                    }
                    .cardprofile{
                        grid-template-columns: repeat(3,1fr);
                        order:1;
                    }
                    .cardSupoort{
                        grid-template-columns:87% 13%;
                       
                    }
                    .cardSupoort .cards1{
                        order:-1;
                    }
                    .Download{
                        grid-template-columns: 188px 203px;
                       order:0;
                       gap:0;
                      
                    }
                    .DownloadHeader{
                        grid-template-columns: 100%;
                       order:0;
                       gap:0.5rem;
                      
                    }
                    .cardsanimation{
                        grid-template-columns: repeat(1,1fr);  
                    }
                    .textaboutdoctor1{
                        font-size: 1.3rem;
                        /* margin-bottom: 0;  */
                        line-height: 1.5em;
                    }
                    .TextBasket1{
                        font-size: 1.5em;
                /*         margin-bottom: 0; */ 
                        line-height: 1.5em;
                        text-align: initial;
                    }
                    .TextBasket1{
                        font-size: 1.5em;
                /*         margin-bottom: 0; */ 
                        line-height: 1.5em;
                        text-align: initial;
                    }
                    .textaboutresturant1{
                        font-size: 1rem;
                  /*       margin-bottom: 0; */
                        line-height: 1.5em;
                    }
                    .textaboutdoctor1card{
                        font-size: 1.3rem;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        color: #008C8D ;
                        font-weight: 600;
                    }
                    .textaboutdoctor2{
                        font-size: .9rem !important;
                        /* margin-bottom: 8px; */
                        line-height: 1.5em;
                    }
                    .TextBasket2{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        text-align: initial;
                    }
                    .fontNum{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: right;
                    }
                    .fontNum1{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: start;
                    }
                   [dir="ltr"] .fontNum{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: left;
                    }
                   [dir="ltr"] .fontNum1{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: end;
                    }
                    .textaboutdoctor2card{
                        font-size: 1.1rem;
                        margin-bottom: 0;
                        line-height: 1.5em;
                    }
                    .textaboutdoctor3{
                        font-size: 0.9rem !important;
                        margin-bottom: 0;
                        padding: 0px;
                    }
                    .TextBasket3{
                        font-size: 1em;
                        margin-bottom: 0;
                        text-align: initial;
                    }
                    .textaboutdoctor3card{
                        font-size: 1.3em;
                        margin-bottom: 0;
                    }
                    .starimg{
                        width: 2em;
                    }
                    .BackgroundHakeemMedical{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: initial;
                       max-width: 1500px;
                       /* max-width: 1190px; */
                       margin: auto;
                    
                      
                    }
                    .BackgroundDiet{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                    
                      
                    }
                    .BackgroundGreySquare{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                   
                      
                    }
                    .BackgroundGreySquareAbout{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                   
                      
                    }
                   
                    
                 .btnImg{
                    width: 100%;
                top:22%;
                font-size: 1.5em !important;
                color: #F52A59;
                padding: 10px;
                background-Color:white; 
               
                border-radius:50% ;
                }
                .btnImg1{
                    width: 50%;
                  /* padding-top:-15%; */
                  font-size: 1em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
            
                }
                .Arrowleft{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 1em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
            
                }
                .ArrowScroll{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 0.5em !important;
                  color: #F52A59;
                  padding: 5px;
                 
                 
                  border-radius:50% ;
            
                }
                [dir="left"].btnImg1{
                    width: 50%;
                  /* padding-top:-15%; */
                  padding-left:20px;
                }
                [dir="left"].Arrowleft{
                    width: 50%;
                  padding-top:-15%;
                  padding-left:20px;
                }
                [dir="left"].ArrowScroll{
                    width: 50%;
                  padding-top:-15%;
                  padding-left:20px;
                }
                .btnImg2{
                  width: 50%;
                  font-size: 1em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                  border-radius:50% ;
                }
                .ArrowServices{
                  width: 50%;
                  font-size: 2em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                  border-radius:50% ;
                }
                .Arrowright{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 1em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
                
                  
                }
               
               .imgnextstyle{
                margin:auto;
                display: block;
                width: 100%;
                background-color: white;
                height:73px;
                border-radius: 50%;
                padding: 20px;
                }
                .searchbox{
                    width: 100%;
                   height: auto;
                    background-color:white;
                    display: flex;
                    align-items: center;
                    border-radius: 60px;
            
                    margin-left: auto;
                    margin-right: auto;
                    padding: 2px 4px; 
                    box-shadow: 0 0 3px #bcbcbc;
                   }
                .searchboxhome{
                    width: 100%;
                   height: auto;
                    background-color:white;
                    display: flex;
                    align-items: center;
                    border-radius: 60px;
            
                    margin-left: auto;
                    margin-right: auto;
                    padding: 2px 4px; 
                    box-shadow: 0 0 3px #bcbcbc;
                   }
                   .search{
                   background-color:white;
                   width: 70%;
                   flex: 1;
                   border: 0;
                   width: 50%;
                   outline: none;
                   padding-left: 30px;
                   padding-right: 30px;
                   font-size: 20px;
                   color: grey;
                   border-radius: 60px;
                   }
                   .searchhome{
                   background-color:white;
                
                   flex: 1;
                   border: 0;
                   width: 100%;
                   outline: none;
                   padding-left: 30px;
                   padding-right: 30px;
                   font-size: 20px;
                   color: grey;
                   border-radius: 60px;
                   }
                   .searchtext{
                    background-color:white;
                    width: 100%;
                    flex: 1;
                    border: 0;
                    text-align: center;
                    outline: none;
                   
                    font-size: 20px;
                    color: grey;
                    border-radius: 60px;
                    }
                   .imgsearchhome{
                   width: 55px;
                   
                  
                   }
                   .imgsearch{
                    width: 3rem;
                    
                   
                    }
                   .btnsearchhome{
                       border: 0;
                      border-radius: 50%;
                       width: 55px;
                       background-color: white;
                   }
                   .btnsearch{
                       border: 0;
                      border-radius: 50%;
                       width: 3rem;
                       background-color: white;
                   }
                   .searchs6 {
                    width: 3.5rem;
                    height: 3.5rem;
               
                   
                
                }
                   .searchs6Examination {
                    width: 6rem;
                    height: 5.8rem;
                    padding: 10%;
                   
                
                }
                .searchsimg {
                    width: 10rem;
                    height: "100px";
                    padding-Top:10% ;
                   
                
                }
                .threeimg1{
                    align-items: center;
                   justify-content: center;
                    display: grid;
                    grid-template-columns: 30% 70%;
                 gap: 0;
                  width: 100%;
                   
                 
                }
                .cardsBlog{
                    
                    grid-template-columns: repeat(3,1fr);
                    gap:1rem;
            
                }
                .cardsdevices{
                    
                    grid-template-columns: repeat(3,1fr);
                    gap:1rem;
            
                }
                .twoimg{
                  
                     display: flex;
                     grid-template-columns: 97% 54%;
                     padding:20px 10px 0px 10px ;
                    /*  padding-left: 5%;
                     padding-right: 5%; */
                     gap:0rem
                    
                 
                }
                .Calendarcard{
                  
                    display: grid;
                    grid-template-columns:100%;
                  
                   
                
               }
                .subCalendarcard{
                  
                    display: grid;
                    grid-template-columns:16% 84%;
                  
                   
                
               }
                .cardmedical{
                    display: grid;
                    grid-template-columns:59% 40%;
                    gap: 0.5rem;
                    height:254px;
                    width: 100%; 
                    padding: 1% 1%;
                }
                .cardAddress{
                    display: grid;
                    grid-template-columns:59% 40%;
                    gap: 0.5rem;
                    height:254px;
                    width: 100%; 
                    padding: 1% 1%;
                }
                .cardBooking{
                    display: grid;
                    grid-template-columns:100%;
                    gap: 0rem;
                 /*    padding: 5%; */
                    width: 100%;    
                }
                .comment{
                     display: flex;
                     grid-template-columns: 75% 83%;
                     margin: auto;
                
                   gap: 20.5rem;
                   
                }
                .Detailprofile{
                    grid-template-columns:30% 34% 43%;
                
                }
                .profileandtextcard{
                    
                    text-align: initial;
                  
                    width: 100%;
                    grid-template-columns:32% 68%;
                }
                .profileandtextcardmedical{
                    
                    text-align: initial;
                    gap:0.5rem;
                    width: 100%;
                    grid-template-columns:38% 61%;
                    /* grid-template-columns:57% 44%; */
                }
                .part1{
                    text-align: initial;
                    gap: 1rem;
                    width: 100%;
                    grid-template-columns: 100%;
                    gap:0.5rem;
                    justify-items: center;
                }
                .profileandtextcardfood{
                    
                    text-align: initial;
                  
                    width: 100%;
                    grid-template-columns: 36% 61%;
                    gap: 1rem;
                }
                .BasketTextandButton{
                    
                    text-align: initial;
                  padding:10px 10px;
                    width: 100%;
                    grid-template-columns: 70% 30%;
                }
                .Orderprice{
                    
                    text-align: initial;
                  padding:0 10px;
                    width: 100%;
                    grid-template-columns:repeat(2,1fr);
                }
                .profileandtextcardDetails{
                    
                    text-align: center;
                  gap: 0.5rem;
                    width: 100%;
                    grid-template-columns: 100%;
                }
                .iconandtext{
                    grid-template-columns:29% 20%;
                    gap: 0.1rem;
                    justify-content: initial;
                    
                }
                .Detailcommentright{
                    grid-template-columns: repeat(2,1fr);
                }
                .Detailcommentleft{
                    grid-template-columns: repeat(2,1fr);
                }
                .doctorName{
                    font-size: 1rem ;
                    text-align: center;
                    margin: 0;
                    font-weight: 600;
                    width: 100%;
                }
                .tagWord{
                    font-size: 1.3em ;
                    text-align: initial;
                    margin: 0;
                    font-weight: 600;
                    padding: 0 74px;
                }
                .ArticalTitle{
                    font-size: 1.2rem;
                    padding-left: 2%;
                    padding-top: 4%;
                 padding-right: 2%;
                 font-weight: 600; 
                }
                .profileimg{
                    width: 4rem;
                }
                .doctorprofileimg{
                    width: 14rem;
                }
                .dot{
                    width: 5rem;
                }
                .Commenttitle{
                    padding-right: 10px  !important;
                    font-size: 1.3em  !important;
                    text-align: initial  !important;
                    margin: 0  !important;
                    font-weight: 600  !important;
                }
                .loveimg{
                    width: 1rem;
                }
                .backgroundSlogen{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                }
                .backgroundSlogenDialog{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                }
                .imgopacity1{
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                    opacity: 0.5;
                    background-color: #bcbcbc;
                  }
                  .Clockandtext{
                    display: grid;
                    grid-template-columns: 11% 40%;
                    align-items: center;
                    justify-content: initial;
                }
                .iconsSize{
                    font-size:1.2rem !important
               }
               .phoneSquarestyle{
                background-color: #bcbcbc63;
            }
            .SizeSilderCard{
                max-width: 400px;
                margin: auto;
            }
            .paragraphandIcons{
                display: grid;
                grid-template-columns: 6% 93%;
                align-items: flex-start;
             
            }
            .paragraphandIconsSpecialty{
                display: grid;
                grid-template-columns: 2% 93%;
                align-items: flex-start;
             
            }
            .TitleandIcons{
                /* display: grid; */
                display: flex;
                /* grid-template-columns: 4% 60%; */
                align-items: center;
                padding: 0 0 10px 0;
                gap: 0.3rem;
            }
            .TitleandIconsDevicesSlider{
                display: grid;
                grid-template-columns: 4% 79% 18%;
                align-items: center;
                padding: 0 0 10px 0;
            }
            .HeaderBasket{
                display: grid;
                grid-template-columns: 4% 79% 18%;
                align-items: center;
                padding: 0 0 10px 0;
            }
            .HeaderandBasketIcon{
                /* display: grid;
                grid-template-columns: 20% 43%; */
                display: flex;
                align-items: center;
                justify-content: initial;
                padding: 10px 20px;
                gap: 0.5rem;
                justify-items: start;
            }
            .HeaderandBasketIconpagebasket{
                display: flex;
                
                align-items: center;
                justify-content: initial;
                padding: 10px 0px;
                gap: 0.5rem;
                justify-items: center;
            }
            .stylesTabsCard{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                border: none;
                border-radius:12px ;
                background-color: white;
                position: relative;
                width: 100%;
                margin: auto;
            
              
            }
            .stylesTabsBasket{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                border: none;
                border-radius:12px ;
                background-color: white; 
                position: relative;
                width: 77%;
                margin: 0;
            
              
            }
            .stylesTabsFav{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                border: none;
                border-radius:12px ;
                background-color: white; 
                position: relative;
                width: 100%;
                margin: auto;
            
              
            }
            .stylesTabsFavDiet{
                box-sizing: border-box;
                box-shadow: 0 0 3px #bcbcbc;
                border: none;
                border-radius:12px ;
                background-color: white; 
                position: relative;
                width: 55%;
                margin: auto;
            
              
            }
            .paddingTopContactandTermpage{
              padding: 100px 0px !important;
            }
            
            }
            @media (min-width:820px){
                .trackImg1{
                    width:100px;
                    height: 100px;
                }
            }
            @media (min-width:912px){
    
                .track1{
                    top:15%
                }
                .track2{
                    top:41.4% 
                }
                .track3{
                    top:65.6% 
                }   
                .track4{
                    top:90.4% 
                } 
            .textorder{
        
                position: absolute;
                width: 27%;
                text-align: end;
                top:36%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 91% ;
                text-align: end;
                top: 20%;
            
            }
        
            }
            @media (min-width:992px){
                /* .flexOtherPrice{
                    display: flex;
                    flex-direction: row;
                   
                    } */
                .icon_setting{
                    width: 3rem;
                    height: 3rem;
    
                }
                .padding_form{
                    padding-bottom:0px ;
                
                }
                .Badge_POs{
                    right: 96%;
                    left: 0;
                    position: absolute;
                    top: 2%;
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
                [dir="ltr"] .Badge_POs{
                    left: 96%;
                    right: 0;
                }
                .wid_Btn_dia{
                 width: 30%;
                }
                .disAddcomfirmation{
                    box-sizing: border-box;
                    /* background-color: #008C8D ;
                    border:0px solid #008C8D ; */
                    width:100%;
                    text-align: center;
                    padding:5px 15px;
                    margin: auto;
                    /* border:0.5px solid #ccf0ef ; */
                    border-radius: 4px;
                    font-size: 1.1rem;
                    text-align: center;
                    color: black;
                    margin-bottom: 0; 
                    
                } 
                .galleryMain{
                    width:480px !important;
                    height: 300px;
                    box-shadow: 0 50px 75px 50px rgba(#1e1e1e,0.18);
                    border:1px solid #e9e9e9e9
                }
                .NameCat{
                    font-size: 0.8rem;
                }
                .FSocial{
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    font-size: 1.5rem !important;
                }
                .hakeemapp{
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }
            .footer p{
                margin-bottom: 0.8rem;
            }
                .GridDetails{
                    display: grid;
                    grid-template-columns: 80% 20%;
                    align-items: flex-start;
                }
                /* .TabOffer{
                    grid-template-columns: repeat(6,1fr);
                } */
                .imgtypetransport{
                    width:3rem;
                    height:3rem;
                }
                .imgcompanytransport{
                    width:4rem;
                    height:4rem;   
                    border-radius: 50%;
                }
                .heightmen{
                    height: 100%;
                }
                .UlSubCatMenu{
                    width: 81%;
                    left: 0%;
                    height:100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                [dir="ltr"] .UlSubCatMenu{
                   
                    left: 19%;
                }
                .UlCatMenu a{
                    font-size: 0.8rem;
                }
                
                .UlCatAll a, .UlCatAll Button ,.UlCatAll span{
                    font-size: 0.8rem !important;
                }
                .GridUlCatMenu{
    
                    grid-template-columns: repeat(3,1fr);
                    /* height: 100%; */
                }
                .ET{
                    /* width:130px; */
                    height: 30px;
                    margin: auto !important;
                    font-size: 0.8rem;
                }
                 .ETpadd{
                    right: 10px;
                    top: -38px;
    
                }
               [dir="ltr"] .ETpadd{
                    left: 10px;
                }
                .rectangle{
                    height: 210px !important;
                }
                .hLine6{
                    font-size: 1.2rem;
                }
                .CategoryHeader{
                    font-size: 1.2rem !important;
                }
                .BlodText{
                    font-size: 1.3rem;
                }
                .detailpost{
                    font-size: 1rem;
                }
                .myswiperArtical img{
                    height: 215px !important;
           
                  }
                .devicesh4{
                    font-size: 1.1rem;
                }
                .sizeCard{
                    width: 100%;
                }
                .allCalendar{
                    
                    font-size: 0.7rem;
                    margin: 0;
                }
                .RESIZE{
                    width: 86%;
                    margin: 0;
                }
                .heightOff{
                    height: 19px;
                }
                .logo{
                    /* width: 3.6rem !important;
                    height: 3.6rem; */
                }
                .BgCategory{
                    top: 57px;
                }
                .clickSignin{
                    font-size: 0.8rem;
                }
                .paddingCategorymenu{
                    padding: 0px 62px;
                }
                .loadingData{
                    grid-template-columns: repeat(4,1fr);
                }
                .loadingPro{
                    grid-template-columns: repeat(3,1fr);
                }
                .loadingDataCat{
                    grid-template-columns: repeat(8,1fr);
                    gap: 2rem;
                }
                .loadingMenu{
                    grid-template-columns: repeat(8,1fr);
                    gap: 2rem;
                }
                .MenuMar{
                    margin-bottom: 118px;
                }
        
                .ImgPro{
                    width: 478px;
                    border: 1px solid #bcbcbc;
                }
                .imgtrad{
                    width: 500px;
                    border: 1px solid #bcbcbc;
                }
                .Empty{
                    width: 103px;
                }
                .DivImagePro{
                    display: grid;
                    grid-template-columns: 16% 82%;
                    gap: 0.5rem;
                    width: 591px;
                }
                .widthProductHome{
                    width:235px !important;
                }
                .gridBrands{
                  
                    grid-template-columns: repeat(3,1fr);
                }
                .gapbasket{
                    gap: 0.5rem !important;
                }
                .NoItem{
                    width: 25%;
                    height:25%;
                }
                .SquareBaskte{
                    width:49%;
                    height: 100%;
                
                }
                .widthDes{
                    width: 32%;
                    margin: auto;
                }
                .BgComp{
                   
                    padding: 20px 40px;
                }
                .BorderRig{
                    border-left: 1px solid #bcbcbc;
                    padding-left: 5px;
                
                }
                .BorderLef{
                    border-right: 1px solid #bcbcbc !important;
                    padding-right: 5px !important;
                
                }
                .CategoryImage{
                    width:207px;
                    height: 165px;
                    /* width: 160px;
                    height: 120px; */
                    /* width: 80%; */
                 
                    text-align: center;
                    margin: 0 auto;
                }
                
                .PaddCat{
                    padding: 20px ;
                }
                .widthOffer{
                    padding-right:  8px !important;
                    padding-left:  8px !important;
                }
                [dir="ltr"] .widthOffer{
                    padding-left:  8px !important;
                    padding-right:  8px !important;
                }
                .widthAddHome{
                    width: 100%;
                }
                 .swiperSlideMraket img{
                    width: 3rem ; 
                   }
    
                .ImgDownload{
                    /* left: -36%;
                    overflow: hidden;
                    position: relative;
                    bottom: -108%;
                    position: absolute;
                    z-index: 0;
                    width: 59%; */
                    left: -36%;
                    overflow: hidden;
                    position: relative;
                    bottom: -85%;
                    position: absolute;
                    z-index: 0;
                    width: 700px;
                }
                .SquareOffer{
                    font-size: 1.1em;
                }
                .CatMarket{
                width: 90px !important;
                height: 90px !important;
    
                }
             .bordercount{
               width: 35px !important;
               height: 35px !important;
               }
                .sizeAdIc{
                    font-size: 1.2rem !important;
                }
                .AddCart{
                    font-size: 1.1rem;
                }
                .paddingAddCartPro{
                    padding: 8px;
                }
                .paddingoffer{
                    padding: 8px;
                }
                .myswiperMarket{
                    padding: 20px;
                }
                .SeeMoreGradients{
                    width: 15%;
                }
                .HH{
    
                    padding-left: 2% !important;
                    padding-top: 2% !important;
                    padding-right: 2% !important;
    
    
                }
                .positionX{
                    background-position-x: 744px !important;
                  
                    background-position-y: 266px !important;
                    max-width: 1700px !important;
                }
                .font_address{
                    font-size: 1.1rem;
                    font-weight: 100;
                }
                .ShareIcon{
                    font-size: 1.8rem;
                }
                .comparedPrice{
                    font-size: 1.3rem;
                    width: 58.5%;
                    }
                .sizepricetext{
                    font-size: 1rem !important;
                    } 
                .imgpost{
                    width: 99%;
                    height: 400px;
                }
                .gapEntity{
                    gap:2rem !important;
                }
                .ET{
                    margin-left:0;
                    margin-right:0;
                }
                .Squarereviews{
                    font-size: 1.2rem;
                }
                .BottomProMedical{
                    bottom:60px !important;
                                }
                .widthClick{
                    width: 23% !important;
                    margin: 0;
                }
                .heightAndRaduis{
                 
                    height: 45px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                }
                [dir="ltr"] .heightAndRaduis{
                    border-top-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 10px;
                }
                [dir="ltr"] .clearMeals{
                    left: 86%;
                  
                   
                }
     
                .compared{
                    width: 80%;
                    padding-top: 20px;
                    margin: auto;
                }
                .avater{
                    height: auto;
               
                }
                .positiontext{
                    /* top: -24px; */
                    position: relative;
                }
                .listMenu{
                    display: grid;
                    grid-template-columns: repeat(5,1fr);
                    align-items: center;
                    justify-content: initial;
    
                    justify-items: initial;
                    /* width: 100%; */
                    width: 90%;
                    margin: 0;
                }
                .SublistMenu{
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    align-items: center;
                    justify-content: center;
                    justify-content: center;
                    justify-items: center;
                    gap: 1rem;
                    padding-top: 15px;
             
                }
                .SubCategory{
                    display: flex;
                    grid-template-columns: 75% 23%;
                    gap: 1rem;
                    flex-direction: row;
             
                }
                .SevenCategory{
                    display: grid;
                    grid-template-columns: repeat(3,1fr);
                    align-items: flex-start;
                    justify-content: center;
                    justify-content: center;
                    justify-items: flex-start;
                    gap: 1rem;
    
             
                }
                .FlexMenu{
                    display: grid;
                    grid-template-columns: repeat(1,1fr);
                    padding: 10px 0;
                   
                }
                .Circle{
                    margin: 10px auto 0 auto;
                }
                .paddingfood{
                    padding: 10px 0px !important;
                        }
                .myswiperDevices{
                    width: 60%;
                    height: 100%;
                }
                .SelectDate{
                    position: absolute;
                    top:25px;
                   font-size: 1.25rem;
                }
                .animation{
               
                    width:70%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                
                   
                }
                .countryDiv{
                    display: grid;
                    grid-template-columns: 40% 50%;
                    width: 100%;
                    align-items: center;
                    padding: 50px 0px 0px 0px;
                    gap: 4rem;
                }
                .paddingBottom{
                    padding-bottom: 80px;
                }
                .widthmobile{
                    width: 100%;
                    margin: auto;
                }
                .postitionCu{
                    border-radius: 50%;
                    top: -43px;
                    position: relative;
                    width: 1rem;
                    z-index: -10;
                    text-align: center;
                    left: 0px;
                }
                [dir="ltr"] .postitionCu{
                    left: 0px;
                }
                .selectCountry{
                    width: 1rem;
                    border-radius: 50%;
                }
                .paddingtopex{
                    padding-top: 1%;
                }
                .ButtonExplorMore{
                    background-color: #F52A59;
                    color: white;
                    border-radius: 4px;
                    position: absolute;
                    bottom: 44px;
                    width: 15%;
                    font-size: 1rem;
                    padding: 4px;
                    text-align: center;
                    margin: 0px;
                    box-shadow: 0 0 3px grey;
                    z-index: 1;
                    
                }
                [dir="ltr"] .ButtonExplorMore{
    
                    left: 83%;
                }
                .bgExamination{
                    background-color: rgb(235, 235, 255);
                    border-radius: 50%;
                    height: 7rem;
                    width: 7rem;
                }
                .notFoundRightBlock{
                    text-align: center;
                    margin-top: 100px;
                  }
                .borderradiusTop{
        
                    margin-bottom: 0px !important;
                    width: 23% !important;
                }
                .borderradiusTopMedical{
                    margin-bottom: 0px !important;
                    width: 9% !important;              
                    /* width: 15% !important;               */
                    border-top-right-radius: 50px !important;
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 16px !important;
                    border-bottom-right-radius: 0px !important;
                }
                [dir="ltr"] .borderradiusTopMedical{
                    border-top-left-radius: 50px !important;
                    border-top-right-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    border-bottom-right-radius: 16px !important;
                    left:91% !important;
                    /* left:85% !important; */
                }
                .ComfirmationDiv{
                    box-shadow: 0 0 3px #bcbcbc;
                    margin:0px 0px 100px 0px;
                }
                .widthQuantitybtn{
                    width: 50% !important;
                }
                .blogh4{
                    font-size: 1rem;
                }
                .Asbutton label{
                    z-index: 1;
                    cursor: pointer;
                    line-height: 1.8em;
                    font-size: 1.1rem;
                }
                .mealscheck label{
                    z-index: 1;
                    cursor: pointer;
    
                }
                .iconsSize{
                    font-size:1.2rem !important
               }
                .TwoButtonSoical{
                    width: 79%;
                    }
             /* .marginDetailsProducts{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                [dir="ltr"]  .marginDetailsProducts{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                } */
                .widthAddCardBtn{
                    width: 62% !important; 
               }
                .textfooter{
                    font-size: 0.9rem;
                }
                .imgsA{
            
                    /* margin-left: 79%; */
                     width:1.5em;
                     margin: auto;
                  
                 
                 }
                 .imgsI{
                     width:1.5em;
                     margin: auto;
                    
                 }
                 .imgsG{
                     margin: auto;
                     width:1.5em;
                 
                 }
                .widthstate{
                    width:50%;
                }
            
                .flexPrice{
        
                    gap:2rem;
        
                }
                .myswiperAddCard{
                    width: 300px;
                    height: 200px;
                    margin:auto;
                }
                .TwoGrid{
                    display: grid;
                    grid-template-columns: 75% 20%;
                    gap: 3rem;
                    padding: 40px;
                }
                .alignInitial{
                    align-items: initial !important;
                }
                .FourGrid{
                    display: grid ;
                    grid-template-columns: repeat(4,1fr);
                    align-items: flex-start;
                    gap: 2rem;
                    padding: 40px 0px;
                
                }
              
                .paddingTabsBasket{
                    padding: 0 10px;
                }
                .imgsmeg{
                    width: 1.3rem;
                    height: 1.3rem;
                 
                }
                .imgtele{
                
                    width:1.2rem;
                    height:1.2rem;
                }
                .divmessg{
                    margin:auto;
                    /* display: block; */
                    width: 40px;
                    background-color: white;
                    height:40px;
                    border-radius: 50%;
                    padding: 9px;
                    }
                .divtele{
                    width: 40px;
                    background-color: white;
                height: 40px;
                    border-radius: 50%;
                    padding: 8px;
                    margin:auto;
                    /* display: block; */
                }
                .categoryname{
                    font-size: 1.25rem;
                }
                .logof{
                    width: 3.5rem ;
                    height: 3.5rem;
                    margin-bottom: 0.8rem;
                      margin-left: 0;
                      margin-right: 0;
                     
                      
                    }
                .aboutText{
                    font-size: 1rem;
                }
                .searchs7 {
                    /* width: 3rem;
                    height: 3rem;  before*/
                    width: 4rem;
                    height: 4rem;
                
                   
                  
                }
                .searchs7White {
                    width: 3rem;
                    height: 3rem;
                
                   
                  
                }
                .h1s7{
                    font-size: 1rem;
                }
                .h1s6{
                  
                  font-size: 1.1rem;
                }
                .subtext{
                  
                  font-size: 0.8rem  !important;
                }
                .hLine3{
                  
                    font-size: 2rem;
                    
                  }
                .header4s2{
                
                    font-size: 2rem;
             
                }
                .headers2{
                    font-size: 2.7rem;}
                .pars2{
            
               
                    font-size:medium;
                    
                }
                .H4A{
                    font-size: 18px;
                    text-align: initial;
                    margin: 0;
                    /* width:calc(100% - 16px);
                    margin-right: 10px; */
                    }
                    [dir="ltr"] .H4A{
            
                        margin-left: 0px;
                        
                        }
                .address1{
                 
                   font-size: 2rem;
            
                  
                  }
                .RegisterLogo{
                    width: 9rem;
                    height: 9rem;
                    margin:  0 auto;
                    max-width: 20rem;
                }
                .EditBtnThreeState{
                    width: 50%;
                    margin: auto;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                .BasketandEditIcon{
                    display: flex;
                    align-items: center;
                   /*  grid-template-columns: 80% 21%; */
                    gap: 1rem;
                    padding: 10px;
                    position: absolute;
                    top: 0;
                    left: 0;
                  
                }
                [dir="ltr"] .BasketandEditIcon{
                   right: 0;
                   left: 89%;
                }
                .paddingTopContactandTermpage{
                    padding: 120px 0px !important;
                  }
                .imgoffer{
                    width: 1010px;
                    height: 414px !important;
                    margin: auto;
                     object-fit: contain;  /*before contain */
                    border: 1px solid #bcbcbc;
                    border-radius: 6px;
                    box-shadow: 0 0 3px #bcbcbc;
                }
                .imgBlog{
                    width: 322px !important;
                    height: 250px !important;
                }
                .imgBlogSlider{
                    width: 322px !important;
                    height: 330px !important;
                }
                .bgSoicalicon{
                    background-color: #F52a5930;
                    border-radius: 50%;
                   padding: 2%;
                  
                }
                .BasketAddWidthDialog{
                    width: 60% !important;
                }
                .widthbtnOpen{
                    width:33% !important ;
                    margin:0 !important;
                }
                .widthbtnOk{
                    width:100% !important ;
                }
                .deletebasket{
                    right:970px;
                    top: 4px;
                    position: absolute;
                }
                [dir="ltr"] .deletebasket{
                    left: 980px;
                    top: 4px;
                }
                .swiplerslideimg{
                    height: 100px !important;
                    width: 100px !important;
                   /*  height: 100%;
                    width: 100%;
                    /* object-fit: cover; */
                    /* display: block; */ 
                   
                }
                .galleryContainer{
                    position: relative;
                    width: 730px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    overflow: hidden;
                    gap: 0.5rem;
                }
                .sliderSize{
                    width: 100%;
                }
                .DivTextBasket{
                    display: grid;
                    grid-template-columns: 100%;
                    justify-content: initial;
                    align-items: initial;
                    justify-items: initial;
                }
                .DivTextAddress{
                    display: grid;
                    grid-template-columns: 100%;
                    justify-content: initial;
                    align-items: initial;
                    justify-items: initial;
                    gap: 0.5rem;
                    padding:  0;
                }
                .imgproductbasket{
                    max-width:550px ;
                    width:360px;
                    height: 240px;
                    max-height:550px ;
                    margin: auto;
                 }
                .starandrating{
                    display: grid;
                    grid-template-columns: 31% 7%;
                    align-items: flex-start;
                }
                .paddingComment{
                    padding: 20px 0px;
                }
                .paddingcontact{
                    padding: 10px 0px;
                }
                .paddingRatingDiv{
                    padding-bottom: 40px ;
                }
                .SizeFont{
                    font-size: 1.1rem !important;
                }
                .paddingtopmedicaltext{
                    /* padding-top: 15px; */
                    padding-top:0px ;
                }
                .paddingtopmedicaltext2{
                    /* padding-top: 77px; */
                    padding-top:0px ;
                }
                .BorderBottom{
                    border-bottom:1px solid #bcbcbc ;
                   /*  border-right: 0; */
                   padding: 0;
                }
                .border{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                 
                left: 255px;
                right: 188px;
                top: 45px;
                }
               [dir="ltr"] .border{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                    right: 255px;
                    left: 188px;
                }
                .border2{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                  
                left: 293px;
                top: 45px;
                right: 323px;
                }
                [dir="ltr"] .border2{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                
                  
                left: 304px;
                
                right: 304px;
                }
             
                .border3{
                    border-left: 2px solid #bcbcbc ;
                    border-right: 0;
                    height: 25px;
                    position: absolute;
                    top: 93px;
                    left: 255px;
                right: 188px;
                }
                [dir="ltr"] .border3{
                    border-right: 2px solid #bcbcbc ;
                    border-left: 0 ;
                
                  
                    right: 255px;
                    left: 188px;
                }
              
                .textPriceProduct{
                    font-size: 1em;
                    margin-bottom: 0px;
                    padding-left: 8px;
                    padding-right: 8px;
                    text-align: initial;
                    font-weight: 600;
                }
                .Order1{
                    order: 1;
                }
                .Order2{
                    order: 2;
                }
                .PaddingBar{
                    padding: 60px 0px;
                    }
                .cardDiet{
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                     display: grid;
                     grid-template-columns: 100%;
                     gap: 0rem;
                 /*     gap: 1rem; */
                     margin: auto;
                    padding:0%;
                    height: 275px;
                    width: 100%; 
                }
                
                .iconandtextthreebox{
            display: flex;
            padding: 0 10px;
            gap: 0rem;
                }
                .BasketandButtonOrder{
                    grid-template-columns: 13% 50%;
                    gap: 1rem;
            
                }
                .BasketandButtonOrderBasketpage{
                    grid-template-columns: 42% 15%;
                    gap:10rem;
            
                }
                .Comfirmationandprice{
                    grid-template-columns: repeat(2,1fr);
                    gap: 1rem;
            
                }
                .Buyandprice{
                    /* grid-template-columns: repeat(3,1fr); */
                    flex-direction: row;
            gap: 1rem;
            
                }
                .iconandtexttime{
                    display: flex;
                    gap: 1rem;
                }
        
                .BasketAdd{
                    box-sizing: border-box;
                    background-color: white;
                    border:0px solid #008C8D ;
                    width:100%;
                    text-align: center;
                    padding:5px;
                    margin: auto;
                    border:0.5px solid #ccf0ef ;
                 
              /*    border-bottom-left-radius: 12px;
                border-bottom-right-radius: 0px; */
                    border-radius: 4px;
                    font-size: 1rem;
                    text-align: center;
                    color: #008C8D ;
                    margin-bottom: 0;
                
                   
                    
                } 
                .Addcomfirmation{
                    box-sizing: border-box;
                    background-color:#008C8D ;
                    border:0px solid #008C8D ;
                    width:100%;
                    text-align: center;
                    padding:9.4px 15px;   /*  padding:5px 15px; */
                    margin: auto;
                    border:0.5px solid #ccf0ef ;
                    border-radius: 22px;
                    font-size: 1rem;
                    text-align: center;
                    color: white;
                    margin-bottom: 0;
                
                   
                    
                } 
            /* [dir="ltr"] .BasketAdd{
            
                 border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
              
                }  */
            
                .ButtonOrder{
                    background-color: #008C8D  !important;
                    color: white !important;
                }
              
                .RecycleBin{
               
                    right:87%;
                    bottom:0;
                    position: absolute;
                    width: 0%;
                }
                [dir="ltr"] .RecycleBin{
                    left:87%;
                    bottom:0;
                    position: absolute;
                }
                .stylesTabsCard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                    margin: auto;
               
                  
                }
                .stylesTabsBasket{
               box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width:72.8%;
                    margin: 0;
               
                  
                }
                .stylesTabsFavDiet{
               box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width:30%;
                    margin: auto;
               
                  
                }
                .stylesTabsFav{
               box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white; 
                    position: relative;
                    width:100%;
                    margin: auto;
               
                  
                }
               
                .stylesTabsCard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                    margin: auto;
               
                  
                }
               
                .stylesTabsCard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    position: relative;
                    width:100%;
                    margin: auto;
               
                  
                }
               
                
                .hLine9{
                    text-align:initial;
                    color: #008C8D ;
                    padding-left:4%; 
                  display: flex;
                  font-weight: 600;
                  font-size: 1.3rem;
                  position: relative; 
                  z-index:1;
                  padding-right: 4%;
                }
                
                .TitleandIcons{
                    display: flex;
                    grid-template-columns: 2% 60%;
                    align-items: center;
                    padding: 0 0 10px 0;
                    gap: 0.5rem;
                }
                .TitleandIconsDevicesSlider{
                    display: grid;
                    grid-template-columns: 3% 79% 18%;
                    align-items: center;
                    padding: 0 0 10px 0;
                    justify-content: space-between;
                }
                .HeaderBasket{
                    display: grid;
                    grid-template-columns: 3% 93% 4%;
                    align-items: center;
                    padding: 0 0 10px 0;
                    justify-content: space-between;
                }
                .HeaderandBasketIcon{
                    display: grid;
                    grid-template-columns: 11% 33%;
                    align-items: center;
                    justify-content: initial;
                    align-items: center;
                /*     gap: 1rem; */
                   padding:10px 20px; 
                   gap: 0rem;
                   justify-items: start;
                }
                .HeaderandBasketIconpagebasket{
                    display: flex;
                  /*   grid-template-columns: 5% 95%; */
                    align-items: center;
                    justify-content: initial;
                    align-items: center;
                /*     gap: 1rem; */
                   padding:10px 0px; 
                   gap: 0rem;
                   justify-items: center;
                }
                .paragraphandIcons{
                    display: grid;
                    grid-template-columns: 6% 93%;
                    align-items: flex-start;
                    /* justify-items:center ; */
                 
                }
                .paragraphandIconsSpecialty{
                    display: grid;
                    grid-template-columns: 2% 93%;
                    align-items: flex-start;
                    /* justify-items:center ; */
                 
                }
                .SizeSilderCard{
                    max-width: 400px;
                    margin: auto;
                }
                .SizeSearch{
                    max-width: 800px !important;
                    margin: auto;
                }
                .phoneSquarestyle{
                    background-color: #bcbcbc63;
                   
                }
                .Clockandtext{
                    display: grid;
                    /* grid-template-columns: 8% 92%; */
                    grid-template-columns:4% 95%;
                    align-items: center;
            
                }
              
                .imgopacity1{
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                    opacity: 0.5;
                    background-color: #bcbcbc;
                  }
                .backgroundSlogen{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                }
                .backgroundSlogenDialog{
                    background-color: #E7FBFA;
                    width: 100%;
                    padding: 20px;
                    border-radius: 16px;
                }
                .imgs2{
                
                    width:700px;
                      max-width:550px;
                      height: auto;
                  }
                .BackGroundOfferheader{
                    position: absolute;
                    width: 3rem;
                    z-index: 0;
                    top: -8px;
                    right: 12px;
                    }
                    [dir="ltr"] .BackGroundOfferheader{
                        position: absolute;
                        width: 3rem;
                        z-index: 0;
                        top: -12px;
                        left: 12px;
                    }
                .ContainerMargin{
                    margin-top: 74px;
                }
               .SquareFooter{
                
                   align-items: center;
                   justify-content: center;
                   
                   text-align: center;
                 max-width: 1500px;
                      margin: 0% auto;
                    display: grid;
                  gap:2rem; 
                    z-index: 1;
                  padding: 0px 0px; 
                  top: -25px;
                  width:100%;
                    position: relative;
                 
                  }  
                .HeaderDietHakeem{
                    text-align: center;
                      
                        color:#F52A59 ;
                        font-size: 2.5rem;
                        font-weight: 600;
                     
                       
                }
                .bloodtextfooter{
                    font-weight: 600;
                    font-size: 0.9rem;
                    text-align: initial;
                }
                .flexlogonav{
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-content: center;
                    justify-items: center;
                   flex:0;
                }
                .stylebasketnav{
                    display: flex;
                    /* grid-template-columns: auto auto auto; */
                    gap: 1rem;
                    flex: 0 4em;
                    justify-content: flex-start;
                }
               
                .basketnav {
                    width: 1.4rem !important;
                    height: 1.4rem !important;
                
                   
                  
                }
                .Countrynav {
                    width: 1.5rem;
                    height: 1.8rem;
                    top:-3px
                
                   
                  
                }
                .cardssocailfooter{
                    text-align: initial;
                
                      gap: 1rem;
                      grid-template-columns: repeat(5,1fr);
               
                      display: grid;
                }
                .prasupport{
                    color: white;
                    font-size: 0.9rem;
                    text-align: right;
                    margin: 0;
                   
                }
                [dir="ltr"] .prasupport{
                    color: white;
                 
                    text-align: left;
                   
                }
                .Sponsorsize{
                    height: 500px !important;
                }
                .Offersize{
                    height: 320px !important;
                  margin-left: 20px !important;
                }
                .Articalsize{
                  height: 430px !important;
                  margin-left: 20px !important;
                  margin-bottom: 20px !important;
                  margin-top: 20px !important;
                }
                .ArticalsizeSlider{
                  height: 330px !important;
                  margin-left: 20px !important;
                  margin-bottom: 20px !important;
                  margin-top: 20px !important;
                  padding-bottom: 20px ;
                }
    
               
                .HeaderDietHakeem2{
                    text-align: center;
                        padding-top: 20px;
                        color:black ;
                        font-size: 2rem;
                     
                     
                       
                }
                
                .stylesDietscard{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:150px;
                    height: 150px;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .stylestrasportwhite{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:50% ;
                    background-color: white;
                    width:72px;
                    height: 72px;
                    /* width:70px;
                    height: 70px; */
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .stylesDietscarddetails{
                    box-sizing: border-box;
                
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    width:100%;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                  
                }
                .styleopencategory{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: #008C8D ;
                    width:100%;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: white !important;
                  
                }
                .styletransport{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    /* border: 3px solid #008C8D ; */
                    border-radius:50% ;
                    background-color: #008C8D ;
                    width:73px;
                    height: 72px;
                    /* width:70px;
                    height: 70px; */
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                    justify-content: center;
                    color: white !important;
                  
                }
                .styleopencategorydetails{
                    box-sizing: border-box;
                    border:0.5px solid#ccf0ef ;
                    border-radius:12px ;
                    background-color: white;
                    
                    width:100%;
                    margin: auto;
                    height: 140px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    justify-items: center;
                 position: relative;
                    justify-content: center;
                
                  
                }
                .markandheart{
                    position: absolute;
                    right:92%;
                    top:0px;
                }
                [dir="ltr"] .markandheart{
                    position: absolute;
                    left: 92%;
                    top:0px;
                }
                .markandheartdetails{
                    position: absolute;
                    right:93%;
                    /* right:91%; */
                    top:0px;
                }
                [dir="ltr"] .markandheartdetails{
                    position: absolute;
                    left: 93%;
                    /* left: 91%; */
                    top:0px;
                }
                .markandheartMedical{
                    position: absolute;
                    /* right:91%; */
                    right: 0%;
                    top:0px;
                    z-index: 1;
                }
                [dir="ltr"] .markandheartMedical{
                    position: absolute;
                    left: 0%;
                    top:0px;
                }
                .markandheartBlog{
                    position: absolute;
                    right:84%;
                    top:0px;
                }
                [dir="ltr"] .markandheartBlog{
                    position: absolute;
                    left:84%;
                    top:0px;
                }
                .markandheartproduct{
                    position: absolute;
                    right:75%;
                    top:0px;
                    width: 50px;
                }
                [dir="ltr"] .markandheartproduct{
                    position: absolute;
                    left: 75%;
                    top:0px;
                    width: 50px;
                }
                .mark{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 3rem !important;
                  
                }
                .markdetails{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 2.5rem !important;
                  
                }
                .markproduct{
                    font-size: 3.5rem !important;
                    width: 2rem !important;
                    position: absolute;
                    color:#008C8D ;
                    height: auto !important;
                    object-fit: contain !important;
                  
                }
                [dir="ltr"] .markproduct{
                    font-size: 3.5rem !important;
                    width: 2rem !important;
                    position: absolute;
                    color:#008C8D ;
                    height: auto !important;
                    object-fit: contain !important;
                }
               .heart{
                    font-size: 2rem !important;
                    width: 2rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                   right: 4px;
                    top:4px;
                } 
               .heartdetails{
                    font-size: 2rem !important;
                    width: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                   right: 1px;
                    top:1px;
                } 
               .heartproduct{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                     right: 2px;
                    top:2px;
                    object-fit: contain;
                } 
                
                [dir="ltr"] .mark{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 3rem !important;
                    
                }
                [dir="ltr"] .markdetails{
                    font-size: 5rem !important;
                    position: absolute;
                    color:#008C8D ;
                    width: 2.5rem !important;
                    
                }
                [dir="ltr"] .markproduct{
                    font-size: 3.5rem !important;
                    width: 2rem !important;
                    position: absolute;
                    color:#008C8D ;
                    height: auto !important;
                    object-fit: contain !important;
                    
                }
                [dir="ltr"] .heart{
                    font-size: 2rem !important;
                    width: 2rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                 
                   left:4px; 
                    top:4px;
                }
                [dir="ltr"] .heartdetails{
                    font-size: 2rem !important;
                    width: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                 
                   left:1px; 
                    top:1px;
                }
                [dir="ltr"] .heartproduct{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                    object-fit: contain;
                   left:2px; 
                   /* left:2px; */
                    top:2px;
                }
                .heartblog{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                     right: 4px;
                    top:4px;
                    object-fit: contain;
                } 
                [dir="ltr"] .heartblog{
                    font-size: 1.5rem !important;
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    /* color:#d9245f; */
                    position: absolute;
                    object-fit: contain;
                   left:4px; 
                    top:4px;
                }
                .textminimun{
                    font-size: 1.2rem;
                    color: black;
                    font-weight: 600;
                    text-align: initial;
                }
                .searchs3{
                   
                    width: 100%;
                    height:100%;
                  
                  }
                .searchs31{
                   
                    width: 88%;
                    height:100%;
                  
                  }
                .threeimg{
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                     display: grid;
                     grid-template-columns: repeat(3,1fr);
                     gap: 1rem;
                     margin: auto;
                     justify-items: stretch;
                   
                 }
                .headerFAQ{
                    font-size: 2rem;
                    color:black;
                    
                    border-top:  3px solid #008C8D ;
                    border-bottom: 3px solid #008C8D ;
                    padding: 1% 0 1% 0;
                    width: 50%;
                
                }
                .imgsupport{
              
                    width:3rem !important;
                    height:3rem;
                 
                }
                .stylesCardDetailsPatient{
                    box-sizing: border-box;
                    box-shadow:2px 2px  5px 0px #bcbcbc;
                    border: none;
                    border-radius:4px ;
                    background-color: white;
                    margin: auto;
                    width: 60%;
                    padding: 0 0px;
                }
               
                .stylesCardBooking{
                    box-sizing: border-box;
                    box-shadow: 0 0 3px #bcbcbc;
                    border: none;
                    border-radius:12px ;
                    background-color: white;
                    margin: auto;
                    width: 60%;
                    padding: 60px 0px;
                }
                .stylesCalendar{
                    box-sizing: border-box;
                    box-shadow:2px 2px  5px 0px #bcbcbc;
                    border: none;
                    border-radius:4px ;
                    background-color: white;
                    margin: auto;
                    width: 60%;
                    padding: 10px;
                  /*   max-width: 550px; */
                }
                .Container{
                     padding: 0px 15px; 
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    max-width: 1060px !important;
                    /* max-width: 1220px; */
                }
                .ContainerDownload{
                    /* padding: 0px 40px;
                    max-width: 1500px; */
                    padding: 10px 10px;
                    max-width: 1060px;
                    margin: auto;
                }
               /*  .HakeemSection2{
                    padding: 50px 0 0 0; 
                   } */
                .SectionContainer{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: block;
                    padding:30px 10px;
                    max-width: 1060px;
                    /* max-width: 1220px; */
                
                }
                .SectionContainerDeleteAccount{
                    width: 100%;
                    margin-left:auto;
                    margin-right: auto;
                    box-sizing: border-box;
                    display: flex;
                  flex-direction: row;
                    padding:30px 10px;
                    max-width: 1060px;
                    /* max-width: 1220px; */
                
                
                }
                .div1DeleteAccount{
                    width: 50%;
                    max-width: 100%;
                 
                
                
                }
                .div2DeleteAccount{
                    width: 50%;
                    max-width: 100%;
                    margin: 20px;
                
                
                
                }
                .paddingComfirmation{
                    padding: 30px 10px !important;
                }
                .Effectstext{
                    font-size: large;
                    text-align: initial;
                }
              
                .Effects{
                    font-size: 1.5rem;
                    text-align: initial;
                }
                .priceBefore{
                    color:#F52A59;
                    text-align: initial;
                }
                .pricenow{
                    color:#008C8D ;
                    text-align: initial;
                }
                .pproducttext{
                    text-align: initial;
                    font-size: 1.2rem;
                    font-weight: 400;
                    margin:0;
                    /* padding: 23px 0; */
                    padding:10px 0px;
                }
                .pproducttext h3 span{
                    text-align: initial;
                    font-size: 1.2rem;
                    font-weight: 400;
                    margin:0;
                    
                    padding:10px 0px;
                    color:#F52A59 !important ;
                }
                .pproducttext ul li {
                    font-size: 0.9rem;
                    margin-bottom: 6px;
                }
                .pproducttext ul li p {
                    font-size: 0.8rem;
                    margin-bottom: 6px;
                }
                
                .pproducttext ul li p span{
                    color: #F52A59 !important;
                    font-size: 0.8rem !important;
                    font-weight: 600 !important;
                }
                .pproducttext ul li p strong{
                    font-size: 0.8rem !important;
                   
                }
                .pproducttexttop{
                    text-align: initial;
                    font-size: 1rem;
                    font-weight: 400;
                    margin: 0;
                }
                .nameProduct{
                    color:black;
                    text-align: initial;
                    font-weight: 600;
                    font-size: 1.2rem;
                }
                .RelatedProducts{
                    color:black;
                    text-align: initial;
                    font-weight: 600;
            
                }
                .myswiper{
                    box-sizing:border-box;
            /*         padding: 0px 6px !important; */
                    /* height: 20%; */
                    height: 440px;
                    margin-left: 0px !important;
                    margin-right:  0px !important;
                    width: 103px ;
                }
                .myswiper2{
                    width: 336px;
                    /* height: 600px; */
                    height: 450px;
                    margin-left: 0 !important;
                    margin-right:  0 !important;
                    
                }
                .headersupport{
                    color: white;
                    text-align: right;
                    direction: rtl;
                    font-size: 1rem;
                }
                [dir="ltr"] .headersupport{
                   
                    text-align: left;
                    direction: ltr;
              
                }
                .searchbox{
                    border-top-left-radius: 5% 50%;
                    border-bottom-left-radius: 5% 50%;
                    border-top-right-radius: 5% 50%;
                    border-bottom-right-radius: 5% 50%;
                  }
        
                
                form.searchboxhome input[type=text]{
                  padding:0px 10px;
                  border: none;
                  border-radius: inherit;
                  width: 90%;
                  font-size: 0.8rem;
                }
                form.searchboxhome input[type=text]:focus{
                  border:none;
                  outline: none;
                }
                form.searchboxhome .searchBtn{
                  padding: 15px 13px;
                  background: #f52a59;
                  border:none;
                  color: #fff;
                  cursor: pointer; 
                  border-radius: 50%;
                }
                form.searchbox input[type=text]{
                    padding: 10px 20px;
                    border: none;
                    border-radius: inherit;
                    width: 90%;
                    
                  }
                  form.searchbox input[type=text]:focus{
                    border:none;
                    outline: none;
                  }
                  form.searchbox .searchBtn{
                    padding: 15px 13px;
                    background: #f52a59;
                    border:none;
                    color: #fff;
                    cursor: pointer; 
                    border-radius: 50%;
                  }
                .Stack{
                    justify-content: center;
                    width: 100%;
                }
                .StackBook{
                    justify-content: center;
                    width: 100%;
                }
             
                .avatercard{
                    box-shadow:0px 0px 3px grey;
                    border-radius: 50%;
                    width:200px;
                    height:200px;
                 
                }
                .hLine7{
                    padding-left: 2% /* كان %3 */;
                    padding-top: 2%;
                    padding-right: 2% /* كان %3 */;
                    text-align: initial;
                    font-size: 1.2rem;
                    
                   }
        
                .sizeAvatar{
                    /* width:140px !important;
                   height:140px !important ;
                   box-shadow:0px 0px 6px grey;
                   border-radius: 50%; */
                   /* top: 0px; */
                   position: relative !important;
                   width: 150px !important;
                   height: 150px !important;
                   border-radius: 50% !important;
                   box-shadow: 0px 0px 3px grey;
                }
                .sizeAvatarWithoutborder{
                    width:300px !important;
                   height:300px !important ;
            
                }
                .sizeAvatarProfile{
                    width:100px !important;
                   height:100px !important ;
                   box-shadow:0px 0px 3px grey;
                   border-radius: 50%;
                }
                .sizeAvatarcard{
                    width:140px !important;
                   height:140px !important ;
                   box-shadow:0px 0px 3px grey;
                   border-radius: 50%;
                }
                .ProfileUser{
                    box-shadow:0px 0px 3px grey;
                    border-radius: 50%;
                }
                .sizestars{
                    font-size: 1.7rem !important;
                   width:200px !important; 
                   align-items: flex-start;
                   justify-content: flex-start;
                   
                }
                .sizestarscomment{
                    font-size: 1.7rem !important;
                   width:500px !important; 
                
                   align-items: center;
                   
                }
                .sizestarsproduct{
                    font-size: 2rem !important;
                   width:auto !important; 
                
                   align-items: center;
                   
                }
                .sizestarscard{
                    font-size: 1.7rem !important;
                   /*  width:200px !important; */
                
                   align-items: center;
                   
                }
                .h3sortby{
                    font-size: 1.5rem;
                    margin:0;
                }
                .animation1{
               height: 7rem;
                    width:7rem;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                
                   
                }
                .animation2{
                    height: 7rem;
                    width:7rem;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin:  auto;
                }
                .hrjsoin{
                    width:60%;
                height:0px ;
                border-width:3px ;
                margin-bottom:-90px;
                 /* align:center;  */
                color:#F52A59;
                background-color:#F52A59;
                opacity: 1 !important;
        }
            
    
                .yellowsquare{
                    box-sizing: border-box;
                    background-color: #faaf00;
                    border:0px solid #faaf00;
                    width:9%;
                    padding: 3px 0px 3px 0px;
                    position: absolute;
                    right:0%;
                    border-radius: 0px 0px 12px 0px;
                    bottom: 0;
                    font-size: 1rem;
                    
                }
                [dir="ltr"] .yellowsquare{
                    padding: 3px 0px 3px 0px;
                    left:0%;
                    border-radius: 0px 0px 0px 12px;   
                } 
                .yellowsquare2{
                    box-sizing: border-box;
                    background-color: #faaf00;
                    border:0px solid #faaf00;
                    width:6%;
                    padding: 0px 0px 3px 0px;
                    position: absolute;
                    /* right:94%; */
                    /* border-radius: 12px 0px 0px 0px; */
                    font-size: 1rem;
                    
                }
                [dir="ltr"] .yellowsquare2{
                    width:6%;
                    /* left: 94%; */
                    /* border-radius: 0px 12px 0px 0px;       */
                } 
    
             [dir="ltr"] .Statesquare{
                box-sizing: border-box;
                background-color: #faaf00;
                border:0px solid  #faaf00;
                width:13%;
                text-align: center;
                padding: 5px;
                position: absolute;
                left:87%;
                color: white;
                bottom: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 0px;
                    
                } 
                .Statesquare{
                    box-sizing: border-box;
                    background-color: #faaf00;
                  border:0px solid  #faaf00;
                    width:13%;
                    text-align: center;
                    padding: 5px;
                    position: absolute;
                    left:0;
                    color: white;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    
                }
                .BgButton{
                    background-color: #F52A59 !important;
                    border: 0 !important; 
                    color: white !important;
                }
                .buttonAddProduct{
                    box-sizing: border-box;
                background-color: white;
                border: 1px solid #bcbcbc;
                    width:100%;
                    text-align: center;
                    padding: 10px 30px;
                    font-size: 1.2rem;
                
                
                    color: black;
                    bottom: 0;
                    
                    
                }
    
                .Open{
                    box-sizing: border-box;
                    background-color: #008C8D ;
                    border:0px solid  #008C8D ;
                    width:13%;
                    text-align: center;
                    padding: 5px;
                    position: absolute;
                    left:0;
                    color: white;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    
                }
                [dir="ltr"] .Open{
                    left:77%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                        
                    } 
             [dir="ltr"] .Add{
                box-sizing: border-box;
                background-color: #008C8D ;
              border:0px solid  #008C8D ;
                width:13%;
                text-align: center;
                /* padding: 10px; */
                position: absolute;
                left:77%;
                color: white;
                bottom: 0;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                    
                } 
                .Add{
                    box-sizing: border-box;
                    background-color: #008C8D ;
                  border:0px solid  #008C8D ;
                    width:13%;
                    text-align: center;
                    /* padding: 10px; */
                    height: 45px; 
                    position: absolute;
                    left:0;
                    color: white;
                    bottom: 0;
                   /*  border-radius: 11px; */
                   border-bottom-left-radius: 12px;
                   border-bottom-right-radius: 0px;
                   border-top-left-radius: 0px;
                   border-top-right-radius: 0px;
                    
                }
            
              
             [dir="ltr"] .ClickAdd{
                box-sizing: border-box;
                background-color: white;
              border:0px solid  #008C8D ;
                width:13%;
                text-align: center;
                /* padding: 5px; */
                position: absolute;
                left:77%;
                color: black;
                bottom: 0;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 12px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border:0.5px solid #ccf0ef ;
                } 
                .ClickAdd{
                    box-sizing: border-box;
                    background-color: white;
                  border:0px solid  #008C8D ;
                    width:13%;
                    text-align: center;
                    /* padding: 5px; */
                    position: absolute;
                    left:0;
                    color: black;
                    bottom: 0;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border:0.5px solid #ccf0ef ;
                }
            
                .Opendetails{
                    box-sizing: border-box;
                    background-color: #008C8D ;
                  border:0px solid  #008C8D ;
                    width:13%;
                    text-align: center;
                    padding: 10px;
                  
                 margin: auto;
                    color: white;
                 
                    border-radius: 11px;
                    
                }
                .Closedetails{
                    box-sizing: border-box;
                    background-color: #F52A59;
                  border:0px solid  #F52A59;
                    width:13%;
                    text-align: center;
                    padding: 10px;
                  
                 margin: auto;
                    color: white;
                 
                    border-radius: 11px;
                }
                .Closedetails{
                    box-sizing: border-box;
                    background-color: #F52A59;
                  border:0px solid  #F52A59;
                    width:13%;
                    text-align: center;
                    padding: 10px;
                  
                 margin: auto;
                    color: white;
                 
                    border-radius: 11px;
                }
                .ComfirmationButton{
                    box-sizing: border-box;
                    background-color: #F52A59;
                    border:0px solid  #ccc5c8;
                    width:100%;
                    text-align: center !important;
                    /* padding: 16.5px 10px !important; */
                    padding: 6px 12px !important;
                    margin: auto;
                    color: white !important ;
                 
                    border-radius: 6px;
                    
                }
    
                .Close{
                    box-sizing: border-box;
                    background-color:#F52A59;
                    border:0px solid  #F52A59;
                    width:13%;
                    text-align: center;
                    padding: 5px;
                    position: absolute;
                    left:0;
                    color: white;
                    font-size: 1rem;
                    bottom: 0;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    
                }
                [dir="ltr"] .Close{
                    left:77%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;   
                    } 
                
                [dir="ltr"] .PinkNameSquare{
                    box-sizing: border-box;
                    background-color: #F52A59;
                    border-right-color: #008C8D ;
                    border-left-color:  #F52A59;
                    width:12%;
                    padding: 0px 0px 10px 0px;
                    position: absolute;
                    left:20%;
                    border-radius:0px;
                font-size: 20px;
                    
                } 
                .PinkNameSquare{
                    box-sizing: border-box;
                    background-color: #F52A59;
                  border:4px solid #F52A59;
                  border-left-color: #008C8D ;
                  border-right-color: #F52A59;
                    width:12%;
                    padding: 0px 0px 10px 0px;
                    position: absolute;
                    right:20%;
                    top: 0px;
                    border-radius:0px;
                font-size: 20px;
                    
                }
                .Preview{
                    width:59.5%;
                  font-size: 1.2rem;
                    padding: 12px;
                 
             margin: 0 20%;
                color: #008C8D ;
            font-weight: 600;
                   box-shadow:0 0 3px #bcbcbc ;
                }
                
                .labelPreview{
                    padding: 10px;
                    margin: 0;
                }
                .checkUseinfo{
                    color: tomato;
                }
            /*     .checkUseinfo{
                    position: absolute;
                    top:0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                }
                .contaninercheckbox :hover input ~ .checkUseinfo{
                    background-color: #ccc;
                }
                .contaninercheckbox input:checked ~ .checkUseinfo{
                    background-color: teal;
                }
                .checkUseinfo::after{
                    content: "";
                    position: absolute;
                    display: none;
                }
                .contaninercheckbox input:checked ~ .checkUseinfo::after{
                    display: block;
                }
                .contaninercheckbox .checkUseinfo::after{
                    left: 9px;
                    top:5px;
                    width: 5px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform:rotate(45deg);
                } */
                .backgroundDiscover{
                /*     background-image: url("/assest/Mask Group 111.svg");  */
                   background-position: center;
                   background-repeat: no-repeat;
                   background-size: cover;
                   height: 100%;
                   margin: 2px ;
                   background-color: white;
                   background-position: initial;
                   max-width: 1500px;
                   margin: auto;
                 
               }
                .formandbutton{
                    grid-template-columns: repeat(1,1fr);
                    align-items: center;
                }
                .select{
                    border: none;
                    font-size: 20px;
                    width: auto;
                  
                    }
                .cardHakeemMedical{
            
                    align-items: center;
                   /* padding: 100px; */
                   
                 }
               .HakeemMedicaltext{
                   /* padding-bottom:100px; */
               }
                
                .HeaderMadicalHakeem{
                    text-align: center;
                        padding-top: 1%;
                        color:#008C8D  ;
                      font-size: 2.5rem;
                       
                }
                .HeaderLogin{
                   
                    
                    color:#008C8D  ;
                  font-size: 2rem;
                  font-weight: 600;
                }
                .accountcreation{
                   
                
                    text-decoration: none;
                    color:#bcbcbc;
                }
                .forgetPassword{
                    text-decoration-line: none;
                    padding-top: 10px;
                    padding-left: 6px;
                    padding-right: 6px;
                
                }
                .parforgetPassword{
                    text-decoration-line: none;
                    padding-top: 10px;
                    padding-left: 6px;
                    padding-right: 6px;
                
                }
                
                .HeaderMadicalHakeem2{
                    text-align: center;
                    padding-top: 1%;
                    color:#008C8D  ;
                  font-size: 3rem;
                }
                .HeaderMadicalHakeem3{
                    text-align: center;
                    padding-top: 1%;
                    color:black ;
                  font-size: 2rem;
                }
               
                .HeaderMadicalHakeem4{
                    text-align: center;
                    padding-top: 1%;
                    color:black ;
                  font-size: 1.5rem;
                }
               
                
            
            .form{
                grid-template-columns: repeat(3,1fr);
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                    border-radius: 28px;
                 padding-top:50px;
                 width: 74%;
                  display: grid;
                  padding-bottom: 50px;
                  align-items: center;
                  gap: 1rem;
            }
            .formSearch{
                grid-template-columns:100%;
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                border-radius: 12px;
             /*     padding-top:35px; */
                width:100%;
                display: grid;
            /*       padding-bottom: 35px; */
                align-items: center;
                gap: 0rem;
                max-width: 1060px;
            }
            .formUp{
                grid-template-columns:100%;
                background-color: white;
                gap: 1rem;
                text-align: center;
                width:100%;
                display: grid;
                align-items: center;
                max-width: 1060px;
            }
            .formdown{
                grid-template-columns: 100%;
                background-color: white;
                text-align: center;
                gap: 1rem;
                padding-top:10px;
                width:100%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                max-width: 1060px;
            }
            .StarDiet{
                stroke:#008C8D ;
                stroke-width:1;
                color:#F52A59
            }
            .StarBorderDiet{
                stroke-width:1;
                stroke:#008C8D ;
                color:#008C8D 
            }
            .formDietSearch{
                grid-template-columns: repeat(1,1fr);
                background-color: white;
                box-shadow: 0 0 3px #bcbcbc;
                text-align: center;
                border-radius: 12px;
                /*  padding-top:35px; */
                width:100%;
                display: grid;
            /*       padding-bottom: 35px; */
                align-items: center;
                gap: 1rem;
        
            }
            
            .DietSearch{
                grid-template-columns: 30% 40%  30%;
                background-color: white;
                text-align: center;
                border-radius: 28px;
                padding-top:10px;
                width:92%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                margin: auto;
              
            }
            .productSearch{
                grid-template-columns: 50% 50%;
                background-color: white;
                text-align: center;
                border-radius: 28px;
                padding-top:10px;
                width:92%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                margin: auto;
              
            }
            .proName{
                grid-template-columns: 100%;
                background-color: white;
                text-align: center;
                border-radius: 28px;
                padding-top: 10px;
                width: 92%;
                display: grid;
                padding-bottom: 10px;
                align-items: center;
                margin: auto;
    
            }
            .label{
                background-color:white;
              flex: 0.4;
              
                border: 0;
            
                outline: none;
               
                font-size: 14px;
                font-weight: 600;
                color: grey;
                margin: 0;
              
            }
            .searchbtn{  
                 padding: 5px 10px;
                 color: white;
                 background-color:#F52A59;
                 border: none;
                 border-radius: 10px;
                 font-size: 1.1rem;
                 font-weight: 100;
                 width: 20%;
                 height: auto;
             
                 z-index: 1;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
                 margin-top: -1%;}
            .SeeMore{
            
             padding: 7px !important;
                color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 10px;
                 font-size: 1.1rem;
            
               width: 100%;
            
               height: auto;
        
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            }
            .widthPrice{
                width: 61%;
            }
            .change{
            
                 padding: 5px 10px;
                 color: white;
                 background-color:#008C8D ;
                 border: none;
                 border-radius: 0px;
                 font-size: 1.2rem;
                 width: 100%;
                 height: 53px;
                 z-index: 2;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-left: auto;
                 margin-right: auto;
            }
            .Buy{
            
                padding: 5px 10px;
                   color: white;
                    background-color:#008C8D ;
                    border: none;
                    border-radius: 10px;
                    font-size: 1rem;
               
                  width: 22%;
               display: flex;
                  height: auto;
                  font-size:25px;
               z-index: 2;
              
               }
                .cards{
                    grid-template-columns: repeat(2,1fr);
                    -ms-grid-columns: repeat(2,1fr);
                }
                
                .stars{
                    grid-template-columns: repeat(1,1fr);
                }
                .Person{
                    width:1.2rem;
                  }
                  .view{
                   /* width:1.5rem; */
                   width: 100%;
                   margin: auto;
                  }
                  .viewmedical{
                   width:1.5rem;
                   height:1.5rem;
                 
                   margin: auto;
                  }
                  .icontitle{
                   width:1.5rem;
                   height: 1.5rem;
                  }
                  .icontContact{
                   width:5rem;
                   height: 5rem;
                  }
                  .icontFAQ{
                   width:6rem;
                   height: 8rem;
                  }
                  .iconBasketTab{
                   width:4rem;
                   height: 4rem;  
                    font-size: 3rem !important;
                  }
                  .iconBlog{
                   width:2.5rem;
                   height: 2.5rem;  
                    font-size: 3rem !important;
                  }
                  .iconBasketMain{
                   width:2.5rem;
                   height: 2.5rem;  
                    font-size: 3rem !important;
                  }
                  .icondollar{
                   width:2rem;
                   height: 4rem;
                  }
                  .iconPhone{
                   width:1.5rem !important;
                   height: 1.5rem !important;
                  }
                  .iconBasket{
                   width:1.2rem !important;
                   height:1.2rem !important;
                         object-fit: contain !important;
                  }
                  .iconremoveBasket{
                   width:1.5rem !important;
                   height:1.5rem !important;
                   object-fit: contain !important;
                  }
                  
                .personandstar{
                    grid-template-columns: repeat(1,1fr);
                    gap:0.5rem;
                }
                .StarandDistance{
                    grid-template-columns: 33.3% 33.3% 33.3%;
                    gap:0rem;
                }
                .cardssection3{
                    grid-template-columns: auto auto;
                  width: 60%;
                  margin: auto;
            
                }
                .personandview{
                    grid-template-columns: auto auto ;
                    gap: 1rem;
                    justify-content: initial;
                    width: 100%;
                }
                .positionStarMedical{
                    display: grid;
                    /* width: 122%; */
                    justify-items: flex-start;
                }
                .cardssection5{
                    grid-template-columns: repeat(3,1fr);
            
                }
                .icontext{
                    font-size: 1rem;
                  color: black;
                }
                .cardssection6{
                    /* grid-template-columns: repeat(5,1fr); */
                    grid-template-columns: repeat(4,1fr);
                    gap: 0rem;
            
                }
                .cardsJsonMedical{
                    grid-template-columns: repeat(7,1fr);
            
                }
                .cardssection7{
                    
                    padding: 20px ;
                    grid-template-columns: repeat(5,1fr) ;
                    gap: 0.5rem;
                    
                }
                .ContactDiv{
                    
                    grid-template-columns: repeat(2,1fr) ;
                    gap: 2rem;
            
                }
                .orderprice{
                    
                    /* grid-template-columns: 89% 9% ; */
                    gap: 1rem;
            
                }
                .typetransport{
                    
                    /* grid-template-columns: 73% 25% ; */
                    gap: 1rem;
                    justify-items:initial;
            
                }
                .AddressandChangebtndiv{
                    
                    grid-template-columns: 72% 25% ;
                    gap: 2rem;
            
                }
                .OrAndBorder{
                    
                    grid-template-columns: 45% 10% 45% ;
                    gap: 0rem;
            
                }
                .imgandName{
                    
                    grid-template-columns: 17% 71% ;
                    gap: 1rem;
            
                }
                .ComfirmationTop{
                    
                    grid-template-columns: 12% 41% 43%;
                    gap: 1rem;
                    justify-items: initial;
            
                }
                .QuantityandOrderandPriceDiv{
                    
                    grid-template-columns:  41% 12% 43%;
                    gap: 1rem;
                    justify-items: initial;
                    padding: 20px 20px;
            
                }
                .QuantityandOrderandPriceDivDialog{
                    padding: 20px;
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 1rem;
                    justify-items: initial;
            
                }
                .ThreeDivproduct{
                    
                    grid-template-columns: 12% 55% 18%;
                    /* grid-template-columns: 12% 62% 18%; */
                    gap: 1rem;
                    justify-items: start;
            
                }
                .ThreeButtonProduct{
                    
                    grid-template-columns:93%; 
                    gap:0.5rem;
                    width: 80%;
                    margin: auto;
                    padding-top: 25px;
                    justify-content: start;
                    align-items: center;
                    justify-items: start;
            
                }
                .TwoButtonProduct{
                    
                 
                    gap:1rem;
                    width:79%;
                    margin: auto;
            
                }
                .ShowthreeProduct{
                    
                    grid-template-columns: 32.6% 32.6% 32.6% ;
                    /* grid-template-columns: 32.3% 32.3% 32.3% ; */
                    gap: 0.5rem;
            
                 
            
                }
                .ShowOffer{
                    
                    grid-template-columns:repeat(4,1fr) ;
                    gap: 1rem;
            
                 
            
                }
                .TimeandDistancesandPrice{
                    justify-items:initial;
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 2rem;
            
                }
                .DivCount{
                    
                    grid-template-columns: repeat(3,1fr) ;
                  /*   gap: 2rem; */
                  height: 45px;   
               
            
                }
                .heightOfferbtn{
                    height: 45px;   
            
                }
                .CardandBasket{
                    
                    grid-template-columns:  31.4% 67%;
                    /* grid-template-columns:  30% 67%; */
                   gap: 1rem; 
            
                }
                .lineprogressandName{
                    
                    grid-template-columns:  18% 80%;
                   gap: 1rem; 
            
                }
                .lineprogressBook{
                    
                    grid-template-columns: 100%;
                   gap: 1rem; 
            
                }
                .paddingProgress{
                    padding: 40px !important;
                }
                .Twotextprice{
                    text-align: initial;
                    grid-template-columns: 15% 15% 60% ;
                    gap: 1rem;
                     
                    flex-direction: row;
            
                }
                .TwoPriceDialog{
                    text-align: initial;
                    gap: 1rem;
                    flex-direction: row;
            
                }
                .TwotextpriceProduct{
                    text-align: initial;
                    grid-template-columns: 100%;
                    gap: 0rem;
                    flex-direction: row;
            
                }
                .PriceOffer{
                    text-align: initial;
                    grid-template-columns: repeat(1,1fr);
                    gap: 0rem;
            
                }
                .TwotextpriceProduct2{
                    text-align: initial;
                    /* grid-template-columns: 50% 50% ; */
                   /*  gap: 1rem; */
                   /* bottom: 57px; */
                   bottom: 106px;
                  left:3px;
               
                   position: absolute;
            
                }
                [dir="ltr"] .TwotextpriceProduct2{
                    left:143px;
                }
                .TabsCard{
                    
                    grid-template-columns: repeat(3,1fr) ;
                    gap: 2rem;
            
                }
                .phoneSquare{
                    
                    grid-template-columns: 10% 50% ;
            
                    gap: 1rem; 
                    text-align: initial;
            
                }
                .phoneSquarediet{
                    
                    /* grid-template-columns: 5%  40% 55% ; */
                    padding: 0;
                    grid-template-columns: 3% 42% 55%;
                  /*   gap: 1rem;  */
                    text-align: initial;
                    gap: 0;
                }
                .Twoelereviews{
                    
                    grid-template-columns: 20% 26% ;
                    gap: 1rem;
                    /* gap: 18rem;  */
                    text-align: initial;
            
                }
                .ThreeCalendar{
                    
                    grid-template-columns: repeat(3,1fr);
                    gap: 1rem;
            
                }
               
                .textandsocial{
                    
                    grid-template-columns: repeat(1,1fr);
                    text-align: initial;
                }
                .textfooter{
                    
                    grid-template-columns: repeat(1,1fr);
                    text-align: initial;
                }
                .cardsDietscard{
                    
                    grid-template-columns: repeat(4,1fr);  text-align: center;
                    
                    padding-top:20px;
               
                      display: grid;
                      padding-bottom: 20px;
                      gap: 5rem;
                 
            
                }
                .cardsmedicianorder{
                    
                    grid-template-columns: repeat(4,1fr);  text-align: center;
                    
                    padding-top:20px;
               
                      display: grid;
                      padding-bottom: 20px;
                      gap: 1rem;
                      justify-items: start;
                 
            
                }
             
                .QuestionandPhotocard{
                    
                    grid-template-columns: repeat(2,1fr);
            
                }
                .cardsTime{
                    
                    grid-template-columns: repeat(4,1fr);
                        gap: 1rem;
                }
                .PreviewForm{
                    grid-auto-columns: 100%;
                }
                .cardinfo{
                    grid-template-columns:100%;
                    gap:0rem;
                }
                .cardsRelatedProducts{
                    grid-template-columns: repeat(4,1fr);
                    gap: 1rem;
                }
                .cardsOfferProducts{
                    grid-template-columns: repeat(2,1fr);
                    gap: 2rem;
                }
                .productphotofour{
                    grid-template-columns:49px 49px 49px 49px;
                    gap: 1rem;
                }
                .Viewproduct{
                    grid-template-columns:  repeat(2,1fr);
                    gap: 2rem;
                    padding-bottom: 20px; 
                }
                .productphoto{
                    grid-template-columns: repeat(1,1fr);
                }
                .cardLogin{
                    grid-template-columns: auto 50%; 
                }
                .cardTradeMarks{
                    grid-template-columns: repeat(6,1fr);
            
                }
                .cardProducts{
                    grid-template-columns: repeat(8,1fr);
                }
                .topthree{
                
                    
                        grid-template-columns: repeat(3,1fr);
                
                   
                }
                .cardssocail{
               /*      grid-template-columns: repeat(5,1fr); */
                    gap: 1rem;
                }
                .cardssection8{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .cardssection10{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .cardssection11{
                  
                    grid-template-columns: repeat(5,1fr);
            
                }
                .downloadsection{
                    grid-template-columns: repeat(2,1fr);
            
                }
                .cardsDiscover{
                    grid-template-columns: repeat(3,1fr);
                 gap: 3rem;
                }
                .cardspost{
                    grid-template-columns: repeat(1,1fr);
                }
                    .footer{
                        grid-template-columns: 30% 20% 20% 20%;
                        padding-top: 1%;
                        padding-bottom:1% ;
                        justify-items: center;
        
                    }
                    .SquareFooter{
                        /* grid-template-columns: 23% 61%; */
                        grid-template-columns: 44% 53%;
                    }
                    .cardpost{
                        grid-template-columns: repeat(2,1fr);
                    }
                    .btnhakeemapp{
                        grid-template-columns: repeat(2,1fr);
                        text-align: center;
                        margin-top:10% ;
                        min-width: 40px;
                        margin:  auto;
                        display: grid;
                        gap:1rem;
                    
                        padding: 6px 10px 6px 0px;
                        
                        display: inline-flex;;
                        color: grey;
                         background-color:white;
                         box-shadow: 0 0 3px #bcbcbc;
                         border: none;
                         border-radius: 10px;
                         justify-items: center;
                         align-items: center;
                        cursor: pointer;
                        width: 165px;
                    }
                    [dir="ltr"] .btnhakeemapp{
           
                        padding: 6px 0px 6px 10px;
                       
                        
                      
                     
                    }
                   .cardsquare{
                        grid-template-columns: repeat(2,1fr);
                        order:1;
                    }
                    .cardprofile{
                        grid-template-columns: repeat(3,1fr);
                        order:1;
                    }
                    .cardSupoort{
                        grid-template-columns:85% 13%;
                       
                    }
                    .cardSupoort .cards1{
                        order:-1;
                    }
                    .Download{
                        grid-template-columns: 188px 203px;
                       order:0;
                       gap:0;
                      
                    }
                    .DownloadHeader{
                        grid-template-columns: 100%;
                       order:0;
                       gap:0.5rem;
                      
                    }
                    .cardsanimation{
                        grid-template-columns: repeat(1,1fr);  
                    }
                    .textaboutdoctor1{
                        font-size: 1.3rem;
              /*         margin-bottom: 0;  */
                        line-height: 1.5em;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                    .TextBasket1{
                        font-size: 2em;
                  /*     margin-bottom: 0;  */
                        line-height: 1.5em;
                        padding-left: 8px;
                        padding-right: 8px;
                        text-align: initial;
                    }
                    .Text{
                        font-size: 2em;
                  /*     margin-bottom: 0;  */
                        line-height: 1.5em;
                      
                        text-align: initial;
                    }
                    .textaboutresturant1{
                        /* font-size: 2em; */
                        font-size: 1.2rem;
                 /*        margin-bottom: 0; */
                        line-height: 1.5em;
                    }
                    .textaboutdoctor1card{
                        font-size: 1.5rem;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        color: #008C8D ;
                        font-weight: 600;
                    }
                    .textaboutdoctor2{
                        font-size: 1.2rem !important;
                       /*  margin-bottom: 0; */
                        /* line-height: 1.5em; */
                        padding-left: 8px;
                        padding-right: 8px;
                        margin-bottom: 8px;
                        text-align: initial;
                    }
                    .textaboutdoctor4{
                        font-size: 0.8rem !important;
                       /*  margin-bottom: 0; */
                        /* line-height: 1.5em; */
                        padding-left: 8px;
                        padding-right: 8px;
                        margin-bottom: 8px;
                        text-align: initial;
                    }
                    .TextBasket2{
                        font-size: 1.5em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        padding-left: 8px;
                        padding-right: 8px;
                        text-align: initial;
                    }
                    .fontNum{
                        font-size: 1.2rem;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: right;
                    }
                    .fontNum1{
                        font-size: 1.2rem;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: start;
                    }
                    [dir="ltr"] .fontNum{
                    
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: left;
                    }
                    [dir="ltr"] .fontNum1{
                        font-size: 1.5em;
                        margin-bottom: 0;
                        line-height: 1.5em;
                        direction: ltr;
                        text-align: end;
                    }
                    .textaboutdoctor2card{
                        font-size: 1.2rem;
                       margin-bottom: 0; 
                        line-height: 1.5em;
                    }
                    .textaboutdoctor3{
                        font-size: 1rem !important;
                        margin-bottom: 0;
                        padding-left: 8px;
                        padding-right: 8px;
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                    .TextBasket3{
                        font-size: 1.3em;
                        margin-bottom: 0;
                        padding-left: 8px;
                        padding-right: 8px;
                        text-align: initial;
                    }
                    .textaboutdoctor3card{
                        font-size: 1em;
                        margin-bottom: 0;
                    }
                    .starimg{
                        width: 2em;
                    }
                    .BackgroundHakeemMedical{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: initial;
                       max-width: 1500px;
                       /* max-width: 1225px; */
                       margin: auto;
                    
                      
                    }
                    .BackgroundDiet{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                    
                      
                    }
                    .BackgroundGreySquare{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                   
                      
                    }
                    .BackgroundGreySquareAbout{
                       /*  background-image: url("/public/assest/BackgroundMedical.svg"); */
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        height: 100%;
                        margin: 0px ;
                     
                        background-position: center;
                       max-width: 1500px;
                       margin: auto;
                   
                      
                    }
                   
                    
                 .btnImg{
                    width: 100%;
                top:22%;
                font-size: 1.5em !important;
                color: #F52A59;
                padding: 10px;
                background-Color:white; 
               
                border-radius:50% ;
                }
                .btnImg1{
                    width: 50%;
                  /* padding-top:-15%; */
                  font-size: 1em !important;
                  color:#F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
            
                }
                [dir="left"].btnImg1{
                    width: 50%;
                  /* padding-top:-15%; */
                  padding-left:20px;
                }
                .Arrowleft{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 0.8em !important;
                  color:#F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
            
                }
                [dir="left"].Arrowleft{
                    width: 50%;
                  padding-top:-15%;
                  padding-left:20px;
                }
                .ArrowScroll{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 0.7em !important;
                  color:#F52A59;
                  padding: 5px;
             
                 
                  border-radius:50% ;
            
                }
                [dir="left"].ArrowScroll{
                    width: 50%;
                  padding-top:-15%;
                  padding-left:20px;
                }
                .btnImg2{
                  width: 50%;
                  font-size: 1em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                  border-radius:50% ;
                }
                .ArrowServices{
                  width: 50%;
                  font-size: 2.5em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                  border-radius:50% ;
                }
                .Arrowright{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 0.8em !important;
                  color: #F52A59;
                  padding: 5px;
                  background-Color:white; 
                 
                  border-radius:50% ;
                
                  
                }
                .ArrowScroll2{
                    width: 50%;
                  padding-top:-15%;
                  font-size: 0.5em !important;
                  color: #F52A59;
                  padding: 5px;
                 
                 
                  border-radius:50% ;
                
                  
                }
               .imgnextstyle{
                margin:auto;
                display: block;
                width: 100%;
                background-color: white;
                height:73px;
                border-radius: 50%;
                padding: 20px;
                }
                .searchbox{
                    width: 100%;
                   height: auto;
                    background-color:white;
                    display: flex;
                    align-items: center;
                    border-radius: 60px;
            
                    margin-left: auto;
                    margin-right: auto;
                    padding: 2px 4px; 
                    box-shadow: 0 0 3px #bcbcbc;
                   }
                   .search{
                   background-color:white;
                   width: 70%;
                   flex: 1;
                   border: 0;
                   width: 50%;
                   outline: none;
                   padding-left: 30px;
                   padding-right: 30px;
                   font-size: 20px;
                   color: grey;
                   border-radius: 60px;
                   }
                   .searchhome{
                   background-color:white;
                   width: 100%;
                   flex: 1;
                   border: 0;
                  
                   outline: none;
                   padding-left: 60px;
                   padding-right: 60px;
                   font-size: 20px;
                   color: grey;
                   border-radius: 60px;
                   }
                   .searchtext{
                    background-color:white;
                    width: 100%;
                    flex: 1;
                    border: 0;
                    text-align: center;
                    outline: none;
                   
                    font-size: 20px;
                    color: grey;
                    border-radius: 60px;
                    }
                   .imgsearchhome{
                   width: 1.4rem;
                   height: 1.4rem;
                   
                  
                   }
                   .imgsearch{
                    width: 3rem;
                    
                   
                    }
                   .btnsearchhome{
                       border: 0;
                      border-radius: 50%;
                       width: 1.2rem;
                       background-color: white;
                   }
                   .btnsearch{
                       border: 0;
                      border-radius: 50%;
                       width: 3rem;
                       background-color: white;
                   }
                   .searchs6 {
                    width: 5rem;
                    height:  5rem;
                   
                   
                
                }
                   .searchs6Examination {
                    width: 6rem;
                    height: 6rem;
                    margin: auto;
                    padding:10% ;
                   
                
                }
                .searchsimg {
                    width: 10rem;
                    height: 130px;
                    padding-Top:10% ;
                   
                
                }
                .threeimg1{
                    align-items: center;
                    justify-content: center;
                    display: grid;
                    /* grid-template-columns: 30% 70%; */
                    grid-template-columns: 26% 73%;
                    gap: 0;
                    width: 100%;
                   
                 
                }
                .cardsBlog{
                    
                    grid-template-columns:32.2% 32.2% 32.2%;
                    gap:1rem;
            
                }
                .cardsdevices{
                    
                    grid-template-columns: repeat(3,1fr);
                    gap:5rem;
            
                }
                .twoimg{
                  
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                     display: flex;
                     padding:20px 20px 0px 20px ;
                   /*   grid-template-columns:72% 8%; */
                     gap:0rem;
                     margin: auto;
                    
                 
                }
                .Calendarcard{
                  
                    display: grid;
                    grid-template-columns:100%;
                  
                   
                
               }
                .subCalendarcard{
                  
                    display: grid;
                    grid-template-columns:16% 84%;
                  
                   
                
               }
                .cardmedical{
                    display: grid;
                    grid-template-columns:60% 40%;
                    gap: 0rem;
                    padding: 0  20px;
                    width: 100%; 
                    height: 290px;
                    padding: 0% 0% 1% 1%;
                }
                .cardAddress{
                    display: grid;
                    grid-template-columns:60% 40%;
                    gap: 0rem;
                    padding: 0  20px;
                    width: 100%; 
                    height: 200px;
                    padding: 0% 0% 1% 1%;
                }
                [dir="ltr"] .cardmedical{
                    padding: 0% 1% 1% 0%;
                }
                .cardBooking{
                    display: grid;
                    grid-template-columns:100%;
                    gap: 0rem;
                    /* padding: 5%; */
                    width: 100%;    
                }
                .comment{
                     display: flex;
                     grid-template-columns: 75% 83%;
                     margin: auto;
                     gap: 44.4rem;
                
                   
                   
                }
                .Detailprofile{
                    grid-template-columns:30% 34% 43%;
                
                }
                .profileandtextcard{
                    
                    text-align: initial;
                  gap: 0.5rem;
                    width: 100%;
                    grid-template-columns:27% 73%;
                }
                .profileandtextcardmedical{
                    
                    text-align: initial;
                    gap: 0.5rem;
                    width: 100%;
                    grid-template-columns:32% 66%;
                }
                .part1{
                    text-align: initial;
                    gap: 1rem;
                    width: 100%;
                    grid-template-columns: 100%;
                    gap:0.5rem;
                    justify-items: center;
                }
                .profileandtextcardfood{
                    
                    text-align: initial;
                  
                    width: 100%;
                    grid-template-columns: 28% 70%;
                    gap: 1rem;
                }
                .BasketTextandButton{
                    
                    text-align: initial;
                  padding: 10px;
                    width: 100%;
                    grid-template-columns: 70% 30%;
                }
                .Orderprice{
                    
                    text-align: initial;
                    padding: 0 10px;
                    width: 100%;
                    grid-template-columns:repeat(2,1fr);
                }
                .profileandtextcardDetails{
                    
                    text-align: center;
                  
                    width: 100%;
                    grid-template-columns: 100%;
                    gap: 0.5rem;
                }
                .iconandtext{
                    grid-template-columns:20% 20%;
                    gap: 0.4rem;
                    align-self: center;
                    width:19%;
                    justify-content: initial;
                    
                }
                .Detailcommentright{
                    grid-template-columns: repeat(2,1fr);
                }
                .Detailcommentleft{
                    grid-template-columns: repeat(2,1fr);
                }
                .doctorName{
                    font-size: 1.2rem ;
                    /* text-align: center; */
                    text-align: initial;
                    margin: 0;
                    font-weight: 600;
                    width: 100%;
                }
                .tagWord{
                    font-size: 1.3em ;
                    text-align: initial;
                    margin: 0;
                    font-weight: 600;
                    padding: 0 74px;
                }
                .ArticalTitle{
                    font-size: 1.5rem;
                    padding-left: 7%;
                    padding-top: 4%;
                 padding-right: 7%;
                 font-weight: 600; 
                }
                .profileimg{
                    width: 4rem;
                }
                .doctorprofileimg{
                    width: 14rem;
                }
                .dot{
                    width: 5rem;
                }
                .Commenttitle{
                    padding-right: 10px  !important;
                    font-size: 1.3em  !important;
                    text-align: initial  !important;
                    margin: 0  !important;
                    font-weight: 600  !important;
                }
                .loveimg{
                    width: 1rem;
                }
            
            }
            @media(min-width:1024px){
                .postitionCu{
                    border-radius: 50%;
                    top: -43px;
                    position: relative;
                    width: 1rem;
                    height: 1rem;
                    z-index: -10;
                    text-align: center;
                    left: 0px;
                }
                [dir="ltr"] .postitionCu{
                    left: 0px;
                }
                .track1{
                    top:15%
                }
                .trackImg1{
                    width:100px;
                    height: 100px;
                }
                .track2{
                    top:41.4% 
                }
                .track3{
                    top:65.6% 
                }  
                .track4{
                    top:90.4% 
                }    
            .textorder{
        
                position: absolute;
                width: 27%;
                text-align: end;
                top: 39%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 91% ;
                text-align: end;
                top: 20%;
            
            }
            }
            @media(min-width:1024px) and (max-height:600px){
                .track1{
                    top:14%
                }
                .trackImg1{
                    width:50px;
                    height: 50px;
                }
                .track2{
                    top:40% 
                }
                .track3{
                    top:64.6% 
                }
                .track4{
                    top:89% 
                }           
            .textorder{
        
                position: absolute;
                width: 39%;
                text-align: end;
                top: 20%;
            
            }
            .textorder2{
        
                position: absolute;
                width: 81% ;
                text-align: end;
                top: 20%;
            
            }
        
            }
            @media(min-width:1280px) and (min-height:800px){
    
                .trackImg1{
                    width:60px  !important;
                    height: 60px  !important;
                }
                
                .textorder{
        
                    position: absolute;
                    width: 36% !important;
                    text-align: end;
                    top: 20%;
                
                }
                .textorder2{
        
                    position: absolute;
                    width: 81% !important;
                    text-align: end;
                    top: 20%;
                
                }
            }
            @media (min-width:1280px){
             .ImgDownload{
                left: -41%;
                overflow: hidden;
                position: relative;
                bottom: -114%;
                position: absolute;
                z-index: 0;
                width: 60%;
             }
                .textorder{
        
                    position: absolute;
                    width: 39%;
                    text-align: end;
                    top: 20%;
                
                }
                .textorder2{
        
                    position: absolute;
                    width: 78%;
                    text-align: end;
                    top: 20%;
                
                }
                .track1{
                    top:14.6% 
                }
                .trackImg1{
                    width:50px;
                    height: 50px;
                }
                .track2{
                    top:40.6% 
                }
                .track3{
                    top:65% 
                }
                .track4{
                    top:89.4% 
                } 
    
                .ColorSquareFooter{
                    background-color:#F52A59;
                    padding:30px 0px ;
                } 
                .SquareFooter{
                    align-items: center;
                    justify-content: center;
            
                    text-align: center;
                     max-width: 1500px;
                       margin: 0% auto;
                     display: grid;
                     gap:2rem; 
                     z-index: 1;
                   /* padding:  4px 0px;  */
                   padding: 10px 0px;
                
                   width:100%;
                   position: relative; 
              
                   /* top: -37px; */
                   top: -37px;
                  left: 0px;
                   right:0px; 
                   justify-items: initial;}  
            } 
            @media (min-width:1241px){
                .clickSignin{
                    font-size: 0.9rem;
                }
                .BgCategory{
                    top: 68px;
                    height: 50px;
                }
                .imgBlog{
                    width: 325px !important;
                    height: 250px !important;
                }
                .imgBlogSlider{
                    width: 319px !important;
                    height: 230px !important;
                }
                .postitionCu{
                    border-radius: 50%;
                    top: -43px;
                    position: relative;
                    width: 1rem;
                    height: 1rem;
                    z-index: -10;
                    text-align: center;
                    left: 0px;
                }
                [dir="ltr"] .postitionCu{
                    left: 0px;
                }
                .selectCountry{
                    width: 1rem;
                    border-radius: 50%;
                }
                .btnsearchhome{
                    border: 0;
                   border-radius: 50%;
                    width: 1.5rem;
                    background-color: white;
                }
                .signIn{
                    padding: 4px 8px;
                     color: white;
                      background-color:#008C8D ;
                      border: none;
                      border-radius: 7px;
                      font-size: 0.8rem;
                  font-weight: 600;
                    /* width: 100%; */
                    width: 109.52px;
                    height: auto;
                   
                  
                  }
                .imgsearchhome{
                    width: 1.5rem;
                    height: 1.5rem;
                }
                form.searchboxhome input[type=text]{
                    padding:0px 10px;
                    border: none;
                    border-radius: inherit;
                    width: 90%;
                    font-size: 0.7rem;
                  }
       
            } 
            @media (min-width:1517px) and (max-width:1706px){
                .textfooter{
                    font-size: 1rem;
                }
                .bloodtextfooter{
                    font-size: 1rem;
                }
                .prasupport{
                    font-size: 1rem;
                }
                .BgCategory{
                    top: 69px;
                }
                .ImgDownload{
                    left: -36%;
                    overflow: hidden;
                    position: relative;
                    bottom: -123%;
                    position: absolute;
                    z-index: 0;
                    width: 59%;
                }
                .positionX{
                background-position-y: 129px !important;
                }
            }
            @media (min-width:1517px) {
                .clickSignin{
                    font-size: 1rem;
                }
                .SquareFooter{
                    /* grid-template-columns: 43% 55%; */
                    gap: 2rem;
                    grid-template-columns: 43% 42%;
                    /* grid-template-columns: 44% 53%; */
                }
            }
            @media (min-width:1707px){
                .MenuMar{
                    margin-bottom: 102px;
                    /* margin-bottom: 5.6%; */
                }
                .ImgDownload{
                    left: -390px;
                    overflow: hidden;
                    position: relative;
                    bottom: -219px;
                    position: absolute;
                    z-index: 0;
                    width: 700px;
                }
                
                .positionX{
                    background-position-x: 744px !important;
                    /* max-height: 1417px; */
                    background-position-y: 400px !important;
                    max-width: 1195px !important;
                }
            }
    
            @media (min-width:768px) and (max-width:912px){
    
                .listMenu{
                    display: grid;
                    grid-template-columns: repeat(5,1fr);
                    align-items: center;
                    justify-content: center;
    
                    justify-items: center;
                    /* width: 100%; */
                    width: 90%;
                    margin: auto;
                }
                .SublistMenu{
                    display: grid;
                    grid-template-columns: repeat(2,1fr);
                    align-items: center;
                    justify-content: center;
                    justify-content: center;
                    justify-items: center;
                    gap: 1rem;
                    padding-top: 15px;
             
                }
                
                .FlexMenu{
                    display: grid;
                    grid-template-columns: repeat(1,1fr);
                    padding: 10px 0;
                   
                }
               
            }
    
    
    
    .nameAndQuantityBox{
        display:flex;
      justify-content: space-between;
      /* position: sticky; */
      /* height: 60px; */
      z-index: 1;
      background: white;
      width: 100%;
      max-width: 580px;
      top:0px;
      margin-top: 50px;
      padding-bottom: 10px !important;
    
      
    }
    .priceTextInProduct{
        font-size: 0.9rem;
    }
    
       .DivCount-product-features{
        display: grid;
        grid-template-columns: repeat(3,1fr) ;
        /*   gap: 2rem; */
        height: 35px;   
        width:110px;
        padding:5px;
    
       }
       .DivCount-product{
                    
        grid-template-columns: repeat(3,1fr) ;
      /*   gap: 2rem; */
      height: 45px;   
      width: 140px !important;
      display: grid;
    
    }
    .count-product-features{
        border:none;
        background-color: transparent;
        width: auto;
        color: #ffffff ;
    
        height: 100%;
      
    
    }
    .grid{
        display: grid;
    
    }
    .grid-template-columns-3{
        grid-template-columns: repeat(3,1fr) ;
    }
    .boxShadow{
        box-shadow: 0px 0px 12px 0px #bcbcbc;
    }
    .btnAddInOtherPrices{
        width: 140px;
        border-radius: 22px !important;
        height: 45px;
    
    }
    
    .xxx{
        /* height: 100% !important; */
        position: absolute;
    
        object-fit: contain;
    
        top:0;
        left: 0;
    }